import { Paginated, PartnerCompanyId } from '@polygence/common';
import { axiosBaseQuery } from '@polygence/common/api/fetch';
import type {
  RevenueSharePaymentRequestPartnerDashboard,
  PartnershipStudentLifecycleEvent,
  PartnerCompanyMetrics,
  PartnerCompanyDashboard,
} from '@polygence/common/types/partnerships';
import { createApi } from '@reduxjs/toolkit/query/react';

export const partnerDashboardApi = createApi({
  baseQuery: axiosBaseQuery(),
  reducerPath: 'partner-dashboard',
  endpoints: (build) => {
    return {
      revenueSharePaymentRequests: build.query<
        Paginated<RevenueSharePaymentRequestPartnerDashboard>,
        { partnerCompanyId: PartnerCompanyId }
      >({
        query: ({ partnerCompanyId, ...params }) => ({
          url: `/partnerships/dashboard/${partnerCompanyId}/revenue-share-payments/`,
          method: 'GET',
          params,
        }),
      }),
      studentLifecycleEvents: build.query<
        PartnershipStudentLifecycleEvent[],
        { partnerCompanyId: PartnerCompanyId }
      >({
        query: ({ partnerCompanyId }) => {
          return `/partnerships/dashboard/${partnerCompanyId}/student-events/`;
        },
      }),
      welcomePage: build.query<PartnerCompanyMetrics, PartnerCompanyId>({
        query: (partnerCompanyId) => `/partnerships/dashboard/${partnerCompanyId}/welcome-page/`,
      }),
      partnerCompanyDashboard: build.query<PartnerCompanyDashboard, PartnerCompanyId>({
        query: (partnerCompanyId) => `/partnerships/dashboard/${partnerCompanyId}/`,
      }),
    };
  },
});

export const {
  useRevenueSharePaymentRequestsQuery,
  useStudentLifecycleEventsQuery,
  useWelcomePageQuery,
  usePartnerCompanyDashboardQuery,
} = partnerDashboardApi;
