import { externalLink, type UserCardAction as UserCardActionType } from '@polygence/common';
import { Button, Icon } from '@polygence/components';
import { ComponentProps, useContext } from 'react';
import { Link } from 'react-router-dom';

import { UserCardContext } from 'src/components/common/UserCard/UserCardContext';
import { USER_CARD_ACTIONS } from 'src/components/common/UserCard/actions';

type UserCardActionProp = UserCardActionType & {
  variant?: ComponentProps<typeof Button>['variant'];
  openInNewTab?: boolean;
};

export const UserCardAction = ({
  action,
  url,
  label,
  variant = 'tertiary',
  openInNewTab = false,
}: UserCardActionProp) => {
  const { closeCard } = useContext(UserCardContext);

  if (action) {
    // @ts-expect-error FIXME
    // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
    const actionHandler = USER_CARD_ACTIONS[action.name] ?? null;

    const onClick = () => {
      // eslint-disable-next-line @typescript-eslint/no-unsafe-call
      actionHandler(action.parameters);
      closeCard();
    };

    return actionHandler ? (
      <Button variant={variant} size="sm" onClick={onClick} className="py-2 mt-2 align-self-start">
        {label}
      </Button>
    ) : null;
  }

  const isExternalLink = url.startsWith('http');
  const opensInNewTab = isExternalLink || openInNewTab;

  return (
    <Button
      // @ts-expect-error see BSH-5057
      as={isExternalLink ? 'a' : Link}
      variant={variant}
      size="sm"
      href={url}
      to={url}
      className="py-2 mt-2 align-self-start"
      endIcon={openInNewTab ? <Icon id="external-link" /> : undefined}
      {...(opensInNewTab ? { ...externalLink } : {})}
    >
      {label}
    </Button>
  );
};
