import { ProjectPlan } from '@polygence/common';
import { createContext } from 'react';
import { Updater } from 'use-immer';

export const ProjectPlanContext = createContext<{
  projectPlan: ProjectPlan | null;
  setProjectPlanDraft: Updater<ProjectPlan['contentJson']>;
}>({
  projectPlan: null,
  setProjectPlanDraft: () => null,
});
