import PropTypes from 'prop-types';
import React from 'react';

import 'src/components/common/ProfilePicture/DefaultProfilePicture.sass';

const DefaultProfilePicture = ({ monogram = '', size = 140 }) => {
  return (
    /* eslint-disable-next-line react/jsx-filename-extension, react/forbid-dom-props -- autodisabled, autodisabled */
    <div className="default-profile-picture-background" style={{ width: size, height: size }}>
      {/* eslint-disable-next-line react/forbid-dom-props -- autodisabled */}
      <span className="default-profile-picture-monogram" style={{ fontSize: size * 0.5 }}>
        {monogram}
      </span>
    </div>
  );
};

DefaultProfilePicture.propTypes = {
  monogram: PropTypes.string,
  size: PropTypes.number,
};

// eslint-disable-next-line import/no-default-export -- autodisabled
export default DefaultProfilePicture;
