import type { AxiosRequestConfig } from 'axios';
import type { CamelCasedPropertiesDeep } from 'type-fest';

import { getRestClient } from '../commonSettings';
import type {
  CounselorProfileId,
  MentorProfileId,
  OptOutFeedbackId,
  PortfolioItemId,
  ProjectLinkId,
  Slug,
  StudentApplicationId,
  StudentProfileId,
  TagId,
  UserId,
  UUID,
} from '../types/common';
import type { EmptyResponse } from '../types/data/common';
import type {
  MentorRequestUid,
  MentorSelfProposableSerializer,
  OwnMentorProfileSerializer,
} from '../types/data/marketplace';
import type {
  AdmissionAdvisorModal,
  CounselorOwnProfile,
  Country,
  Degree,
  ExternalStudentProfile,
  ExternalStudentProfileOverview,
  FieldOfStudy,
  FileUpload,
  FinancialAidDocument,
  MentorPublicList,
  OwnMentorProfile,
  ParentOwnProfile,
  PortfolioItem,
  PortfolioItemUpdate,
  ProjectLink,
  ProjectLinkCreateRequest,
  ProjectLinkUpdateRequest,
  ProjectOutcomeType,
  ReferralType,
  ScholarGroup,
  School,
  SearchInstitutions,
  StudentOwnProfile,
  StudentParentInfo,
  StudentProfileWithApplication,
  StudentScholarPage,
  StudentScholarPageSelect,
  StudentSelectedProduct,
  StudentUrlRequestParam,
  StudentUrlResponse,
  SymposiumPresenterStatistics,
  Tag,
  TagCreatePayload,
  UserProfileResponse,
} from '../types/marketplace';
import type { ProjectIdea } from '../types/studentApplication';
import type { StringUserType, UserState } from '../types/user';
import type { timezones } from '../utils/timezones';

import { defaults } from './fetch';

export function getUserInfo() {
  const internalFetch = getRestClient();
  return internalFetch.get<UserState>('/api/user-info/');
}

export const getApplicationProjectIdea = (generalInterest: string) => {
  const internalFetch = getRestClient();
  return internalFetch.get<ProjectIdea[]>(
    `/api/application-project-ideas/?general_interest=${generalInterest}`,
  );
};

type TimezoneKey = keyof typeof timezones;

export const updateTimezone = (data: TimezoneKey, userType: StringUserType) => {
  const internalFetch = getRestClient();
  return internalFetch.patch(`/api/${userType}profile/`, { timeZone: data, partial: true });
};

export const updateUserProfile = (payload: Record<string, unknown>, userType: StringUserType) => {
  const internalFetch = getRestClient();
  return internalFetch.patch<Record<string, unknown>>(`/api/${userType}profile/`, {
    ...payload,
    partial: true,
  });
};

export const getScholarPages = (query = '') => {
  const internalFetch = getRestClient();
  return internalFetch.get<{
    results: StudentScholarPageSelect[];
  }>(`/api/scholars/?q=${query}`);
};

export const getTags = (category = '', showForCaseStudies = false) => {
  const params = new URLSearchParams({ category });
  if (showForCaseStudies) {
    params.append('show_for_case_studies', '1');
  }

  const internalFetch = getRestClient();
  return internalFetch.get<Tag[]>('/api/tags/', { params: params });
};

export const getSelfProposableMentorRequests = () => {
  const internalFetch = getRestClient();
  return internalFetch.get<CamelCasedPropertiesDeep<MentorSelfProposableSerializer>[]>(
    `/api/mentor-self-proposable-list/`,
  );
};

export const getSelfProposedMentorRequests = () => {
  const internalFetch = getRestClient();
  return internalFetch.get<CamelCasedPropertiesDeep<MentorSelfProposableSerializer>[]>(
    `/api/mentor-self-proposed-list/`,
  );
};

export const selfPropose = (mentorRequestUid: MentorRequestUid) => {
  const internalFetch = getRestClient();
  return internalFetch.post<CamelCasedPropertiesDeep<MentorSelfProposableSerializer>>(
    `/api/mentor-requests/${mentorRequestUid}/self-propose/`,
  );
};

export function getAdmissionAdvisorModalByUserUuid(uuid: UUID) {
  const internalFetch = getRestClient();
  return internalFetch.get<AdmissionAdvisorModal>(`/api/admission_advisor_modal/user/${uuid}/`);
}

export function getAdmissionAdvisorModal(email: string | undefined | null) {
  const internalFetch = getRestClient();

  return internalFetch.get<AdmissionAdvisorModal>(
    `/api/admission_advisor_modal/${email ?? 'default'}/`,
  );
}

export function updateStudentProfile(
  payload: Partial<StudentOwnProfile> & {
    partial: boolean;
  },
) {
  const internalFetch = getRestClient();

  return internalFetch.patch<StudentOwnProfile>(`/api/studentprofile/`, payload);
}

export function getMyAdmissionAdvisorModal() {
  const internalFetch = getRestClient();
  return internalFetch.get<AdmissionAdvisorModal>('/api/my-admission-advisor-modal/');
}

export const getCounselorStudents = (profileId: CounselorProfileId) => {
  const internalFetch = getRestClient();
  return internalFetch.get<ExternalStudentProfile[]>(`/api/counselors/${profileId}/students/`);
};

export const getProjectOutcomeTypes = () => {
  const internalFetch = getRestClient();
  return internalFetch.get<ProjectOutcomeType[]>('/api/project-outcome-types/');
};

export const deleteProjectLink = (id: ProjectLinkId) => {
  const internalFetch = getRestClient();
  return internalFetch.delete(`/api/project-links/${id}/`);
};

export const getListOfSchools = (search: string) => {
  const internalFetch = getRestClient();
  return internalFetch.get<{ results: School[] }>(`/api/schools/?search=${search}`);
};

export const deletePermanentFile = (uuid: UUID) => {
  const internalFetch = getRestClient();
  return internalFetch.delete(`/api/permanent-files/${uuid}/`);
};

export const getSymposiumPresenterStatistics = (scholarPageSlug: string) => {
  const internalFetch = getRestClient();
  return internalFetch.get<SymposiumPresenterStatistics>(
    `/api/symposium-presenter-statistics/${scholarPageSlug}/`,
  );
};

export const updateStudentParentInfo = (
  studentId: StudentProfileId,
  payload: StudentParentInfo,
) => {
  const internalFetch = getRestClient();
  return internalFetch.patch(`/api/student-profile/${studentId}/change-parent-info/`, payload);
};

export const deleteAllFinancialAidDocuments = () => {
  const internalFetch = getRestClient();
  return internalFetch.delete<EmptyResponse>('api/financial-aid-documents/');
};

export function getCountries(search?: string) {
  const internalFetch = getRestClient();
  return internalFetch.get<Country[]>('/api/countries/', { params: { search } });
}

export const uploadFinancialAidDocuments = ({
  formData,
  onProgressChange,
}: {
  formData: FormData;
  onProgressChange?: AxiosRequestConfig['onUploadProgress'];
}) => {
  const internalFetch = getRestClient();
  return internalFetch.post<FinancialAidDocument[]>('api/financial-aid-documents/', formData, {
    onUploadProgress: onProgressChange,
    headers: {
      'Content-Type': undefined,
    },
  });
};

export const uploadFile = ({
  formData,
  onProgressChange,
}: {
  formData: FormData;
  onProgressChange?: AxiosRequestConfig['onUploadProgress'];
}) => {
  const internalFetch = getRestClient();
  return internalFetch.post<FileUpload[]>('api/files/', formData, {
    onUploadProgress: onProgressChange,
    headers: {
      'Content-Type': undefined,
    },
  });
};

export const getScholarPage = (slug: Slug) => {
  const internalFetch = getRestClient();
  return internalFetch<StudentScholarPage>(`/api/scholars/${slug}/`);
};

export const createProjectLink = (payload: Partial<ProjectLinkCreateRequest>) => {
  const internalFetch = getRestClient();
  return internalFetch.post<ProjectLink>(`/api/project-links/`, payload);
};

export const updateProjectLink = (
  id: ProjectLinkId,
  payload: Partial<ProjectLinkUpdateRequest>,
) => {
  const internalFetch = getRestClient();
  return internalFetch.patch<ProjectLink>(`/api/project-links/${id}/`, payload);
};

export const getStudentProfileForCoreApp = () => {
  const internalFetch = getRestClient();
  return internalFetch.get<StudentProfileWithApplication>(
    `/api/student-profile-core-app-student-pays/`,
  );
};

export const updateStudentProfileForCoreAppStudentPaysWorkflow = (
  payload: Partial<StudentProfileWithApplication> & { applicationStage?: string },
) => {
  const internalFetch = getRestClient();
  return internalFetch.patch<StudentProfileWithApplication>(
    '/api/student-profile-core-app-student-pays/',
    payload,
  );
};

export const updateStudentProfileForPod = (payload: Partial<StudentProfileWithApplication>) => {
  const internalFetch = getRestClient();
  return internalFetch.patch<StudentProfileWithApplication>(
    '/api/student-profile-for-pod/',
    payload,
  );
};

export const updateStudentProfileForCoreAppPartnerPaysWorkflow = (
  payload: Partial<StudentProfileWithApplication> & { applicationStage?: string },
) => {
  const internalFetch = getRestClient();
  return internalFetch.patch<StudentProfileWithApplication>(
    '/api/student-profile-core-app-partner-pays/',
    payload,
  );
};

export const updateStudentProfileForPathfinders = (
  studentProfileId: StudentProfileId,
  payload: Partial<StudentProfileWithApplication> & { applicationStage?: string },
) => {
  const internalFetch = getRestClient();
  return internalFetch.patch<StudentProfileWithApplication>(
    `/api/students/${studentProfileId}/pathfinders/`,
    payload,
  );
};

export const submitStudentApplicationLegacy = (payload: {
  applicationId: StudentApplicationId;
}) => {
  const internalFetch = getRestClient();
  return internalFetch.post<StudentProfileWithApplication>(
    '/api/student-application-v2-submit/',
    payload,
  );
};

export const getUserProfile = <UserType extends StringUserType>(userType: UserType) => {
  const internalFetch = getRestClient();
  return internalFetch.get<UserProfileResponse<UserType>>(`/api/${userType}profile/`);
};

export const getReferralTypes = () => {
  const internalFetch = getRestClient();
  return internalFetch.get<ReferralType[]>('/api/referral-types/');
};

export const reapplyStudent = (payload = { discount: false }) => {
  const internalFetch = getRestClient();
  return internalFetch.post<{ success: boolean }>(`/api/studentprofile/reapply/`, payload);
};

export const getChildrenProfiles = () => {
  const internalFetch = getRestClient();
  return internalFetch.get<ExternalStudentProfile[]>('api/parent-student-list/');
};

export const getProspectiveMentor = (uuid: UUID) => {
  const internalFetch = getRestClient();
  return internalFetch.get<CamelCasedPropertiesDeep<{ uuid: UUID; subjectTags: TagId[] }>>(
    `/api/prospective-mentors/${uuid}/`,
  );
};

export const updateProspectiveMentor = (uuid: UUID, payload: { subjectTags: TagId[] }) => {
  const internalFetch = getRestClient();
  return internalFetch.post<{ success: boolean }>(`/api/prospective-mentors/${uuid}/`, payload);
};

export const createTag = (payload: TagCreatePayload) => {
  const internalFetch = getRestClient();
  return internalFetch.post<Tag>('/api/tags/', payload);
};

export const getSingleMentorProfile = (mentorProfileId: UserId) => {
  const internalFetch = getRestClient();
  return internalFetch.get<MentorPublicList | Partial<OwnMentorProfile>>(
    '/api/singlementorprofile/',
    {
      params: { mid: mentorProfileId },
    },
  );
};

export const getDegrees = () => {
  const internalFetch = getRestClient();
  return internalFetch.get<Degree[]>('/api/degrees/');
};

export const getFieldOfStudies = () => {
  const internalFetch = getRestClient();
  return internalFetch.get<FieldOfStudy[]>('/api/field-of-studies/');
};

export const getInstitutions = <
  SearchType extends string | undefined,
  LimitType extends number | undefined,
>({ search = '', limit = undefined }: { search?: SearchType; limit?: LimitType } = {}) => {
  const internalFetch = getRestClient();
  return internalFetch.get<SearchInstitutions<SearchType, LimitType>>('/api/institutions/', {
    params: { search, limit },
  });
};

export const updateCounselorProfile = (payload: Partial<CounselorOwnProfile>) => {
  const internalFetch = getRestClient();
  return internalFetch.patch<CounselorOwnProfile & { missingFields?: string[] }>(
    '/api/counselorprofile/',
    {
      partial: true,
      ...payload,
    },
  );
};

export const getProposalActive = (uuid: UUID) => {
  const internalFetch = getRestClient();
  return internalFetch.get<{
    isMatched: boolean;
    studentName: string;
  }>(`/api/proposal-active/${uuid}/`);
};

export const updateMentorTags = (
  mentorProfileId: MentorProfileId,
  payload: Record<string, TagId[] | TagId>,
) => {
  const internalFetch = getRestClient();
  return internalFetch.patch<{ success: boolean }>(
    `/api/mentors/${mentorProfileId}/tags/`,
    payload,
  );
};

export const updateContinuedInterest = (email: string, interested: string) => {
  const internalFetch = getRestClient();
  return internalFetch.patch<{ recorded: boolean; id: OptOutFeedbackId }>(
    '/api/continued-interest/',
    { email, interested },
    { params: { email, interested } },
  );
};

export const partialUpdateReviewStudent = (
  uuid: UUID,
  _response?: unknown,
  payload?: {
    matchRating?: number | undefined;
    reason?: {
      no_good_fit?: boolean;
      no_bandwidth?: boolean;
      timezone_issue?: boolean;
      unavailable_until?: string;
      other?: string;
    };
  },
) => {
  const internalFetch = getRestClient();
  return internalFetch.patch<{ message: string; success: boolean }>(
    `/api/review-student/${uuid}/`,
    payload,
  );
};

export const updateReviewStudent = (uuid: UUID, payload: { response: string }) => {
  const internalFetch = getRestClient();
  return internalFetch.post<{ message: string; success: boolean }>(
    `/api/review-student/${uuid}/`,
    payload,
  );
};

export const partialUpdateStudentURLResponse = <
  Params extends Partial<Record<StudentUrlRequestParam, string>>,
>(
  params: Params,
  payload: unknown,
) => {
  const internalFetch = getRestClient();
  return internalFetch.patch<CamelCasedPropertiesDeep<StudentUrlResponse<Params>>>(
    '/api/student-url-response/',
    payload,
    {
      params,
    },
  );
};

export const updateWaitlistInvitationResponse = (uuid: UUID, response: 'yes' | 'no') => {
  const internalFetch = getRestClient();
  return internalFetch.post<{ success: boolean }>(
    `/api/mentor-requests/${uuid}/waitlist/${response}/`,
    { response },
  );
};

export const updateParentProfile = (payload: Partial<ParentOwnProfile & { partial: boolean }>) => {
  const internalFetch = getRestClient();
  return internalFetch.patch<ParentOwnProfile>('/api/parentprofile/', payload);
};

export const createScholarPage = () => {
  const internalFetch = getRestClient();
  return internalFetch.post<StudentScholarPage>('/api/my-scholar-page/');
};

export const updateScholarPage = (slug: Slug, payload: Partial<StudentScholarPage>) => {
  const internalFetch = getRestClient();
  return internalFetch.patch<StudentScholarPage>(`/api/scholars/${slug}/`, payload);
};

export const getPeerScholarGroup = (url: Slug) => {
  const internalFetch = getRestClient();
  return internalFetch.get<ScholarGroup>(`/api/scholars/peers/${url}/`);
};

export const updatePortfolioItem = (
  id: PortfolioItemId,
  payload: Partial<
    Omit<PortfolioItem, 'tags' | 'projectLinks'> & {
      tags: PortfolioItemId[];
      projectLinks: Partial<ProjectLink>[];
    }
  >,
) => {
  const internalFetch = getRestClient();
  return internalFetch.patch<PortfolioItemUpdate>(`/api/portfolio-items/${id}/`, payload);
};

export const getExternalStudentOverview = (studentProfileId: StudentProfileId) => {
  const internalFetch = getRestClient();
  return internalFetch.get<ExternalStudentProfileOverview>(`/api/students/${studentProfileId}/`);
};

export const getHomeProfile = () => {
  const internalFetch = getRestClient();
  return internalFetch.get<OwnMentorProfileSerializer>('/api/homeprofile/', {
    transformRequest: defaults.transformRequest,
    transformResponse: defaults.transformResponse,
  });
};

export const updateHomeProfile = (payload: Partial<OwnMentorProfileSerializer>) => {
  const internalFetch = getRestClient();
  return internalFetch.patch<Partial<OwnMentorProfileSerializer>>('/api/homeprofile/', payload, {
    transformRequest: defaults.transformRequest,
    transformResponse: defaults.transformResponse,
  });
};

export const updateStudentSelectedProduct = (
  studentProfileId: StudentProfileId,
  payload: StudentSelectedProduct,
) => {
  const internalFetch = getRestClient();
  return internalFetch.patch<StudentSelectedProduct>(
    `/api/students/${studentProfileId}/product-selection/`,
    payload,
  );
};
