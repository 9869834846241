import * as zeusApi from '@polygence/common/api/zeus';
import type { SimpleCounselorProfile } from '@polygence/common/types/analytics';
import type { Nullable } from '@polygence/common/types/utils';
import type { CounselorProfile } from '@polygence/common/types/zeus';
import type { SelectOption, SingleValue } from '@polygence/components/dist/select/types';
import { useEffect, useState } from 'react';
import Select from 'react-select';

import { useCounselorsQuery } from 'src/reducers/adminReducers';
import { useAppSelector } from 'src/store';

interface EditableCounselorProps {
  counselor: Nullable<SimpleCounselorProfile>;
  studentChannel: string;
  studentId: number;
  onChange: (event: {
    target: { name: 'counselor'; value: number };
    counselor: CounselorProfile;
  }) => void;
  onMismatch: (arg: boolean) => void;
  label?: string;
}

export const EditableCounselor = ({
  counselor,
  studentChannel,
  studentId,
  onChange,
  onMismatch,
  label,
}: EditableCounselorProps) => {
  const counselorFetchLimit = 1000;
  const channel =
    useAppSelector((state) => {
      return state.channel.value;
    }) || studentChannel;
  const [currentCounselor, setCurrentCounselor] =
    useState<Nullable<SimpleCounselorProfile | CounselorProfile>>(counselor);
  const { data } = useCounselorsQuery({
    profile_approved: true,
    partner_company__tag: channel,
    page_size: counselorFetchLimit,
  });
  const counselors = data?.results ?? [];
  const options = counselors.map((_counselor) => {
    return {
      label: _counselor ? `${_counselor.firstName} ${_counselor.lastName}` : 'Select...',
      value: _counselor.pk,
    };
  });
  const [currentValue, setCurrentValue] = useState<Nullable<SingleValue<SelectOption>>>(() => {
    if (!counselor?.pk) {
      return null;
    } else {
      return {
        label: `${counselor.firstName} ${counselor.lastName}`,
        value: counselor.pk,
      };
    }
  });

  const handleChange = (newValue: SingleValue<SelectOption>) => {
    if (newValue) {
      const value = Number(newValue.value);
      const selectedLabel = newValue.label;
      zeusApi
        .updateStudent(studentId, { counselorId: value })
        .then(({ data }: { data: { counselorId: number } }) => {
          const _currentCounselor = counselors.find((counselor) => {
            return counselor.pk === data.counselorId;
          });
          setCurrentValue({ label: selectedLabel, value });
          if (_currentCounselor) {
            setCurrentCounselor(_currentCounselor);
            if (typeof onChange === 'function') {
              onChange({
                target: { name: 'counselor', value },
                counselor: _currentCounselor,
              });
            }
          }
        })
        .catch(console.error);
    } else {
      zeusApi
        .updateStudent(studentId, { counselorId: null })
        .then(() => {
          setCurrentCounselor(null);
          setCurrentValue(null);
        })
        .catch(console.error);
    }
  };

  useEffect(() => {
    if (!currentValue) {
      return;
    }
    const isMatchingCounselor =
      !currentValue.value ||
      options.some((option) => {
        return option.value === currentValue.value;
      });
    if (typeof onMismatch === 'function') {
      onMismatch(!isMatchingCounselor);
    }
  }, [options, currentCounselor, onMismatch, currentValue]);

  return (
    // eslint-disable-next-line react/jsx-filename-extension -- autodisabled
    <div className="mb-5">
      {label && <span className="me-3">{label}</span>}
      <Select
        menuPortalTarget={document.body}
        value={currentValue}
        options={options}
        onChange={handleChange}
        isClearable
      />
    </div>
  );
};
