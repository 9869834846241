import { ProjectPlan } from '@polygence/common';
import { Text, cn } from '@polygence/components';
import { useContext } from 'react';

import { ProjectPlanContext } from 'src/components/hermes/project-plan/ProjectPlanContext';
import { EditableMarkdown } from 'src/components/hermes/project-plan/ProjectPlanSection';

const RoadmapSession = ({
  session,
  onChange,
  className,
}: {
  session: ProjectPlan['contentJson']['roadmap'][number];
  onChange: (value: ProjectPlan['contentJson']['roadmap'][number]) => void;
  className?: string;
}) => {
  const handleChange =
    (key: keyof ProjectPlan['contentJson']['roadmap'][number]) => (value: string) => {
      onChange({
        ...session,
        [key]: value,
      });
    };

  return (
    <div
      className={cn(
        '-tw-mx-2 tw-grid tw-grid-cols-2 tw-gap-3 tw-border-0 tw-border-b tw-border-solid tw-border-gray-200 tw-px-2',
        className,
      )}
    >
      <div className="tw-flex tw-flex-col">
        <Text size="medium" fontWeight="bold" className="tw-mb-1 tw-mt-3">
          During Session {session.sessionNumber}
        </Text>
        <EditableMarkdown
          content={session.duringSession}
          onChange={handleChange('duringSession')}
        />
      </div>
      <div className="tw-flex tw-flex-col">
        <Text
          size="small"
          fontWeight="bold"
          className="tw-mb-1 tw-mt-3 tw-pt-1 tw-uppercase tw-text-gray-500"
        >
          Assignments
        </Text>
        <EditableMarkdown
          content={session.beforeNextSession}
          onChange={handleChange('beforeNextSession')}
        />
      </div>
    </div>
  );
};

export const ProjectPlanRoadmap = ({
  roadmap,
}: {
  roadmap: ProjectPlan['contentJson']['roadmap'];
}) => {
  const { setProjectPlanDraft } = useContext(ProjectPlanContext);

  const onChange = (index: number) => (value: ProjectPlan['contentJson']['roadmap'][number]) => {
    setProjectPlanDraft((draft) => {
      // eslint-disable-next-line fp/no-mutation
      draft.roadmap[index] = value;
    });
  };

  return (
    <div className="d-flex flex-column">
      {roadmap.map((sessionPlan, index) => (
        <RoadmapSession
          key={sessionPlan.sessionNumber}
          session={sessionPlan}
          onChange={onChange(index)}
          className={index % 2 === 0 ? 'tw-bg-gray-50' : undefined}
        />
      ))}
    </div>
  );
};
