import type { UserCardBadge as UserCardBadgeType } from '@polygence/common';
import { Badge, Text, Variant } from '@polygence/components';
import classNames from 'classnames';

import { Icon } from 'src/components/Icon';
import styles from 'src/components/common/UserCard/UserCard.module.scss';
import { useValidateValue } from 'src/hooks/useValidateValue';

const ALLOWED_VARIANTS: Readonly<Variant[]> = ['primary', 'dark', 'light'] as const;
const ALLOWED_ICONS = ['completedProject', 'activeProject', 'enrolledProject', 'book'] as const;

type AllowedIcons = (typeof ALLOWED_ICONS)[number];

interface UserCardBadgeProps {
  badge: UserCardBadgeType;
}

function isIconVariable(icon: AllowedIcons): icon is AllowedIcons {
  return ALLOWED_ICONS.includes(icon);
}

function isVariant(variant: string): variant is Variant {
  return ALLOWED_VARIANTS.includes(variant as Variant);
}

const TEXT_COLORS: Partial<Record<Variant, string>> = {
  primary: 'text-primaryDark',
  dark: 'text-white',
  light: 'text-primaryDark',
} as const;

export const UserCardBadge = ({ badge }: UserCardBadgeProps) => {
  const icon = useValidateValue(badge.icon, isIconVariable, 'Wrong iconId passed to UserCard!');
  const variant = useValidateValue(
    badge.variant,
    isVariant,
    'Wrong variant was passed to UserCard!',
  );

  return (
    <Badge
      key={badge.label}
      className="px-4 py-2 d-flex justify-content-center"
      pill
      size="medium"
      variant={variant ?? 'primary'}
    >
      {icon && (
        <Icon name={icon} size="16px" className={classNames(styles['badgeIcon'], 'me-2')} local />
      )}
      <Text
        size="small"
        fontWeight="bold"
        textWrap={badge.label.length > 15 ? 'wrap' : 'nowrap'}
        className={variant ? TEXT_COLORS[variant] : 'text-white'}
      >
        {badge.label}
      </Text>
    </Badge>
  );
};
