import NiceModal, { bootstrapDialog, useModal } from '@ebay/nice-modal-react';
import { Nullable } from '@polygence/common';
import { useUpdateProjectPlanMutation } from '@polygence/common/api/hermes-project';
import { Button, Modal, Text } from '@polygence/components';
import { useState } from 'react';
import { toast } from 'react-toastify';

import { StarRating } from 'src/components/mentor/FormElements';

export const ProjectPlanFeedbackModal = NiceModal.create(
  ({ projectPlanId }: { projectPlanId: number }) => {
    const modal = useModal();
    const [rating, setRating] = useState<Nullable<number>>(null);
    const [isUpdating, setIsUpdating] = useState(false);
    const [errorMsg, setErrorMsg] = useState('');

    const [updateProjectPlan] = useUpdateProjectPlanMutation();

    const handleUpdate = () => {
      if (!rating) {
        setErrorMsg('Please select a rating');
        return;
      }

      setIsUpdating(true);
      updateProjectPlan({
        id: projectPlanId,
        data: {
          rating,
        },
      })
        .unwrap()
        .then(() => {
          setIsUpdating(false);
          toast.success('Thank you for your feedback!');
          void modal.hide();
        })
        .catch((err) => console.error(err));
    };

    const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
      setRating(Number(event.target.value));
    };

    const onEscapeKeyDown = (e: KeyboardEvent) => {
      e.preventDefault();
    };

    return (
      <Modal
        {...bootstrapDialog(modal)}
        body
        closeButton
        {...{ backdrop: 'static', onEscapeKeyDown }}
      >
        <div className="tw-m-5 tw-flex tw-flex-col tw-items-center tw-justify-center">
          <Text size="medium" fontWeight="bold">
            On a scale of 1-5, how would you rate the generated project plan?
          </Text>
          <StarRating label="" name="rating" onChange={handleChange} value={rating} />
          {errorMsg && (
            <Text size="medium" className="tw-mb-4 tw-text-red-500">
              {errorMsg}
            </Text>
          )}
          <Button variant="primary" onClick={handleUpdate} disabled={isUpdating}>
            Submit
          </Button>
        </div>
      </Modal>
    );
  },
);
