import NiceModal from '@ebay/nice-modal-react';
import { ProjectPlan } from '@polygence/common';
import { useUpdateProjectPlanMutation } from '@polygence/common/api/hermes-project';
import { Alert, Button, Heading, Icon, Text, cn } from '@polygence/components';
import { isEqual } from 'lodash';
import { useMemo, useState } from 'react';
import { toast } from 'react-toastify';
import { useImmer } from 'use-immer';

import { ProjectPlanContext } from 'src/components/hermes/project-plan/ProjectPlanContext';
import { ProjectPlanFeedbackModal } from 'src/components/hermes/project-plan/ProjectPlanFeedbackModal';
import { ProjectPlanRoadmap } from 'src/components/hermes/project-plan/ProjectPlanRoadmap';
import { ProjectPlanSection } from 'src/components/hermes/project-plan/ProjectPlanSection';
import { useCanEditProjectPlan } from 'src/components/hermes/project-plan/useCanEditProjectPlan';

export const ProjectPlanComponent = ({
  projectPlan,
  onClose,
}: {
  projectPlan: ProjectPlan;
  onClose?: () => void;
}) => {
  const [componentKey, setComponentKey] = useState(0);
  const [projectPlanDraft, setProjectPlanDraft] = useImmer(projectPlan.contentJson);
  const [updateProjectPlan, { isLoading: isUpdating }] = useUpdateProjectPlanMutation();
  const canEdit = useCanEditProjectPlan(projectPlan);

  const contextValue = useMemo(
    () => ({ projectPlan, setProjectPlanDraft }),
    [projectPlan, setProjectPlanDraft],
  );

  const handlePublish = () => {
    if (isUpdating) {
      return;
    }

    updateProjectPlan({
      id: projectPlan.id,
      data: {
        approvedAt: new Date().toISOString(),
        contentJson: projectPlanDraft,
      },
    })
      .unwrap()
      .then(() => {
        resetComponent();
        toast.success(
          !projectPlan.approvedAt ? 'Project plan published!' : 'Project plan updated!',
        );
        handleClose();

        if (!projectPlan.approvedAt) {
          NiceModal.show(ProjectPlanFeedbackModal, {
            projectPlanId: projectPlan.id,
          }).catch((err) => console.error(err));
        }
      })
      .catch(() => {
        toast.error('Something went wrong');
      });
  };

  const handleClose = () => {
    if (!onClose) {
      return;
    }

    if (!isEqual(projectPlanDraft, projectPlan.contentJson)) {
      // eslint-disable-next-line no-alert
      const closeWithOutSave = window.confirm(
        'You have unsaved changes, do you want to close without saving?',
      );
      if (!closeWithOutSave) {
        return;
      }
    }

    onClose();
  };

  const resetComponent = () => {
    setComponentKey((key) => key + 1);
  };

  return (
    <ProjectPlanContext.Provider value={contextValue} key={componentKey}>
      <Heading size="h4" as="h1" className="tw-mx-auto tw-flex tw-items-center tw-gap-2">
        {projectPlan.studentName}'s Project Plan{' '}
      </Heading>
      {!projectPlan.approvedAt && (
        <Alert variant="info" className="tw-mx-auto tw-mt-4 tw-flex tw-gap-2 tw-bg-violet-100">
          <Icon id="sparkles" className="tw-text-amber-400" />
          <Text size="small" fontWeight="bold" className="tw-text-gray-600">
            This is an AI-generated draft of the project plan. In its current state, it's only
            visible to you. <br />
            Please review and edit it before publishing to the student.
          </Text>
        </Alert>
      )}
      <ProjectPlanContent content={projectPlanDraft} />
      <div className="tw-flex tw-justify-end tw-gap-3">
        {onClose && (
          <Button
            onClick={() => {
              handleClose();
            }}
            startIcon={<Icon id="x" />}
            variant="link"
          >
            Close
          </Button>
        )}
        {canEdit && (
          <Button
            variant="primary"
            disabled={isUpdating}
            onClick={handlePublish}
            endIcon={!projectPlan.approvedAt ? <Icon id="share" /> : undefined}
          >
            {!projectPlan.approvedAt ? 'Save & Publish' : 'Save All'}
          </Button>
        )}
      </div>
    </ProjectPlanContext.Provider>
  );
};

export const ProjectPlanContent = ({
  content,
  className,
}: {
  content: ProjectPlan['contentJson'];
  className?: string;
}) => {
  const getSectionProps = <T extends keyof ProjectPlan['contentJson']>(
    key: T,
  ): { nameKey: T; content: ProjectPlan['contentJson'][T] } => ({
    nameKey: key,
    content: content[key],
  });

  return (
    <div
      className={cn(
        'container gap-5 tw-prose tw-prose-sm tw-prose-slate tw-max-w-none tw-p-4',
        className,
      )}
    >
      <ProjectPlanSection title="Title" {...getSectionProps('title')} />
      <ProjectPlanSection title="Description" {...getSectionProps('description')} />
      <ProjectPlanSection title="Potential outcome" {...getSectionProps('potentialOutcome')} />
      <ProjectPlanSection title="Roadmap" {...getSectionProps('roadmap')}>
        <ProjectPlanRoadmap roadmap={content.roadmap} />
      </ProjectPlanSection>
      <ProjectPlanSection
        title="Essential skills to learn"
        {...getSectionProps('essentialSkillsToLearn')}
      />
      <ProjectPlanSection title="Potential pitfalls" {...getSectionProps('potentialPitfalls')} />
    </div>
  );
};
