import type {
  StudentJourneyLaunchpadProject,
  StudentJourneyLaunchpadReflection,
} from '@polygence/common';
import { Card, Text, Skeleton, Icon } from '@polygence/components';
import classNames from 'classnames';
import type { ElementType } from 'react';

import { CollapsibleJourneyTitle } from 'src/components/Tiles/StudentJourney/CollapsibleJourneyTitle';
import { StudentJourneyProduct } from 'src/components/Tiles/StudentJourney/StudentJourneyProduct';
import styles from 'src/components/Tiles/StudentJourney/common.module.scss';
import { ActiveLaunchpadProjectJourneyTile } from 'src/components/Tiles/StudentJourney/launchpad/ActiveLaunchpadProjectJourneyTile';
import { ActiveLaunchpadReflectionJourneyTile } from 'src/components/Tiles/StudentJourney/launchpad/ActiveLaunchpadReflectionJourneyTile';
import { UnderMatchingLaunchpadProjectJourneyTile } from 'src/components/Tiles/StudentJourney/launchpad/UnderMatchingLaunchpadProjectJourneyTile';
import { UnderMatchingLaunchpadReflectionJourneyTile } from 'src/components/Tiles/StudentJourney/launchpad/UnderMatchingLaunchpadReflectionJourneyTile';
import { useStudentJourney } from 'src/components/Tiles/StudentJourney/useStudentJourney';

const SkeletonLaunchpadProjectTile = () => {
  return (
    <div className="d-flex flex-column gap-4 align-items-center">
      <Skeleton variant="circle" width="32px" height="32px" />
      <Skeleton width="72px" height="32px" />
      <Skeleton width="72px" height="32px" />
    </div>
  );
};

const SkeletonLaunchpadReflectionTile = () => {
  return (
    <div className="d-flex flex-row gap-10 justify-content-evenly">
      <Skeleton variant="circle" width="32px" height="32px" />
      <Skeleton width="72px" height="32px" />
      <Skeleton width="72px" height="32px" />
    </div>
  );
};

const SkeletonLaunchpadJourneyTile = () => {
  return (
    <div>
      <div className="d-flex flex-row gap-4">
        <Card
          className={classNames(
            styles['internalCard'],
            'd-flex flex-1 flex-row gap-6 px-6 py-4 flex-fill justify-content-evenly shadow-none',
          )}
        >
          {[0, 1, 2].map((item) => (
            <SkeletonLaunchpadProjectTile key={item} />
          ))}
        </Card>
      </div>
      <div className="d-flex flex-row mt-4">
        <Card className={classNames(styles['internalCard'], 'px-6 py-4 shadow-none w-100')}>
          <div className="d-flex flex-column align-items-center">
            <Text className="mb-4" alignment="center" fontWeight="bold" size="small">
              Reflection Call
            </Text>
            <SkeletonLaunchpadReflectionTile />
          </div>
        </Card>
      </div>
    </div>
  );
};

const projectComponents: { [key in StudentJourneyLaunchpadProject['status']]: ElementType } = {
  under_matching: UnderMatchingLaunchpadProjectJourneyTile,
  active: ActiveLaunchpadProjectJourneyTile,
  completed: ActiveLaunchpadProjectJourneyTile,
  terminated: ActiveLaunchpadProjectJourneyTile,
};

const reflectionComponents: { [key in StudentJourneyLaunchpadReflection['status']]: ElementType } =
  {
    inactive: UnderMatchingLaunchpadReflectionJourneyTile,
    active: ActiveLaunchpadReflectionJourneyTile,
    completed: ActiveLaunchpadReflectionJourneyTile,
    terminated: ActiveLaunchpadReflectionJourneyTile,
  };

interface LaunchpadJourneyTileLayoutProps {
  projects: StudentJourneyLaunchpadProject[];
  reflection: StudentJourneyLaunchpadReflection;
}

const LaunchpadJourneyTileLayout = ({ projects, reflection }: LaunchpadJourneyTileLayoutProps) => {
  const ReflectionComponent = reflectionComponents[reflection.status];

  return (
    <div>
      <div className="d-flex flex-row gap-4">
        <Card
          className={classNames(
            styles['internalCard'],
            'd-flex flex-1 flex-row gap-6 px-6 py-4 flex-fill justify-content-evenly shadow-none flex-wrap',
          )}
        >
          {projects.map((project, index) => {
            const ProjectComponent = projectComponents[project.status];
            return (
              <ProjectComponent
                // eslint-disable-next-line react/no-array-index-key
                key={index}
                data={project}
                index={index + 1}
                subject={project.subject}
              />
            );
          })}
        </Card>
      </div>
      <div className="d-flex flex-row mt-4">
        <Card className={classNames(styles['internalCard'], 'px-6 py-4 shadow-none w-100')}>
          <div className="d-flex flex-column align-items-center">
            <Text className="mb-4" alignment="center" fontWeight="bold" size="small">
              Reflection Call
            </Text>
            <ReflectionComponent data={reflection} />
          </div>
        </Card>
      </div>
    </div>
  );
};

export const LaunchpadJourneyTile = () => {
  const { data } = useStudentJourney();

  if (!data || !data.launchpad) {
    return null;
  }

  const isAllProjectCompleted = data.launchpad.projects.every(
    (project) => project.status === 'completed',
  );
  const isReflectionCompleted =
    !data.launchpad.reflection || data.launchpad.reflection.status === 'completed';
  const isLaunchpadCompleted = isAllProjectCompleted && isReflectionCompleted;

  const completedSessions =
    data?.launchpad?.projects.filter((project) => project.status === 'completed').length +
    (data?.launchpad?.reflection?.status === 'completed' ||
    data?.launchpad?.reflection?.status === 'terminated'
      ? 1
      : 0);

  const totalSessions = data?.launchpad?.projects.length + (data?.launchpad?.reflection ? 1 : 0);

  return (
    <StudentJourneyProduct
      loader={<SkeletonLaunchpadJourneyTile />}
      productType="launchpad"
      title={
        <CollapsibleJourneyTitle
          title="Launchpad"
          sessionData={
            data?.launchpad?.projects
              ? {
                  completedSessions,
                  totalSessions,
                }
              : null
          }
        />
      }
      defaultOpen={!isLaunchpadCompleted}
    >
      {isLaunchpadCompleted ? (
        <>
          <Text
            style={{ color: 'var(--primary-not-so-dark)' }}
            fontWeight="normal"
            alignment="center"
            size="small"
            className="mb-4"
          >
            Congratulations, you did it! <Icon id="check" size="sm" />
          </Text>
          <LaunchpadJourneyTileLayout
            projects={data.launchpad.projects}
            reflection={data.launchpad.reflection}
          />
        </>
      ) : (
        <LaunchpadJourneyTileLayout
          projects={data.launchpad.projects}
          reflection={data.launchpad.reflection}
        />
      )}
    </StudentJourneyProduct>
  );
};
