import { commonHooks, commonReducers } from '@polygence/common';
import { useEffect, useMemo } from 'react';
import { toast } from 'react-toastify';

import { PageLoad } from 'src/components/PageLoad';
import { CART_COOKIE_NAME, STUDENT_TEAM_EMAIL } from 'src/constants';
import { useCreatePaymentIntentForPodMutation } from 'src/reducers/paymentReducer';
import {
  useRegisterStudentToPodFromCookieMutation,
  useGetListOfStudentRelatedPodsQuery,
  useGetRequiredStudentInformationQuery,
  useGetTokenCountQuery,
  useEnrollWithTokenMutation,
} from 'src/reducers/podsReducers';
import { useAppDispatch } from 'src/store';
import { PodHubPage } from 'src/students/Pods/PodHubPage';
import { RequiredPodInformation } from 'src/students/Pods/RequiredPodInformation';
import { getCookie } from 'src/utils';

export const PodPage = () => {
  const dispatch = useAppDispatch();
  const currentUser = commonHooks.useCurrentUser();

  const { data: pods, isFetching: isPodsLoading } = useGetListOfStudentRelatedPodsQuery();
  const { data: requiredInfo, refetch: refetchRequiredInfo } =
    useGetRequiredStudentInformationQuery();
  const { data: { count: availableTokenCount = 0 } = {} } = useGetTokenCountQuery();
  const [registerStudentToPod, { isLoading: isRegisterLoading }] =
    useRegisterStudentToPodFromCookieMutation();
  const [createPaymentIntentForPod] = useCreatePaymentIntentForPodMutation();
  const [enrollWithToken, { isLoading: isTokenEnrollmentLoading }] = useEnrollWithTokenMutation();

  const mostRecentPod = pods?.[pods.length - 1];
  const hasAvailableToken = availableTokenCount > 0;
  const pendingPods = useMemo(() => pods?.filter((pod) => !pod.isEnrolled) ?? [], [pods]);

  const submitLabel = useMemo(() => {
    if (!hasAvailableToken) {
      return 'Proceed to payment';
    }
    return pendingPods.length === 1 ? 'Enroll now' : 'Submit';
  }, [hasAvailableToken, pendingPods.length]);

  const handleRequiredInfoSubmit = () => {
    if (!hasAvailableToken) {
      createPaymentIntentForPod().catch(() =>
        toast.error(`Couldn't create invoice. Please contact support! ${STUDENT_TEAM_EMAIL}`),
      );
    } else {
      if (pendingPods.length === 1 && pendingPods[0]?.pod && !pendingPods[0].pod.isPodFull) {
        enrollWithToken({ cohortId: pendingPods[0].pod.id }).unwrap().catch(console.error);
      }
    }
    refetchRequiredInfo().catch(console.error);
    return Promise.resolve(null);
  };

  useEffect(() => {
    const podCookie = getCookie(CART_COOKIE_NAME);
    if (podCookie) {
      registerStudentToPod()
        .then(() => {
          dispatch(
            commonReducers.userActions.updateUser({
              otherInfo: { ...currentUser.otherInfo, isInterestedInPod: true },
            }),
          );
        })
        .catch(() =>
          toast.error(
            `Couldn't handle your registration. Please contact support! ${STUDENT_TEAM_EMAIL}`,
          ),
        );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [registerStudentToPod, dispatch]);

  if (isPodsLoading || isRegisterLoading || isTokenEnrollmentLoading) {
    return <PageLoad />;
  }

  if (!mostRecentPod) {
    return <PodHubPage pods={[]} tokenCount={availableTokenCount} />;
  }
  const shouldTriggerPaymentIntentCreation = !hasAvailableToken && !mostRecentPod.paymentIntent;

  if (
    requiredInfo &&
    !mostRecentPod.isEnrolled &&
    mostRecentPod.pod.active &&
    (shouldTriggerPaymentIntentCreation || requiredInfo.isProfileIncomplete)
  ) {
    return (
      <RequiredPodInformation
        title={mostRecentPod.pod.course.title}
        startDate={mostRecentPod.pod.startDate}
        requiredInfo={requiredInfo}
        isProfileIncomplete={requiredInfo.isProfileIncomplete}
        handleRequiredInfoSubmit={handleRequiredInfoSubmit}
        submitLabel={submitLabel}
      />
    );
  }

  return <PodHubPage pods={pods} tokenCount={availableTokenCount} />;
};
