import type { Nullable } from '@polygence/common';
import { Card, Heading, Spacer, Text, Tooltip } from '@polygence/components';
import { Col, Row } from 'react-bootstrap';

import styles from 'src/components/overviews/counselor/CounselorOverview.module.scss';

export interface CounselorMetricsSectionProps {
  heading: string;
  className?: string;
  netPromoterScore: Nullable<number>;
  enrolledStudentsCount: number;
  refsByQuarter: number;
  refsByYear: number;
  enrolledByQuarter: number;
  enrolledByYear: number;
}

export const CounselorMetricsSection = ({
  heading,
  className,
  netPromoterScore,
  enrolledStudentsCount,
  refsByQuarter,
  refsByYear,
  enrolledByQuarter,
  enrolledByYear,
}: CounselorMetricsSectionProps) => {
  return (
    <div className={className}>
      <Card className="p-6">
        <Heading alignment="left" as="h2" className={styles['sectionHeader']} size="h5">
          {heading}
        </Heading>
        <Row className="gy-8">
          <Col xs={12} sm={6}>
            <Text alignment="left" className="text-muted" size="small">
              Total students enrolled
            </Text>
            <Text alignment="left" fontWeight="bold" size="large">
              {enrolledStudentsCount}
            </Text>
          </Col>
          <Tooltip
            placement="top"
            tip="This is the response to the latest NPS survey, not the NPS calculation."
          >
            <Col xs={12} sm={6}>
              <Text alignment="left" className="text-muted" size="small">
                Latest Counselor NPS Value
              </Text>
              <Text alignment="left" fontWeight="bold" size="large">
                {netPromoterScore ?? '-'}
              </Text>
            </Col>
          </Tooltip>
        </Row>
        <Spacer size={10} />
        <Row className="gy-8">
          <Col xs={12} sm={6}>
            <Text alignment="left" className="text-muted" size="small">
              Enrollments by Quarter
            </Text>
            <Text alignment="left" fontWeight="bold" size="large">
              {enrolledByQuarter ?? '-'}
            </Text>
          </Col>
          <Col xs={12} sm={6}>
            <Text alignment="left" className="text-muted" size="small">
              Referrals by Quarter
            </Text>
            <Text alignment="left" fontWeight="bold" size="large">
              {refsByQuarter ?? '-'}
            </Text>
          </Col>
        </Row>
        <Spacer size={10} />
        <Row className="gy-8">
          <Col xs={12} sm={6}>
            <Text alignment="left" className="text-muted" size="small">
              Enrollments by Year
            </Text>
            <Text alignment="left" fontWeight="bold" size="large">
              {enrolledByYear ?? '-'}
            </Text>
          </Col>
          <Col xs={12} sm={6}>
            <Text alignment="left" className="text-muted" size="small">
              Referrals by Year
            </Text>
            <Text alignment="left" fontWeight="bold" size="large">
              {refsByYear ?? '-'}
            </Text>
          </Col>
        </Row>
      </Card>
    </div>
  );
};
