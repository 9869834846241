import { Dispatch, SetStateAction, useCallback, useEffect } from 'react';
import { QueryParamConfigMap, useQueryParams } from 'use-query-params';

export function useQueryParamsForFilter<T extends QueryParamConfigMap>(
  paramConfigMap: T,
  setFiltersState: Dispatch<SetStateAction<Record<string, unknown>>>,
) {
  const [query, setQuery] = useQueryParams<T>(paramConfigMap);
  useEffect(() => {
    const notEmptyQuery = Object.keys(query).reduce(
      (prev, curr) => {
        if (query[curr] !== undefined && query[curr] !== null) {
          return { ...prev, [curr]: query[curr] as unknown };
        }
        return prev;
      },
      {} as Record<string, unknown>,
    );
    setFiltersState((prevFilters) => ({ ...prevFilters, ...notEmptyQuery }));
  }, [query, setFiltersState]);

  const setFiltersInQuery = useCallback(
    (filters: SetStateAction<Partial<typeof query>>) => {
      setQuery(filters);
      setFiltersState(filters as SetStateAction<Record<string, unknown>>);
    },
    [setFiltersState, setQuery],
  );
  return { query, setFiltersInQuery };
}
