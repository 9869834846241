import type { CoreResponse, ProjectStatus, ProjectTypes } from '@polygence/common';
import type { IconVariable, Variant } from '@polygence/components';

export const ASSIGNMENT_ACTIONS = {
  submitAssignment: {
    name: 'SubmitAssignment',
    sessionCardButtonLabel: 'Submit',
    sessionCardButtonVariant: 'primary',
  },
  submittedAssignment: {
    name: 'SubmittedAssignment',
    sessionCardButtonLabel: 'Submitted',
    sessionCardButtonVariant: 'tertiary',
  },
  toBeSubmittedAssignment: {
    name: 'ToBeSubmittedAssignment',
    sessionCardButtonLabel: 'To be submitted',
    sessionCardButtonVariant: 'secondary',
  },
  viewSubmittedAssignment: {
    name: 'ViewSubmittedAssignment',
    sessionCardButtonLabel: 'View Submission',
    sessionCardButtonVariant: 'tertiary',
  },
};

export const MILESTONE_ACTIONS = {
  submitAssignment: {
    ...ASSIGNMENT_ACTIONS.submitAssignment,
    instructionModalButtonLabel: 'Proceed to Submit',
    researchQuestionAndAbstractUploadInstruction:
      'Please add the research question and project description. You can update them at any time, even after session 3.',
    milestone: true,
  },
  submittedAssignment: {
    ...ASSIGNMENT_ACTIONS.submittedAssignment,
    instructionModalButtonLabel: 'View Submission',
    researchQuestionAndAbstractUploadInstruction:
      'Please add the research question and project description. You can update them at any time, even after session 3.',
    milestone: true,
  },
  toBeSubmittedAssignment: {
    ...ASSIGNMENT_ACTIONS.toBeSubmittedAssignment,
    instructionModalButtonLabel: 'View Student Submission',
    researchQuestionAndAbstractUploadInstruction:
      'Please find the research question and project description below.',
    milestone: true,
  },
  viewSubmittedAssignment: {
    ...ASSIGNMENT_ACTIONS.viewSubmittedAssignment,
    instructionModalButtonLabel: 'View Student Submission',
    researchQuestionAndAbstractUploadInstruction:
      'Please find the research question and project description below.',
    milestone: true,
  },
};

export const EXTENSION_REQUEST_ACTIONS = {
  request: 'Request Extension',
  student_view: 'Extension Request sent to mentor',
  mentor_view: 'Extension Request sent to student',
  respond: 'Respond to Extension Request',
  extend_deadline: 'Update Deadline',
} as const;

export const PROJECT_STATUS = {
  ACTIVE: 'active',
  COMPLETE: 'complete',
  TERMINATED: 'terminated',
};

export const MAX_ASSIGNMENTS_PER_SESSION = 10;

type ProjectStatusColors = Record<ProjectStatus, Variant>;
type ProjectTypeColors = Record<ProjectTypes, Variant>;
type CoreResponseColors = Record<CoreResponse, Variant>;
type CoreResponseIcons = Record<CoreResponse, IconVariable>;

export const PROJECT_STATUS_COLORS: ProjectStatusColors = {
  terminated: 'danger',
  active: 'primary',
  complete: 'secondary',
} as const;

export const PROJECT_TYPE_COLORS: ProjectTypeColors = {
  flagship: 'success',
  flagship_white_label: 'success',
  pathfinder_deprecated: 'secondary',
  pathfinder_diagnostic: 'tertiary',
  pathfinder_launchpad: 'secondary',
  pathfinder_standalone: 'secondary',
  showcasing_publishing: 'tertiary',
  showcasing_presenting: 'tertiary',
  showcasing_competing: 'tertiary',
  showcasing_multimedia: 'tertiary',
  pods: 'secondary',
  advanced_scholars_program: 'success',
  research_coach: 'secondary',
} as const;

export const CORE_RESPONSE_COLORS: CoreResponseColors = {
  yes: 'primary',
  no: 'danger',
  maybe: 'warning',
} as const;

export const CORE_RESPONSE_ICONS: CoreResponseIcons = {
  yes: 'check',
  no: 'x',
  maybe: 'help-circle',
} as const;

export const OUTPUT_TYPE_OPTIONS = [
  { value: 'review_paper', label: 'Review paper' },
  { value: 'original_research_paper', label: 'Original research paper' },
  { value: 'github_code_base', label: 'Github' },
  { value: 'creative_writing', label: 'Creative writing' },
  { value: 'computer_simulation', label: 'Computer simulation' },
  { value: 'opinion_editorial', label: 'Opinion editorial' },
  { value: 'infographic', label: 'Infographic' },
  { value: 'video_or_docu_series', label: 'Video or Docu series' },
  { value: 'webinar', label: 'Webinar' },
  { value: 'podcast', label: 'Podcast' },
  { value: 'organization', label: 'Organization' },
  { value: 'art_work_or_portfolio', label: 'Art work or Portfolio' },
  { value: 'science_fair_project', label: 'Science fair project' },
  { value: 'app_game', label: 'App or Game' },
  { value: 'other', label: 'Other' },
] as const;

export const OUTPUT_TYPES = {
  review_paper: 'Review paper',
  original_research_paper: 'Original research paper',
  github_code_base: 'Github',
  creative_writing: 'Creative writing',
  computer_simulation: 'Computer simulation',
  opinion_editorial: 'Opinion editorial',
  infographic: 'Infographic',
  video_or_docu_series: 'Video or Docu series',
  webinar: 'Webinar',
  podcast: 'Podcast',
  organization: 'Organization',
  art_work_or_portfolio: 'Art work or Portfolio',
  science_fair_project: 'Science fair project',
  app_game: 'App or Game',
  other: 'Other',
};
