import type { UserId } from '@polygence/common';
import * as HoverCard from '@radix-ui/react-hover-card';
import type { ReactNode } from 'react';

import { UserCard } from 'src/components/common/UserCard/UserCard';
import styles from 'src/components/common/UserCard/UserCard.module.scss';
import { usePrefetch } from 'src/reducers/marketplaceReducer';

interface UserCardHoverTriggerProps {
  children: ReactNode;
  userId: UserId | null | undefined;
  prefetchOnHover?: boolean;
}

export const UserCardHoverTrigger = ({
  children,
  userId,
  prefetchOnHover,
}: UserCardHoverTriggerProps) => {
  const prefetchUserCard = usePrefetch('userCards');

  const handleOnMouseEnter = () => {
    if (userId && prefetchOnHover) {
      prefetchUserCard({ userId });
    }
  };

  if (!userId) {
    return <>{children}</>;
  }

  return (
    <HoverCard.Root>
      <HoverCard.Trigger asChild onPointerEnter={handleOnMouseEnter}>
        <span role="button" tabIndex={0} className={styles['trigger']}>
          {children}
        </span>
      </HoverCard.Trigger>
      <HoverCard.Portal>
        <HoverCard.Content
          sideOffset={5}
          align="start"
          alignOffset={-20}
          style={{ zIndex: 1070 /* $zindex-popover from Bootstrap, so it's above modals */ }}
        >
          <UserCard userId={userId} />
        </HoverCard.Content>
      </HoverCard.Portal>
    </HoverCard.Root>
  );
};
