import { Card, Heading, Collapsible } from '@polygence/components';
import classNames from 'classnames';
import type { ReactNode } from 'react';

import styles from 'src/components/Tiles/StudentJourney/common.module.scss';
import { useStudentJourney } from 'src/components/Tiles/StudentJourney/useStudentJourney';
import { getTracker } from 'src/utils/tracking/factory';

export const StudentJourneyProduct = ({
  title,
  children,
  loader,
  dashed = false,
  defaultOpen = false,
  productType,
}: {
  title: ReactNode;
  children: ReactNode;
  loader: ReactNode;
  dashed?: boolean;
  defaultOpen?: boolean;
  productType: 'core' | 'pss' | 'launchpad';
}) => {
  const { isLoading } = useStudentJourney();

  return (
    <>
      {!dashed ? (
        <Card as="section" className={styles['studentJourneyProductCard']}>
          <Collapsible
            defaultOpen={defaultOpen}
            onOpen={() => {
              getTracker().track('Student Journey Collapsible Opened', {
                category: 'student_journey_tile',
                productType,
              });
            }}
            summary={title}
            rootClassNames="tw-mb-3"
            summaryClassNames="tw-text-xl"
            contentClassNames="tw-py-3"
          >
            {isLoading ? loader : children}
          </Collapsible>
        </Card>
      ) : (
        <Card
          as="section"
          className={classNames(styles['studentJourneyProductCard'], styles['pitchCard'])}
        >
          <Heading as="h1" size="h4" alignment="center" className="tw-mb-3">
            {title}
          </Heading>
          {isLoading ? loader : children}
        </Card>
      )}
    </>
  );
};
