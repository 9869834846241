import {
  EmptyResponse,
  EndOfPodStatus,
  PodsCohortId,
  PublicPodsCohort,
  PublicPodsCourse,
} from '@polygence/common';
import { StudentRelatedPod, RequiredStudentInformation } from '@polygence/common/types/data/pods';
import { createApi } from '@reduxjs/toolkit/query/react';

import { axiosBaseQuery } from 'src/reducers/axiosBaseQuery';

export const podsApi = createApi({
  baseQuery: axiosBaseQuery(),
  reducerPath: 'pods',
  tagTypes: ['PodsOfStudent', 'RequiredStudentInformation', 'Courses', 'Cohorts', 'TokenCount'],
  endpoints: (build) => {
    return {
      getListOfStudentRelatedPods: build.query<StudentRelatedPod[], void>({
        query: () => ({
          url: '/pods/student-related-pods/',
          method: 'GET',
        }),
        providesTags: ['PodsOfStudent'],
      }),
      getRequiredStudentInformation: build.query<RequiredStudentInformation, void>({
        query: () => ({
          url: '/pods/student-required-information/',
          method: 'GET',
        }),
        providesTags: ['RequiredStudentInformation'],
      }),
      registerStudentToPodFromCookie: build.mutation<EmptyResponse, void>({
        query: () => ({
          url: '/pods/register-student-to-pod-from-cookie/',
          method: 'POST',
          withCredentials: true,
        }),
        invalidatesTags: ['PodsOfStudent'],
      }),
      getStudentEndOfPodStatus: build.query<EndOfPodStatus, PodsCohortId>({
        query: (podsCohortId) => ({
          url: `/pods/student-end-of-pod-status/${podsCohortId}/`,
          method: 'GET',
        }),
      }),
      getPodsCohortsByCourseId: build.query<
        PublicPodsCohort[],
        { courseId: number; futurePods?: boolean }
      >({
        query: ({ courseId, futurePods = false }) => ({
          url: `/pods/courses/${courseId}/cohorts/`,
          method: 'GET',
          params: { ...(futurePods && { start_date__gt: new Date().toISOString() }) },
        }),
        providesTags: ['Cohorts'],
      }),
      getPodsCourses: build.query<PublicPodsCourse[], void>({
        query: () => ({
          url: `/pods/courses/`,
          method: 'GET',
        }),
        providesTags: ['Courses'],
      }),
      getTokenCount: build.query<{ count: number }, void>({
        query: () => ({
          url: '/pods/get-available-pod-token-count/',
          method: 'GET',
        }),
        providesTags: ['TokenCount'],
      }),
      enrollWithToken: build.mutation<EmptyResponse, { cohortId: PodsCohortId }>({
        query: ({ cohortId }) => ({
          url: `/pods/cohorts/${cohortId}/enroll/`,
          method: 'POST',
        }),
        invalidatesTags: ['TokenCount', 'PodsOfStudent'],
      }),
      optOutWaitlistSubmission: build.mutation<EmptyResponse, { uuid: string }>({
        query: ({ uuid }) => ({
          url: `/pods/waitlist-submission/${uuid}/opt-out/`,
          method: 'PATCH',
        }),
      }),
    };
  },
});

export const {
  useGetListOfStudentRelatedPodsQuery,
  useGetRequiredStudentInformationQuery,
  useRegisterStudentToPodFromCookieMutation,
  useLazyGetStudentEndOfPodStatusQuery,
  useGetPodsCoursesQuery,
  useLazyGetPodsCohortsByCourseIdQuery,
  useGetTokenCountQuery,
  useEnrollWithTokenMutation,
  useOptOutWaitlistSubmissionMutation,
} = podsApi;
