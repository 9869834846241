import { Text } from '@polygence/components';
import { useEffect } from 'react';
import { useParams } from 'react-router-dom';

import { useOptOutWaitlistSubmissionMutation } from 'src/reducers/podsReducers';

export const WaitlistSubmissionOptOutPage = () => {
  const { uuid } = useParams<{ uuid: string }>();
  const [optOutOfWaitlist] = useOptOutWaitlistSubmissionMutation();

  useEffect(() => {
    if (uuid) {
      optOutOfWaitlist({ uuid }).unwrap().catch(console.error);
    }
  }, [optOutOfWaitlist, uuid]);

  return (
    <div className="container tw-flex tw-justify-center">
      <Text size="large">You've successfully opted out of the Pod's waitlist.</Text>
    </div>
  );
};
