import type { UserCard } from '@polygence/common';
import { Avatar, Text } from '@polygence/components';
import type { PropsWithChildren } from 'react';

import styles from 'src/components/common/UserCard/UserCard.module.scss';

type UserCardUserInfoProps = PropsWithChildren<
  Pick<
    UserCard,
    'description' | 'firstName' | 'lastName' | 'profilePicture' | 'timezone' | 'pronouns'
  >
>;

export const UserCardUserInfo = ({
  firstName,
  lastName,
  description,
  profilePicture,
  timezone,
  pronouns,
  children,
}: UserCardUserInfoProps) => {
  return (
    <div className="d-flex gap-4">
      <Avatar
        user={{
          firstName,
          lastName,
          profilePicture,
        }}
        size={100}
      />
      <div className="d-flex flex-column justify-content-center">
        <div className="d-flex gap-3 align-items-center">
          <Text size="large" fontWeight="bold">
            {`${firstName} ${lastName}`}
          </Text>
          {pronouns && (
            <Text size="small" fontWeight="light" className={styles['grayscale-6']}>
              ({pronouns})
            </Text>
          )}
        </div>
        {description.map((item) => (
          <Text key={item} size="medium" fontWeight="normal">
            {item}
          </Text>
        ))}
        <Text size="small" fontWeight="light" className={styles['grayscale-6']}>
          {timezone.replace('_', ' ')}
        </Text>
        {children}
      </div>
    </div>
  );
};
