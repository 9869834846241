import {
  projectIssueQuestions,
  techIssueQuestions,
} from 'src/components/hermes/surveys/mentor/generalQuestions';
import { sessionCountAsQuestion } from 'src/components/hermes/surveys/mentor/metaQuestions';
import {
  makeQuestionsOnlyAvailableForPolygence,
  makeQuestionsSentToStudentSection,
} from 'src/components/hermes/surveys/mentor/utils';

export const publicQuestions = [
  {
    component: 'LabelWithTooltip',
    props: {
      children: 'Recap the session in 2 sentences:',
      tooltip:
        'This brief summary reminds your student of your conversation and informs their parent about what you discussed.',
      labelFor: 'sessionRecap',
    },
  },
  {
    component: 'FormInput',
    props: {
      name: 'sessionRecap',
      inputType: 'textarea',
      placeholder: 'It was great chatting with you about...',
      htmlRequired: true,
    },
  },
  {
    component: 'LabelWithTooltip',
    props: {
      children: 'What I heard from you in this session was that...',
      tooltip:
        'This is a chance to be the student’s mirror and reflect back to them what you heard in the session. Consider highlighting where you saw them get excited, any strong project ideas or beginning stages of strong project ideas that they brought up, etc.',
      labelFor: 'sessionStudentHighlight',
    },
  },
  {
    component: 'FormInput',
    props: {
      name: 'sessionStudentHighlight',
      inputType: 'textarea',
      htmlRequired: true,
      placeholder: 'You are...',
    },
  },
  {
    component: 'LabelWithTooltip',
    props: {
      children: 'Before jumping into a full research project, you should:',
      tooltip:
        'Sometimes students need to be honest about what is and isn’t realistic about their developing project idea. This section is a place to give honest feedback to the student on what skills they need to build and/or how they need to develop their project idea further in order to have a successful Core project. \n\n If they seem fully prepared for the Core program already, feel free to skip or provide more general feedback.',
      labelFor: 'launchpadRecommendation',
    },
  },
  {
    component: 'FormInput',
    props: {
      name: 'launchpadRecommendation',
      inputType: 'textarea',
      rows: 5,
      placeholder: 'Consider xyz, think about...',
      htmlRequired: true,
    },
  },
  {
    component: 'LabelWithTooltip',
    props: {
      children:
        'Given what I heard from you, here are 2-3 project ideas that I think you should consider doing for your Core Project:',
      tooltip:
        'Let’s help this student get concrete! The more you can provide sample project ideas for them to consider that align with what you heard are the student’s interests and goals, the closer they get to their goals of defining their research project idea by the end of Launchpad.',
      labelFor: 'projectIdea1',
    },
  },
  {
    component: 'FormInput',
    props: {
      name: 'projectIdea1',
      inputType: 'textarea',
      htmlRequired: true,
    },
  },
  {
    component: 'FormInput',
    props: {
      name: 'projectIdea2',
      inputType: 'textarea',
    },
  },
  {
    component: 'FormInput',
    props: {
      name: 'projectIdea3',
      inputType: 'textarea',
    },
  },
  {
    component: 'LabelWithTooltip',
    props: {
      children:
        'Here are some additional resources to check out to explore the project ideas we discussed:',
      tooltip:
        'Especially if your student was more in the beginning stage of crafting a project idea, what do you encourage them to explore in their own time to get closer to a more concrete project idea?',
      labelFor: 'recommendedResources',
    },
  },
  {
    component: 'FormInput',
    props: {
      name: 'recommendedResources',
      inputType: 'textarea',
      rows: 5,
      placeholder:
        'For example: \n1. Books/papers/articles to read \n2. Podcasts/TedTalks to explore \n3. Summer programs to consider \n4. Online courses to take or a competition to enter',
      htmlRequired: true,
    },
  },
  {
    component: 'LabelWithTooltip',
    props: {
      children: 'Send a quick note of encouragement:',
      tooltip: 'A short note of encouragement can go a long way for students!',
      labelFor: 'studentEncouragement',
    },
  },
  {
    component: 'FormInput',
    props: {
      name: 'studentEncouragement',
      inputType: 'textarea',
      htmlRequired: true,
    },
  },
] as const;

export const privateQuestions = [
  {
    component: 'LabelWithTooltip',
    props: {
      children: 'Please asses the student’s readiness to do a Core Polygence program:',
      tooltip:
        'This helps us know how much additional support this student might need prior to starting and during their project.',
      labelFor: 'studentReadinessForCore',
    },
  },
  {
    component: 'ComponentsText',
    props: {
      children: '1 star: As of now, this student will struggle in the Core project',
      size: 'small',
      fontWeight: 'light',
    },
  },
  {
    component: 'ComponentsText',
    props: {
      children: '5 star: This student will do extremely well in the Core project',
      size: 'small',
      fontWeight: 'light',
    },
  },
  {
    component: 'StarRating',
    props: {
      name: 'studentReadinessForCore',
      starCount: 5,
    },
  },
  {
    component: 'LabelWithTooltip',
    props: {
      children:
        'If this student chose to do a Core Program (10 session project), would you want to work with them?',
      tooltip:
        'This data helps us know if we should or should not reach out to you to propose a match for the Core program should this student get to that point.',
    },
  },
  {
    component: 'RadioButton',
    props: {
      name: 'openToMentorCore',
      required: true,
      showLabel: false,
      options: [
        { label: 'Yes', value: 'yes' },
        {
          label: 'No',
          value: 'no',
        },
        { value: 'maybe', label: 'Maybe' },
      ],
    },
  },
  {
    component: 'LabelWithTooltip',
    props: {
      children:
        'In 1-2 sentences, please describe how close you think this student is to having a concrete great project idea:',
      tooltip:
        'This evaluation helps us know where the student is at as we prepare for a Reflection Session with them at the end of their Launchpad program.',
    },
  },
  {
    component: 'FormInput',
    props: {
      name: 'projectIdeaFeedback',
      inputType: 'textarea',
    },
  },
  {
    component: 'LabelWithTooltip',
    props: {
      children:
        'What should future mentors consider when helping this student? Any particular support you think this student might need in the future?',
      tooltip:
        'Your answer to this question is often shared with other mentors meeting with this same student so they can have more context into what the student most needs.',
    },
  },
  {
    component: 'FormInput',
    props: {
      name: 'goodToKnowForMentors',
      inputType: 'textarea',
    },
  },
  {
    component: 'LabelWithTooltip',
    props: {
      children:
        'Would you like to share any additional thoughts on how we can improve Launchpad? (Optional)',
      tooltip: 'We can always get better! Thanks for helping us improve.',
    },
  },
  {
    component: 'FormInput',
    props: {
      name: 'improvementIdea',
      inputType: 'textarea',
    },
  },
] as const;

export const launchpadQuestions = [
  sessionCountAsQuestion,
  makeQuestionsSentToStudentSection(publicQuestions),
  makeQuestionsOnlyAvailableForPolygence(privateQuestions),
  ...techIssueQuestions,
  ...projectIssueQuestions,
] as const;
