import type { UserCardPartnerTag as UserCardPartnerTagType } from '@polygence/common';
import { Badge, Text, Tooltip } from '@polygence/components';

interface UserCardPartnerTagProps {
  tag: UserCardPartnerTagType;
}

export const UserCardPartnerTag = ({ tag }: UserCardPartnerTagProps) => {
  return (
    <Tooltip placement="bottom" tip={tag.definition}>
      <div>
        <Badge
          size="medium"
          variant={tag.severity === 'high' ? 'tertiary' : 'secondary'}
          className="tw-flex tw-justify-center tw-px-3 tw-py-1"
        >
          <Text
            size="small"
            fontWeight="bold"
            textWrap={tag.label.length > 15 ? 'wrap' : 'nowrap'}
            className={tag.severity === 'high' ? 'tw-text-white' : 'tw-text-black'}
          >
            {tag.label}
          </Text>
        </Badge>
      </div>
    </Tooltip>
  );
};
