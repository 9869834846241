import { commonHooks } from '@polygence/common';
import { InputField } from '@polygence/components';
import { Dispatch, SetStateAction, useEffect, useState } from 'react';
import { StringParam } from 'use-query-params';

import { useQueryParamsForFilter } from 'src/hooks/useQueryParamsForFilter';

export const SearchComponent = ({
  filters,
  setFilters,
}: {
  filters: Record<string, unknown>;
  setFilters: Dispatch<SetStateAction<Record<string, unknown>>>;
}) => {
  const { query, setFiltersInQuery } = useQueryParamsForFilter(
    {
      q: StringParam,
    },
    setFilters,
  );
  const [search, setSearch] = useState((filters['q'] || query['q']) as string | undefined);
  const debouncedSearch = commonHooks.useDebounce(search, 500);

  useEffect(() => {
    if (
      typeof debouncedSearch === 'string' &&
      (debouncedSearch.length >= 3 || debouncedSearch === '')
    ) {
      setFiltersInQuery((prevFilters) => {
        return { ...prevFilters, q: debouncedSearch };
      });
    }
  }, [debouncedSearch, setFiltersInQuery]);

  const handleSearch = ({ target: { value } }: { target: { value: string } }) => {
    setSearch(value);
  };

  return (
    <InputField
      name="search"
      label=""
      floating={false}
      value={search}
      placeholder="Search (min 3 chars)"
      onChange={handleSearch}
    />
  );
};
