import { ProjectPlan } from '@polygence/common';
import { useMemo } from 'react';

import { ProjectPlanContent } from 'src/components/hermes/project-plan/ProjectPlan';
import { ProjectPlanContext } from 'src/components/hermes/project-plan/ProjectPlanContext';

export const StudentSnapshotProjectPlan = ({ projectPlan }: { projectPlan: ProjectPlan }) => {
  const contextValue = useMemo(
    () => ({
      projectPlan,
      setProjectPlanDraft: () => null,
    }),
    [projectPlan],
  );

  return (
    <ProjectPlanContext.Provider value={contextValue}>
      <ProjectPlanContent content={projectPlan.contentJson} className="tw-p-0" />
    </ProjectPlanContext.Provider>
  );
};
