import type { UserId, WorkspaceId } from '@polygence/common';
import { Card, Spacer, cn } from '@polygence/components';
import type { ReactNode } from 'react';

import styles from 'src/components/common/UserCard/UserCard.module.scss';
import { UserCardAction } from 'src/components/common/UserCard/UserCardAction';
import { UserCardBadge } from 'src/components/common/UserCard/UserCardBadge';
import { UserCardPartnerTag } from 'src/components/common/UserCard/UserCardPartnerTag';
import { UserCardSkeleton } from 'src/components/common/UserCard/UserCardSkeleton';
import { UserCardUserInfo } from 'src/components/common/UserCard/UserCardUserInfo';
import { useUserCardsQuery } from 'src/reducers/marketplaceReducer';

interface UserCardProps {
  userId: UserId;
  workspaceId?: WorkspaceId;
  actions?: ReactNode;
}

export const UserCard = ({ userId, workspaceId, actions }: UserCardProps) => {
  const { data, isLoading } = useUserCardsQuery({ userId, workspaceId });

  return (
    <Card className={cn(styles['userCard'], 'tw-flex tw-flex-col tw-gap-5 tw-p-5')}>
      {isLoading || !data ? (
        <UserCardSkeleton />
      ) : (
        <>
          <UserCardUserInfo {...data}>
            <Spacer size={2} />
            {data.actions.map((action) => (
              <UserCardAction key={action.label} {...action} />
            ))}
            {actions}
          </UserCardUserInfo>
          {data.badges && (
            <div className="tw-flex tw-flex-wrap tw-gap-3">
              {data.badges.map((badge) => (
                <UserCardBadge key={badge.label} badge={badge} />
              ))}
            </div>
          )}
          {data.partnerTags.length > 0 && (
            <div className="tw-flex tw-flex-wrap tw-gap-3">
              {data.partnerTags.map((tag) => (
                <UserCardPartnerTag key={tag.label} tag={tag} />
              ))}
            </div>
          )}
          {data.envisionInterest && (
            <div className="tw-flex tw-flex-wrap tw-gap-3">
              <UserCardPartnerTag
                key="envision"
                tag={{
                  label: 'Envision interest',
                  definition: 'Student is interested in Envision program',
                  severity: 'medium',
                }}
              />
            </div>
          )}
        </>
      )}
    </Card>
  );
};
