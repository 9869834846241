import { Skeleton } from '@polygence/components';

export const UserCardSkeleton = () => {
  return (
    <>
      <div className="d-flex gap-4">
        <Skeleton variant="circle" width="100px" />
        <div className="d-flex flex-column justify-content-center">
          <Skeleton width="260px" height="20px" className="mb-2" />
          <Skeleton width="220px" height="14px" className="mb-1" />
        </div>
      </div>
      <div className="d-flex gap-4">
        <Skeleton width="180px" height="20px" />
      </div>
    </>
  );
};
