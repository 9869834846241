import { createContext } from 'react';

export interface UserCardContextType {
  closeCard: () => void;
}

export const UserCardContext = createContext<UserCardContextType>({
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  closeCard: () => {},
});
// eslint-disable-next-line fp/no-mutation
UserCardContext.displayName = 'UserCardContext';
