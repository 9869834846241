import { Nullable } from '@polygence/common';
import { Heading, Icon, InputField, Text, Button, Card, Spacer } from '@polygence/components';
import { useEffect, useRef, useState } from 'react';
import { useParams } from 'react-router';
import { toast } from 'react-toastify';

import { Loader } from 'src/components/Loader';
import { STUDENT_TEAM_EMAIL } from 'src/constants/emails';
import {
  useInvitationPromptQuery,
  useCreateInvitationMutation,
} from 'src/reducers/worldstridesReducer';

export const WorldStridesParentToChildInvitationPage = () => {
  const { uuid } = useParams<{ uuid: string }>();
  const inputRef = useRef<HTMLInputElement | null>(null);
  const { currentData: invitationPrompt, isLoading: queryIsLoading } = useInvitationPromptQuery({
    uuid,
  });
  const [invitationUrl, setInvitationUrl] = useState<Nullable<string>>(null);
  const [createInvitation, { isLoading: createIsLoading }] = useCreateInvitationMutation();

  const handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    const formData = new FormData(event.currentTarget);
    const email = formData.get('email');

    if (!email) {
      return;
    }

    createInvitation({
      uuid,
      payload: {
        email: email as string,
      },
    })
      .unwrap()
      .then((response) => {
        setInvitationUrl(response.invitationUrl);
      })
      .catch(() => {
        toast.error(
          `We could not create the invitation. If the issue persists after reloading the page, please contact us at ${STUDENT_TEAM_EMAIL}.`,
        );
      });
  };

  const handleCopy = () => {
    invitationUrl &&
      navigator.clipboard
        .writeText(invitationUrl)
        .then(() => toast.success('Copied to clipboard'))
        .catch(() => toast.error(`Could not copy to clipboard`));
  };

  useEffect(() => {
    if (inputRef.current) {
      inputRef.current.focus();
    }
  }, [invitationPrompt]);

  if (queryIsLoading) {
    return (
      <div className="tw-mt-[20vh] tw-flex tw-justify-center">
        <Loader />
      </div>
    );
  }

  if (!invitationPrompt) {
    return (
      <div className="tw-mt-[20vh] tw-flex tw-justify-center">
        <Text size="large" className="tw-text-danger">
          No invitation found.
        </Text>
      </div>
    );
  }

  return (
    <div className="tw-flex tw-flex-col tw-align-middle">
      <Heading alignment="center" size="h3" className="tw-mx-3 tw-mt-4 sm:tw-mt-8">
        Invitation Form
      </Heading>
      <Spacer size={6} />

      {invitationUrl && (
        <div className="tw-flex tw-flex-col tw-items-center">
          <Text alignment="center" size="medium" className="tw-mx-3">
            We've successfully sent the invitation email to your child.
          </Text>
          <Spacer size={10} />
          <Text alignment="center" size="medium" className="tw-mx-3">
            As an alternative, you may wish to share the following invitation link with your child
          </Text>
          <Spacer size={4} />

          <Button
            variant="tertiary"
            size="sm"
            className="tw-mx-3 tw-break-all"
            endIcon={<Icon id="copy" />}
            onClick={handleCopy}
          >
            Copy invitation link
          </Button>
        </div>
      )}

      {!invitationUrl && (
        <>
          <Text alignment="center" size="medium" className="tw-mx-3 tw-mb-2">
            Dear{' '}
            <span className="tw-font-bold">
              {invitationPrompt.parentFirstName} {invitationPrompt.parentLastName}
            </span>
            {/* */}! Please complete the form so that we can invite your child to choose the program
            they would like to join.
          </Text>
          <Text alignment="center" size="medium" className="tw-mx-3">
            Because we are a virtual education program hosted on our secure learning platform,
            students need their own accounts to enroll.
          </Text>

          <form onSubmit={handleSubmit}>
            <Card className="tw-mx-auto tw-mt-6 tw-flex tw-max-w-[420px] tw-flex-col tw-gap-2 tw-p-7 md:tw-mt-10">
              <InputField
                name="firstName"
                label="First Name"
                value={invitationPrompt.studentFirstName}
                disabled
              />
              <InputField
                name="lastName"
                label="Last Name"
                value={invitationPrompt.studentLastName}
                disabled
              />
              <InputField
                ref={inputRef}
                defaultValue={invitationPrompt.studentEmail}
                type="email"
                name="email"
                label="Email Address"
                required
              />
              <Spacer size={8} />
              <Text alignment="center" size="medium" className="tw-mx-auto">
                To redeem the enrollment voucher
              </Text>
              <Button
                variant="primary"
                type="submit"
                size="lg"
                className="tw-mx-auto tw-w-fit"
                disabled={createIsLoading}
              >
                Invite your child
              </Button>
            </Card>
          </form>
        </>
      )}
      <Spacer size={13} />
    </div>
  );
};
