import { useParams } from 'react-router';

import { CounselorMetricsSection } from 'src/components/overviews/counselor/CounselorMetricsSection';
import { useWelcomePageQuery } from 'src/reducers/partnerDashboardReducers';

export const WelcomePage = () => {
  const { id } = useParams<{ id: string }>();
  const { data, isSuccess } = useWelcomePageQuery(Number(id));

  if (isSuccess) {
    return (
      <div>
        <CounselorMetricsSection
          heading="Metrics"
          netPromoterScore={null}
          enrolledStudentsCount={data.enrolledStudentsCount}
          refsByQuarter={data.referralStudentsByCurrentQuarter}
          refsByYear={data.referralStudentsByCurrentYear}
          enrolledByQuarter={data.enrolledStudentsByCurrentQuarter}
          enrolledByYear={data.enrolledStudentsByCurrentYear}
        />
      </div>
    );
  }

  return null;
};
