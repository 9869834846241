import { Text } from '@polygence/components';
import classNames from 'classnames';
import { JSXElementConstructor, useContext } from 'react';

import { StudentSnapshotFeedback } from 'src/components/common/StudentSnapshot/ContentRenderers/Feedback';
import { StudentSnapshotMarkdown } from 'src/components/common/StudentSnapshot/ContentRenderers/Markdown';
import { StudentSnapshotProjectPlan } from 'src/components/common/StudentSnapshot/ContentRenderers/ProjectPlan';
import { StudentSnapshotReflectionCallSummary } from 'src/components/common/StudentSnapshot/ContentRenderers/ReflectionCallSummary';
import { StudentSnapshotSummaries } from 'src/components/common/StudentSnapshot/ContentRenderers/Summaries';
import { StudentSnapshotTopics } from 'src/components/common/StudentSnapshot/ContentRenderers/Topics';
import { StudentSnapshotContext } from 'src/components/common/StudentSnapshot/StudentSnapshot';
import styles from 'src/components/common/StudentSnapshot/StudentSnapshot.module.scss';
import type { StudentSnapshotPage } from 'src/reducers/marketplaceReducer';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const CONTENT_COMPONENTS: Record<string, JSXElementConstructor<any>> = {
  Markdown: StudentSnapshotMarkdown,
  Topics: StudentSnapshotTopics,
  Summaries: StudentSnapshotSummaries,
  ReflectionCallSummary: StudentSnapshotReflectionCallSummary,
  Feedbacks: StudentSnapshotFeedback,
  ProjectPlan: StudentSnapshotProjectPlan,
};

const contentContainerClassNames = classNames(
  'd-grid place-items-center bg-grayscale-2 p-5',
  styles['studentSnapshotContent'],
);

export const StudentSnapshotContent = ({ data }: { data: StudentSnapshotPage }) => {
  const { selectedTabIndex } = useContext(StudentSnapshotContext);

  const selectedTab = data?.tabs[selectedTabIndex];
  const content = selectedTab?.content;
  const Component = CONTENT_COMPONENTS[content?.component ?? ''];

  if (!Component) {
    // don't render anything, the error gets handled by the ErrorBoundary
    throw new Error(`Unknown Component in StudentSnapshotContent! (${String(content?.component)})`);
  }

  const componentProps = content?.props ?? {};

  return (
    <div className={contentContainerClassNames}>
      <Component {...componentProps} />
    </div>
  );
};

export const StudentSnapshotEmptyContent = () => {
  return (
    <div className="d-flex align-items-center justify-content-center">
      <Text size="medium" fontWeight="light" alignment="center">
        There seems to be nothing here.
      </Text>
    </div>
  );
};

export const StudentSnapshotErrorInContent = () => {
  return (
    <div className={contentContainerClassNames}>
      <StudentSnapshotEmptyContent />
    </div>
  );
};
