import { axiosBaseQuery } from '@polygence/common/api/fetch';
import type {
  MeetingId,
  ProjectPauseRequestId,
  ProjectPlanId,
} from '@polygence/common/types/common';
import type { ProjectPauseRequest, ProjectPlan } from '@polygence/common/types/hermes';
import { createApi } from '@reduxjs/toolkit/query/react';

export const hermesProjectApi = createApi({
  baseQuery: axiosBaseQuery(),
  reducerPath: 'hermesProject',
  tagTypes: ['ProjectPlan'],
  endpoints: (build) => {
    return {
      projectPlan: build.query<ProjectPlan, number>({
        providesTags: (result, _, __) => {
          return result ? [{ type: 'ProjectPlan', id: result.id }] : [];
        },
        query: (id) => {
          return {
            url: `/hermes/projects/${id}/project-plan/`,
            method: 'GET',
          };
        },
      }),
      updateProjectPlan: build.mutation<
        ProjectPlan,
        {
          id: ProjectPlanId;
          data: Partial<ProjectPlan>;
        }
      >({
        query: ({ id, data }) => {
          return {
            url: `/hermes/project-plan/${id}/`,
            method: 'PATCH',
            data,
          };
        },
        invalidatesTags: (_, __, { id }) => [{ type: 'ProjectPlan', id }],
      }),
      submitProjectPauseRequest: build.mutation<
        ProjectPauseRequest,
        Pick<ProjectPauseRequest, 'pauseUntil' | 'project' | 'reasonForPause'>
      >({
        query: (data) => {
          return {
            url: '/hermes/project_pause_requests/',
            method: 'POST',
            data,
          };
        },
      }),
      modifyProjectPauseRequest: build.mutation<
        ProjectPauseRequest,
        {
          id: ProjectPauseRequestId;
          data: Pick<ProjectPauseRequest, 'status' | 'reasonForDecline'>;
        }
      >({
        query: ({ id, data }) => {
          return {
            url: `/hermes/project_pause_requests/${id}/`,
            method: 'PATCH',
            data,
          };
        },
      }),
      trackZoomJoin: build.mutation<
        void,
        {
          meetingId: MeetingId;
          sourceLocation:
            | 'chat'
            | 'session_list'
            | 'native_app_sessions_screen'
            | 'native_app_session_item';
        }
      >({
        query: ({ meetingId, sourceLocation }) => {
          return {
            url: `/tracking/zoom-join-activity/`,
            method: 'POST',
            data: { meetingId, sourceLocation },
          };
        },
      }),
    };
  },
});

export const {
  useSubmitProjectPauseRequestMutation,
  useModifyProjectPauseRequestMutation,
  useProjectPlanQuery,
  useUpdateProjectPlanMutation,
  useTrackZoomJoinMutation,
} = hermesProjectApi;
