import type { StudentJourneyPSSProgram } from '@polygence/common';
import { Skeleton } from '@polygence/components';
import type { ElementType } from 'react';

import { CollapsibleJourneyTitle } from 'src/components/Tiles/StudentJourney/CollapsibleJourneyTitle';
import { ProjectActiveTile } from 'src/components/Tiles/StudentJourney/ProjectActiveTile';
import { StudentJourneyProduct } from 'src/components/Tiles/StudentJourney/StudentJourneyProduct';
import { UnderMatchingTile } from 'src/components/Tiles/StudentJourney/UnderMatchingTile';
import { PitchPSSJourneyTile } from 'src/components/Tiles/StudentJourney/pss/PitchPSSJourneyTile';
import { PurchasablePSSJourneyTile } from 'src/components/Tiles/StudentJourney/pss/PurchasablePSSJourneyTile';
import { SurveyPSSJourneyTile } from 'src/components/Tiles/StudentJourney/pss/SurveyPSSJourneyTile';
import { WaitUntilPSSJourneyTile } from 'src/components/Tiles/StudentJourney/pss/WaitUntilPSSJourneyTile';
import { useStudentJourney } from 'src/components/Tiles/StudentJourney/useStudentJourney';

const SkeletonPSSJourneyTile = () => {
  return <Skeleton className="w-100" height="75px" />;
};

const PSSActiveTile = () => <ProjectActiveTile projectType="pss" />;

const components: { [key in StudentJourneyPSSProgram['status']]: ElementType } = {
  pitchable: PitchPSSJourneyTile,
  purchasable: PurchasablePSSJourneyTile,
  purchased: WaitUntilPSSJourneyTile,
  under_matching: UnderMatchingTile,
  survey_to_be_filled: SurveyPSSJourneyTile,
  active: PSSActiveTile,
  completed: PSSActiveTile,
  terminated: PSSActiveTile,
};

const PSSJourney = () => {
  const { data } = useStudentJourney();

  if (!data || !data.pss) {
    return null;
  }

  const status = data.pss.status;

  const Component = components[status];
  return <Component />;
};

export const PSSJourneyTile = () => {
  const { data } = useStudentJourney();

  const status = data?.pss?.status;
  const project = data?.pss?.project;
  const dashed = status ? ['pitchable', 'purchasable'].includes(status) : false;
  const isProjectActive = project ? project.sessionsCompleted !== project.allSessions : false;
  const isDefaultOpen = status
    ? ['pitchable', 'purchasable', 'survey_to_be_filled'].includes(status)
    : isProjectActive;

  return (
    <StudentJourneyProduct
      loader={<SkeletonPSSJourneyTile />}
      productType="pss"
      title={
        <CollapsibleJourneyTitle
          title="Premium Showcasing"
          sessionData={
            project
              ? {
                  completedSessions: project.sessionsCompleted,
                  totalSessions: project.allSessions,
                }
              : null
          }
          dashed={dashed}
        />
      }
      dashed={dashed}
      defaultOpen={isDefaultOpen}
    >
      <PSSJourney />
    </StudentJourneyProduct>
  );
};
