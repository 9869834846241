import { Heading, OptionType, Select, Spacer, Text } from '@polygence/components';
import { useState } from 'react';
import { useParams } from 'react-router';
import type { MultiValue } from 'react-select';
import { toast } from 'react-toastify';
import { StringParam, useQueryParams } from 'use-query-params';

import { Autosave } from 'src/components/CollegeAdmissionSurvey/Autosave';
import { FEATURED_US_AS_OPTIONS } from 'src/components/CollegeAdmissionSurvey/constants';
import {
  getCalculatedFeaturedUsAsOptions,
  getCurrentFeaturedUsAsOption,
  getSurveyCollegesQuestion,
  getSurveyDescription,
} from 'src/components/CollegeAdmissionSurvey/utils';
import NoMatchPage from 'src/components/NoMatchPage';
import { PageLoad } from 'src/components/PageLoad';
import { TopCollegesSelector } from 'src/components/usersettings/TopCollegesSelector';
import {
  useGetPublicCollegeAdmissionSurveyQuery,
  useUpdatePublicCollegeAdmissionSurveyMutation,
} from 'src/reducers/marketplaceReducer';

export const PublicCollegeAdmissionSurvey = () => {
  const { uuid } = useParams<{ uuid: string }>();
  const [query] = useQueryParams({ stage: StringParam });
  const stage = query.stage ?? '';
  const [saving, setSaving] = useState(false);

  const { currentData: student, isLoading } = useGetPublicCollegeAdmissionSurveyQuery({
    uuid,
  });
  const [updateStudent] = useUpdatePublicCollegeAdmissionSurveyMutation();

  const handleFeaturedUsAsSelectChange = (selectedOptions: MultiValue<OptionType>) => {
    const featuredUsAs = getCalculatedFeaturedUsAsOptions(selectedOptions as OptionType[]).map(
      (option) => option.value,
    );

    updateStudent({
      uuid,
      payload: { featured_us_as: featuredUsAs },
    }).catch(() => toast.error("Couldn't save how you featured us as."));
  };

  if (isLoading) {
    return <PageLoad />;
  }

  if (!student) {
    return <NoMatchPage />;
  }

  return (
    <div>
      <div className="d-flex column flex-column justify-content-center align-items-center mb-8 container">
        <Heading as="h2" className="my-5">
          Polygence College Admissions Survey
        </Heading>
        <Text size="medium" alignment="center" className="mb-3 w-75">
          Thank you for participating in our college survey!
        </Text>
        <Text size="medium" alignment="center" className="mb-3 w-75">
          {getSurveyDescription(stage, student.firstName)}
        </Text>
        <div className="w-75">
          <Spacer size={6} />
          <Text size="medium" fontWeight="bold" className="mb-5">
            {`How did ${student.firstName} feature Polygence on their college applications?`}
          </Text>
          <Select
            name="featuredUsAs"
            isMulti
            options={FEATURED_US_AS_OPTIONS}
            value={getCurrentFeaturedUsAsOption(student.featuredUsAs)}
            onChange={handleFeaturedUsAsSelectChange}
          />
          <Spacer size={8} />

          <TopCollegesSelector
            profileUuid={uuid}
            stage={stage}
            title={getSurveyCollegesQuestion(stage, student.firstName)}
            setSaving={setSaving}
          />

          <Text size="medium" alignment="center" className="my-8">
            {`Thank you so much for your response. We're so excited to see the next steps of ${student.firstName}’s academic journey!`}
          </Text>
          <Spacer size={13} />
        </div>
      </div>
      <Autosave saving={saving} />
    </div>
  );
};
