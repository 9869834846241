import { cn, Icon } from '@polygence/components';
import { useState } from 'react';
import { Link, useRouteMatch } from 'react-router-dom';

import getPathName from 'src/components/admin/components/aux/getPathName';
import type { RouteType } from 'src/components/partnerDashboard/PartnerDashboard';

interface SidebarItemProps {
  route: RouteType;
  collapsed: boolean;
  isActive: boolean;
}

const SidebarItem = ({ route, collapsed, isActive }: SidebarItemProps) => {
  const match = useRouteMatch();

  return (
    <li key={route.route}>
      <Link
        to={`${match.url}/${route.route}`}
        className={cn(
          'tw-flex tw-items-center tw-gap-2 tw-rounded-lg tw-px-3 tw-py-2 tw-text-gray-700 tw-transition-colors hover:tw-bg-gray-100 hover:tw-text-gray-900',
          isActive && 'tw-bg-gray-100 tw-font-medium tw-text-gray-900 tw-shadow-sm',
        )}
      >
        <Icon
          id={route.icon}
          size="md"
          className={cn('tw-transition-colors', isActive && 'tw-text-primary-600')}
        />
        {!collapsed && (
          <span className={cn('tw-transition-colors', isActive && 'tw-text-primary-600')}>
            {route.tabName}
          </span>
        )}
      </Link>
    </li>
  );
};

interface PartnerNavProps {
  routes: RouteType[];
  current: string;
  companyName?: string;
}

export const PartnerNav = ({ routes, current, companyName = 'My Company' }: PartnerNavProps) => {
  const [collapsed, setCollapsed] = useState(false);

  return (
    <div
      className={cn(
        'tw-left-0 tw-top-0 tw-z-50 tw-h-full tw-overflow-y-auto tw-border-r tw-border-gray-200 tw-bg-white tw-pt-6 tw-transition-all tw-duration-300 tw-ease-in-out',
        collapsed ? 'tw-w-16' : 'tw-w-64',
      )}
    >
      <div className="tw-flex tw-h-full tw-flex-col">
        <div className="tw-flex tw-items-center tw-justify-between tw-border-b tw-border-gray-200 tw-p-4">
          {!collapsed && (
            <span className="tw-text-lg tw-font-semibold tw-text-gray-800">{companyName}</span>
          )}
          <button
            type="button"
            onClick={() => setCollapsed(!collapsed)}
            className="tw-appearance-none tw-rounded-lg tw-border-none tw-bg-gray-100 tw-p-1.5 tw-transition-colors hover:tw-bg-gray-50"
          >
            {collapsed ? <Icon id="arrow-right" size="md" /> : <Icon id="x" size="md" />}
          </button>
        </div>

        <nav className="tw-flex-1 tw-overflow-y-auto">
          <ul className="tw-flex tw-flex-col tw-gap-2 tw-p-2">
            {routes.map((item) => (
              <SidebarItem
                key={item.route}
                collapsed={collapsed}
                isActive={current.endsWith(`/${getPathName(item.route)}`)}
                route={item}
              />
            ))}
          </ul>
        </nav>
      </div>
    </div>
  );
};
