import { Badge, Pill, Text } from '@polygence/components';
import { Input, array, boolean, object, nullish, parse, string } from 'valibot';

import { StudentSnapshotEmptyContent } from 'src/components/common/StudentSnapshot/StudentSnapshotContent';

const TopicsSchema = object({
  topics: array(
    object({
      name: string(),
      keywords: array(string()),
      current: nullish(boolean()),
      description: nullish(string()),
    }),
  ),
});

type TopicsProps = Input<typeof TopicsSchema>;

export const StudentSnapshotTopics = (props: TopicsProps) => {
  const { topics } = parse(TopicsSchema, props);

  if (!topics || !topics.length) {
    return <StudentSnapshotEmptyContent />;
  }

  return (
    <ol className="d-flex flex-column gap-6 ps-4">
      {topics.map(({ name, keywords, description, current }) => {
        return (
          <li key={name}>
            <div className="d-flex flex-column gap-3 ps-3">
              <Pill variant="primary" selected={current ?? false} className="align-self-start">
                {name}
              </Pill>
              {keywords.length > 0 && (
                <div className="d-flex gap-3">
                  {keywords.map((keyword) => {
                    return (
                      <Badge key={keyword} variant="primary" size="small">
                        <strong>{keyword.toUpperCase()}</strong>
                      </Badge>
                    );
                  })}
                </div>
              )}
              {description && (
                <Text size="small" fontWeight="light" alignment="left">
                  {description}
                </Text>
              )}
            </div>
          </li>
        );
      })}
    </ol>
  );
};
