import { ProjectPlan } from '@polygence/common';
import { useUpdateProjectPlanMutation } from '@polygence/common/api/hermes-project';
import { Button, Heading, Icon, InputField } from '@polygence/components';
import { isEqual } from 'lodash';
import {
  ChangeEvent,
  createContext,
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import { toast } from 'react-toastify';

import { ReactMarkdown } from 'src/components/aux/markdown/ReactMarkdown';
import { ProjectPlanContext } from 'src/components/hermes/project-plan/ProjectPlanContext';
import { useCanEditProjectPlan } from 'src/components/hermes/project-plan/useCanEditProjectPlan';

export const EditableMarkdown = ({
  content,
  onChange: onChangeProp,
}: {
  content: string | undefined;
  onChange: (value: string) => void;
}) => {
  const { isEditing } = useContext(ProjectPlanSectionContext);
  const markdownRef = useRef<HTMLDivElement>(null);
  const contentHeight = useRef(0);

  const minHeight = contentHeight.current + 20;

  const onChange = (event: ChangeEvent<HTMLInputElement>) => {
    if (onChangeProp) {
      onChangeProp(event.target.value);
    }
  };

  useEffect(() => {
    if (markdownRef.current) {
      contentHeight.current = markdownRef.current.offsetHeight;
    }
  });

  return isEditing ? (
    // eslint-disable-next-line react/forbid-dom-props
    <div style={minHeight ? { height: minHeight } : undefined}>
      <InputField
        value={content}
        name="content"
        type="textarea"
        onChange={onChange}
        className="[&_.input-group]:tw-h-full [&_textarea]:tw-min-h-full [&_textarea]:tw-resize-none"
      />
    </div>
  ) : (
    <div ref={markdownRef}>
      <ReactMarkdown>{content}</ReactMarkdown>
    </div>
  );
};

const ProjectPlanSectionContext = createContext<{
  isEditing: boolean;
}>({
  isEditing: false,
});

type Props<T extends keyof ProjectPlan['contentJson']> =
  | {
      title: string;
      nameKey: 'roadmap';
      content?: ProjectPlan['contentJson']['roadmap'];
      children: React.ReactNode;
    }
  | { title: string; nameKey: T; content: ProjectPlan['contentJson'][T]; children?: undefined };

export const ProjectPlanSection = <T extends keyof ProjectPlan['contentJson']>({
  title,
  nameKey,
  content,
  children,
}: Props<T>) => {
  const [isEditing, setIsEditing] = useState(false);

  const { projectPlan, setProjectPlanDraft } = useContext(ProjectPlanContext);
  const canEdit = useCanEditProjectPlan(projectPlan);
  const [updateProjectPlan, { isLoading: isUpdating }] = useUpdateProjectPlanMutation();

  const contextValue = useMemo(
    () => ({ isEditing: canEdit ? isEditing : false }),
    [isEditing, canEdit],
  );

  const contentString = typeof content === 'string' ? String(content) : undefined;

  const handleChange = (newState: ProjectPlan['contentJson'][Exclude<T, 'roadmap'>]) => {
    if (nameKey === 'roadmap') {
      return;
    }

    setProjectPlanDraft((draft) => {
      // eslint-disable-next-line fp/no-mutation
      draft[nameKey] = newState;
    });
  };

  const handleSave = () => {
    if (!projectPlan || isUpdating) {
      return;
    }

    if (!content) {
      toast.error('Can not save empty field.');
      return;
    }

    if (!isEqual(content, projectPlan.contentJson)) {
      updateProjectPlan({
        id: projectPlan.id,
        data: {
          contentJson: { ...projectPlan.contentJson, [nameKey]: content },
        },
      })
        .unwrap()
        .then(() => setIsEditing(false))
        .catch(() => {
          toast.error('Something went wrong.');
        });
    } else {
      setIsEditing(false);
    }
  };

  const toggleEdit = () => {
    setIsEditing((editing) => !editing);
  };

  if (!projectPlan?.contentJson[nameKey]) {
    return null;
  }

  return (
    <ProjectPlanSectionContext.Provider value={contextValue}>
      <div className="tw-flex tw-w-full tw-flex-col tw-gap-2">
        <div className="tw-flex tw-items-center tw-justify-between tw-border-0 tw-border-b tw-border-solid tw-border-gray-300 tw-pb-1">
          <Heading
            size="h5"
            as="h2"
            alignment="left"
            className="tw-text-sm tw-uppercase tw-text-gray-600"
          >
            {title}
          </Heading>
          {canEdit &&
            (isEditing ? (
              <Button
                variant="primary"
                startIcon={<Icon id="save" />}
                size="sm"
                disabled={isUpdating}
                onClick={handleSave}
              >
                Save
              </Button>
            ) : (
              <Button
                variant="secondary"
                startIcon={<Icon id="edit-3" />}
                size="sm"
                onClick={toggleEdit}
              >
                Edit
              </Button>
            ))}
        </div>
        {children ? children : <EditableMarkdown content={contentString} onChange={handleChange} />}
      </div>
    </ProjectPlanSectionContext.Provider>
  );
};
