import NiceModal from '@ebay/nice-modal-react';

import { StudentSnapshotModal } from 'src/components/common/StudentSnapshot/StudentSnapshotModal';

interface StudentSnapshotActionParameters {
  studentId: number;
  userId: number;
  workspaceId?: number;
}

export const StudentSnapshotAction = async (parameters: StudentSnapshotActionParameters) => {
  await NiceModal.show(StudentSnapshotModal, parameters);
};
