import PropTypes from 'prop-types';
import React, { useState } from 'react';

import { DefaultProfilePicture } from 'src/components/common/ProfilePicture';
import { getMonogramOf } from 'src/components/hermes/utils';

import 'src/components/common/ProfilePicture/ProfilePicture.sass';

const ProfilePicture = ({ user, size = 140 }) => {
  const [error, setError] = useState(false);
  const { profilePicture, firstName } = user;

  return (
    // eslint-disable-next-line react/jsx-filename-extension -- autodisabled
    <>
      {!error && profilePicture && firstName && (
        <img
          src={profilePicture}
          alt={firstName}
          className="profile-picture"
          // eslint-disable-next-line react/forbid-dom-props -- autodisabled
          style={{ width: size, height: size }}
          onError={() => {
            setError(true);
          }}
        />
      )}
      {(!profilePicture || error) && (
        <DefaultProfilePicture monogram={getMonogramOf(user)} size={size} />
      )}
    </>
  );
};

ProfilePicture.propTypes = {
  user: PropTypes.oneOfType([
    PropTypes.shape({
      profilePicture: PropTypes.string.isRequired,
      firstName: PropTypes.string.isRequired,
      lastName: PropTypes.string.isRequired,
    }),
    PropTypes.any,
  ]),
  size: PropTypes.number,
};

// eslint-disable-next-line import/no-default-export -- autodisabled
export default ProfilePicture;
