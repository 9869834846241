import type { StudentJourneyCoreProgram } from '@polygence/common';
import { Skeleton } from '@polygence/components';
import type { ElementType } from 'react';

import { CollapsibleJourneyTitle } from 'src/components/Tiles/StudentJourney/CollapsibleJourneyTitle';
import { ProjectActiveTile } from 'src/components/Tiles/StudentJourney/ProjectActiveTile';
import { StudentJourneyProduct } from 'src/components/Tiles/StudentJourney/StudentJourneyProduct';
import { UnderMatchingTile } from 'src/components/Tiles/StudentJourney/UnderMatchingTile';
import { PurchasedCoreJourney } from 'src/components/Tiles/StudentJourney/core/PurchasedCoreJourney';
import { useStudentJourney } from 'src/components/Tiles/StudentJourney/useStudentJourney';

const SkeletonCoreJourneyTile = () => {
  return <Skeleton className="w-100 py-4" height="80px" />;
};

const CoreActiveTile = () => <ProjectActiveTile projectType="core" />;

const components: { [key in StudentJourneyCoreProgram['status']]: ElementType } = {
  under_matching: UnderMatchingTile,
  active: CoreActiveTile,
  completed: CoreActiveTile,
  purchased: PurchasedCoreJourney,
  terminated: CoreActiveTile,
};

const CoreJourney = () => {
  const { data } = useStudentJourney();

  if (!data || !data.core) {
    return null;
  }

  const Component = components[data.core.status];
  return <Component />;
};

export const CoreJourneyTile = () => {
  const { data } = useStudentJourney();

  return (
    <StudentJourneyProduct
      loader={<SkeletonCoreJourneyTile />}
      productType="core"
      title={
        <CollapsibleJourneyTitle
          title="Core Program"
          sessionData={
            data?.core?.project
              ? {
                  completedSessions: data.core.project.sessionsCompleted,
                  totalSessions: data.core.project.allSessions,
                }
              : null
          }
        />
      }
      defaultOpen={
        data?.core?.project
          ? data.core.project.sessionsCompleted !== data.core.project.allSessions
          : false
      }
    >
      <CoreJourney />
    </StudentJourneyProduct>
  );
};
