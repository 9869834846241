import { ProjectType, Summary, UserType, commonHooks } from '@polygence/common';
import { Text } from '@polygence/components';

import { StudentSnapshotMarkdown } from 'src/components/common/StudentSnapshot/ContentRenderers/Markdown';
import { SummaryPublicQuestions } from 'src/components/hermes/sessions/submitted-session-summary/SummaryPublicQuestions';

export const StudentSnapshotReflectionCallSummary = (props: {
  content: string;
  summaries: Pick<Summary, 'extras'>;
}) => {
  const { content, summaries } = props;
  const currentUser = commonHooks.useCurrentUser();

  return (
    <>
      <StudentSnapshotMarkdown content={content} />
      {summaries && (
        <SummaryPublicQuestions
          summary={summaries}
          projectType={ProjectType.PATHFINDER_DIAGNOSTIC}
        />
      )}
      {summaries?.extras['goodToKnowForMentors'] && currentUser.userType === UserType.MENTOR && (
        <>
          <Text className="tw-mb-1" fontWeight="bold" size="small">
            What should future mentors consider when helping this student? Any particular support
            you think this student might need in the future?
          </Text>
          <Text size="small" className="text-break">
            {String(summaries.extras['goodToKnowForMentors'])}
          </Text>
        </>
      )}
    </>
  );
};
