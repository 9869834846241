import { commonHooks } from '@polygence/common';
import * as roleUtils from '@polygence/common/utils/roles';
import { IconVariable } from '@polygence/components';
import { Redirect, Switch, useRouteMatch, useLocation } from 'react-router-dom';
import 'react-tabs/style/react-tabs.css';

import { Loader } from 'src/components/Loader';
import { PrivateRoute } from 'src/components/authenticatedRoutes';
import 'src/components/admin/marco.css';
import { PartnerNav } from 'src/components/partnerDashboard/PartnerNav';
import { RevenueSharePaymentRequests } from 'src/components/partnerDashboard/RevenueSharePaymentRequests';
import { StudentEvents } from 'src/components/partnerDashboard/StudentEvents';
import { WelcomePage } from 'src/components/partnerDashboard/WelcomePage';
import { usePartnerCompanyDashboardQuery } from 'src/reducers/partnerDashboardReducers';

export interface RouteType {
  component: () => JSX.Element | null;
  route: string;
  tabName: string;
  icon: IconVariable;
}

export const PartnerDashboard = () => {
  const match = useRouteMatch<{ id: string }>();
  const location = useLocation();
  const currentUser = commonHooks.useCurrentUser();
  const { data, isLoading, isError } = usePartnerCompanyDashboardQuery(Number(match.params.id));

  const routes: RouteType[] = [
    {
      component: RevenueSharePaymentRequests,
      tabName: 'Revenue Share',
      route: 'revenue-share',
      icon: 'dollar-sign',
    },
  ];

  if (isLoading) {
    return <Loader className="mx-auto my-5" />;
  }

  if (isError || (!roleUtils.hasAdminDashboardAccess(currentUser) && !isLoading && !data?.tag)) {
    return <Redirect to={{ pathname: '/', state: { from: location } }} />;
  }

  if (roleUtils.hasAdminDashboardAccess(currentUser)) {
    routes.unshift({
      component: WelcomePage,
      tabName: 'Welcome Page',
      route: 'welcome-page',
      icon: 'home',
    });
    routes.push({
      component: StudentEvents,
      tabName: 'Student Updates',
      route: 'student-updates',
      icon: 'list',
    });
  }

  return (
    <>
      <div className="fullPage tw-flex">
        <PartnerNav routes={routes} current={location.pathname} companyName={data?.companyName} />
        <div className="content tw-flex-1 tw-overflow-y-auto tw-bg-gray-100 tw-p-6">
          <Switch>
            {routes.map(({ component: RouteComponent, route }) => {
              return (
                <PrivateRoute
                  key={route}
                  path={`${match.path}/${route}`}
                  component={RouteComponent}
                />
              );
            })}
          </Switch>
        </div>
      </div>
    </>
  );
};
