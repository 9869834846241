import { Heading, Icon, Text, Tooltip } from '@polygence/components';
import { Input, array, coerce, date, number, object, parse, string } from 'valibot';

import DateWrapper, { DateFormat } from 'src/components/aux/dateWrapper';

const FeedbackSchema = object({
  feedbacks: array(
    object({
      sessionTitle: string(),
      sessionDate: coerce(date(), (i) => new Date(i as string)),
      rating: coerce(number(), Number),
      researchJournal: string(),
    }),
  ),
});

type FeedbackProps = Input<typeof FeedbackSchema>;

export const StudentSnapshotFeedback = (props: FeedbackProps) => {
  const { feedbacks } = parse(FeedbackSchema, props);

  if (!feedbacks.length) {
    return (
      <section className="d-flex flex-1 align-items-center justify-content-center">
        <Text size="medium" fontWeight="light" alignment="center">
          There are no student feedbacks submitted yet.
        </Text>
      </section>
    );
  }

  return (
    <section className="d-flex flex-column gap-4">
      {feedbacks.map(({ sessionTitle, sessionDate, rating, researchJournal }, index) => {
        return (
          <article key={sessionTitle}>
            <Heading as="h2" size="h5" alignment="left" className="mb-2">
              {sessionTitle}
            </Heading>
            <div className="d-flex gap-3 align-items-center mb-3">
              <div className="d-flex gap-2">
                <Icon id="clock" size="sm" className="text-primary" />
                <Text size="small" fontWeight="light">
                  {new DateWrapper(sessionDate).format(DateFormat.FORMAT_2)}
                </Text>
              </div>
              <Tooltip tip="How useful did you find the session?">
                <div className="d-flex gap-2">
                  <Icon id="star" size="sm" className="text-primary" />
                  <Text size="small" fontWeight="light">
                    {rating} / 5
                  </Text>
                </div>
              </Tooltip>
            </div>
            <Text size="small">{researchJournal}</Text>
            {index < feedbacks.length - 1 && <hr className="mt-4" />}
          </article>
        );
      })}
    </section>
  );
};
