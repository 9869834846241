import { FetchBaseQueryError } from '@reduxjs/toolkit/query';
import type { BaseQueryFn } from '@reduxjs/toolkit/query/react';
import type { AxiosRequestTransformer, AxiosResponseTransformer } from 'axios';
import type { AxiosRequestConfig } from 'axios';
import axios from 'axios';
import camelcase from 'camelcase-keys';
import snakecase from 'snakecase-keys';

const APPLICATION_JSON = 'application/json';

const convertKeysToSnakeCase: AxiosRequestTransformer = (data, headers) => {
  const contentType = headers.getContentType() || '';
  const hasJSONContentType = contentType.indexOf(APPLICATION_JSON) > -1;
  const isObject = typeof data === 'object' && data != null;
  if (isObject && hasJSONContentType) {
    // eslint-disable-next-line @typescript-eslint/no-unsafe-return
    return snakecase(data, { deep: true });
  }
  // eslint-disable-next-line @typescript-eslint/no-unsafe-return
  return data;
};

const convertKeysToCamelCase: AxiosResponseTransformer = (data, headers) => {
  const contentType = headers.getContentType() || '';
  const hasJSONContentType = contentType.indexOf(APPLICATION_JSON) > -1;
  const isObject = typeof data === 'object' && data != null;
  if (isObject && hasJSONContentType) {
    // eslint-disable-next-line @typescript-eslint/no-unsafe-return
    return camelcase(data, { deep: true });
  }
  // eslint-disable-next-line @typescript-eslint/no-unsafe-return
  return data;
};

export const commonAxios = axios.create({
  headers: {
    'Content-Type': APPLICATION_JSON,
  },
  transformRequest: [
    convertKeysToSnakeCase,
    ...(Array.isArray(axios.defaults.transformRequest) ? axios.defaults.transformRequest : []),
  ],
  transformResponse: [
    ...(Array.isArray(axios.defaults.transformResponse) ? axios.defaults.transformResponse : []),
    convertKeysToCamelCase,
  ],
});

export const defaults = {
  transformRequest: axios.defaults.transformRequest,
  transformResponse: axios.defaults.transformResponse,
} as const;

export const axiosBaseQuery =
  (): BaseQueryFn<
    | {
        url: string;
        method: AxiosRequestConfig['method'];
        data?: AxiosRequestConfig['data'];
        params?: AxiosRequestConfig['params'];
      }
    | string,
    unknown,
    unknown
  > =>
  async (args) => {
    const requestConfig = typeof args === 'string' ? { url: args } : args;
    try {
      const result = await commonAxios(requestConfig);
      return { data: result.data };
    } catch (err) {
      if (axios.isAxiosError(err)) {
        return {
          error: {
            status: err.response?.status,
            // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
            data: err.response?.data || err.message,
          },
        };
      }
      throw err;
    }
  };

export function isFetchBaseQueryError(error: unknown): error is FetchBaseQueryError {
  return typeof error === 'object' && error != null && 'status' in error;
}
