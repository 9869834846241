import { ProjectType } from '@polygence/common';

import {
  projectIssueQuestions,
  techIssueQuestions,
} from 'src/components/hermes/surveys/mentor/generalQuestions';
import { reflectionSessionCountAsQuestion } from 'src/components/hermes/surveys/mentor/metaQuestions';
import {
  makeQuestionsOnlyAvailableForPolygence,
  makeQuestionsSentToStudentSection,
} from 'src/components/hermes/surveys/mentor/utils';

export const publicQuestions = [
  {
    component: 'LabelWithTooltip',
    props: {
      children: 'Recap the session in 2 sentences:',
      labelFor: 'sessionRecap',
    },
  },
  {
    component: 'FormInput',
    props: {
      name: 'sessionRecap',
      inputType: 'textarea',
      htmlRequired: true,
    },
  },
  {
    component: 'LabelWithTooltip',
    props: {
      children: 'Some of the things that I heard from you in this session were:',
      labelFor: 'reflectionSessionStudentHighlight1',
    },
  },
  {
    component: 'FormInput',
    props: {
      name: 'reflectionSessionStudentHighlight1',
      inputType: 'text',
      htmlRequired: true,
      placeholder: 'You are...',
    },
  },
  {
    component: 'FormInput',
    props: {
      name: 'reflectionSessionStudentHighlight2',
      inputType: 'text',
      placeholder: 'You are...',
    },
  },
  {
    component: 'LabelWithTooltip',
    props: {
      children: 'We hope that your Polygence experience was great, and we definitely recommend...',
      labelFor: 'reflectionMentorRecommendation',
    },
  },
  {
    component: 'FormInput',
    props: {
      name: 'reflectionMentorRecommendation',
      inputType: 'textarea',
      rows: 5,
      placeholder: 'that you consider...',
      htmlRequired: true,
    },
  },
  {
    component: 'LabelWithTooltip',
    props: {
      children:
        'List the project idea(s) that you and your student landed on doing for the Core project. Ideally this looks like a topic sentence and a description of the idea:',
      labelFor: 'projectIdeas',
    },
    display: {
      $is: {
        field: '__pathfinderType',
        value: ProjectType.PATHFINDER_LAUNCHPAD,
      },
    },
  },
  {
    component: 'FormInput',
    props: {
      name: 'projectIdeas',
      inputType: 'textarea',
      rows: 5,
      htmlRequired: true,
    },
    display: {
      $is: {
        field: '__pathfinderType',
        value: ProjectType.PATHFINDER_LAUNCHPAD,
      },
    },
  },
  {
    component: 'LabelWithTooltip',
    props: {
      children:
        'Any additional notes to add specific to the student (please use complete sentences)',
      labelFor: 'additionalNotes',
    },
  },
  {
    component: 'FormInput',
    props: {
      name: 'additionalNotes',
      inputType: 'textarea',
      rows: 3,
      htmlRequired: true,
    },
  },
  {
    component: 'LabelWithTooltip',
    props: {
      children: 'Send a quick note of encouragement to complete your email to student/parent:',
      labelFor: 'studentEncouragement',
    },
  },
  {
    component: 'FormInput',
    props: {
      name: 'studentEncouragement',
      inputType: 'textarea',
      htmlRequired: true,
    },
  },
] as const;

export const privateQuestions = [
  {
    component: 'LabelWithTooltip',
    props: {
      children:
        'We always want to improve our products so it would be very helpful if you could ask the student some of these sample questions:',
      labelFor: 'studentProductImprovementQuestions',
    },
  },
  {
    component: 'ComponentsText',
    props: {
      children:
        'After your program experience, what are the top 3 aspects of the program that stuck out to you as being most valuable to you?',
      size: 'small',
      fontWeight: 'light',
      className: 'mb-4',
    },
  },
  {
    component: 'ComponentsText',
    props: {
      children: 'What would you change about the program experience?',
      size: 'small',
      fontWeight: 'light',
      className: 'mb-4',
    },
  },
  {
    component: 'ComponentsText',
    props: {
      children:
        '(Launchpad only) As you now enter your Core experience, is there anything in the Launchpad experience that could have prepared you better for this next step?',
      size: 'small',
      fontWeight: 'light',
      className: 'mb-4',
    },
    display: {
      $is: {
        field: '__pathfinderType',
        value: ProjectType.PATHFINDER_LAUNCHPAD,
      },
    },
  },
  {
    component: 'ComponentsText',
    props: {
      children:
        'How would you describe this program to a friend? (we are trying to understand google search keywords here so please write down their exact phrases they use to describe the program)',
      size: 'small',
      fontWeight: 'light',
      className: 'mb-4',
    },
  },
  {
    component: 'FormInput',
    props: {
      name: 'studentProductImprovementQuestions',
      inputType: 'textarea',
      placeholder: 'Your messy notes here',
      rows: 7,
      htmlRequired: true,
    },
  },
  {
    component: 'LabelWithTooltip',
    props: {
      children:
        'Would you like to share any additional thoughts on how we can improve the program? (your opinion, less about the student’s words)',
      labelFor: 'mentorProductImprovementThoughts',
    },
  },
  {
    component: 'FormInput',
    props: {
      name: 'mentorProductImprovementThoughts',
      inputType: 'textarea',
      rows: 4,
      htmlRequired: true,
    },
  },
  {
    component: 'LabelWithTooltip',
    props: {
      children:
        'What should future mentors consider when helping this student? Any particular support you think this student might need in the future?',
      labelFor: 'goodToKnowForMentors',
    },
  },
  {
    component: 'FormInput',
    props: {
      name: 'goodToKnowForMentors',
      inputType: 'textarea',
      rows: 3,
      htmlRequired: true,
    },
  },
];

export const reflectionQuestions = [
  reflectionSessionCountAsQuestion,
  makeQuestionsSentToStudentSection(publicQuestions),
  makeQuestionsOnlyAvailableForPolygence(privateQuestions),
  ...techIssueQuestions,
  ...projectIssueQuestions,
];
