import { Spacer } from '@polygence/components';
import type { Dispatch, SetStateAction } from 'react';
import React, { useState } from 'react';

import { RtkPaginator } from 'src/components/admin/components/aux/RtkPaginator';
import { SearchComponent } from 'src/components/admin/components/aux/SearchComponent';

export interface FilterComponentProps {
  setFilters: Dispatch<SetStateAction<Record<string, unknown>>>;
  filters: Record<string, unknown>;
}

export const FilterableRtkPaginator = ({
  id,
  title,
  searchable = true,
  FilterComponent,
  defaultFilters = {},
  children,
}: {
  id?: string;
  title?: string;
  searchable?: boolean;
  FilterComponent?: React.FC<FilterComponentProps>;
  defaultFilters?: Record<string, unknown>;
  children: React.ReactElement<React.ComponentProps<typeof RtkPaginator>>;
}) => {
  const [filters, setFilters] = useState<Record<string, unknown>>(defaultFilters);

  if (children.type !== RtkPaginator) {
    console.error('FilterableRtkPaginator child must be RtkPaginator');
    return null;
  }

  return (
    <div className="align-items-center max-width-xxl" {...(id && { id })}>
      <div className="row align-items-center my-3">
        <div className="col-6">{title && <h4 className="m-0">{title}</h4>}</div>
        {searchable && (
          <div className="col-4 offset-2">
            <SearchComponent filters={filters} setFilters={setFilters} />
          </div>
        )}
        {FilterComponent && (
          <div className="col-12">
            {searchable && <Spacer size={3} vertical />}
            <FilterComponent setFilters={setFilters} filters={filters} />
          </div>
        )}
      </div>
      {React.cloneElement(children, {
        paginationArgs: { ...children.props.paginationArgs, ...filters },
      })}
    </div>
  );
};
