export const makeQuestionsSentToStudentSection = (fields: readonly unknown[]) => {
  return {
    component: 'Box',
    props: {
      children: [
        {
          component: 'ComponentsHeading',
          props: {
            size: 'h5',
            as: 'h2',
            alignment: 'center',
            children:
              'The information you provide in this section will be shared with your student and their parent.',
          },
        },
        {
          component: 'Spacer',
          props: {
            size: 6,
          },
        },
        ...fields,
      ],
      containerClassName: 'bg-grayscale-1 border border-3 polygence-rounded p-6',
    },
    display: {
      $not: { field: 'countAs', value: 'no_show' },
    },
  };
};

export const makeQuestionsOnlyAvailableForPolygence = (fields: readonly unknown[]) => {
  return {
    component: 'Box',
    props: {
      children: [
        {
          component: 'Spacer',
          props: {
            size: 9,
          },
        },
        {
          component: 'ComponentsHeading',
          props: {
            size: 'h5',
            as: 'h2',
            alignment: 'center',
            children:
              'The information you provide in this section will be shared only with Polygence (not students/parents).',
          },
        },
        {
          component: 'Spacer',
          props: {
            size: 6,
          },
        },
        ...fields,
      ],
    },
    display: {
      $not: { field: 'countAs', value: 'no_show' },
    },
  };
};
