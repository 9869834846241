import type { UserId, WorkspaceId } from '@polygence/common';
import * as Popover from '@radix-ui/react-popover';
import {
  KeyboardEventHandler,
  MouseEventHandler,
  ReactNode,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';

import { UserCard } from 'src/components/common/UserCard/UserCard';
import styles from 'src/components/common/UserCard/UserCard.module.scss';
import {
  UserCardContext,
  UserCardContextType,
} from 'src/components/common/UserCard/UserCardContext';
import { usePrefetch } from 'src/reducers/marketplaceReducer';

interface UserCardTriggerProps {
  children: ReactNode;
  userId?: UserId;
  workspaceId?: WorkspaceId;
  prefetchOnHover?: boolean;
  actions?: ReactNode;
}

const openDelay = 1100;
const closeDelay = 300;

export const UserCardTrigger = ({
  children,
  userId,
  workspaceId,
  prefetchOnHover = true,
  actions,
}: UserCardTriggerProps) => {
  const [open, setIsOpen] = useState(false);

  const prefetchUserCard = usePrefetch('userCards');

  const wasOpenedWithHoverRef = useRef(false);
  const triggerRef = useRef<HTMLDivElement>(null);
  const openTimerRef = useRef(0);
  const closeTimerRef = useRef(0);

  const closeCard = useCallback(() => {
    setIsOpen(false);
  }, [setIsOpen]);

  const contextValue = useMemo<UserCardContextType>(
    () => ({
      closeCard,
    }),
    [closeCard],
  );

  const handleOnMouseEnter = () => {
    if (userId && prefetchOnHover) {
      prefetchUserCard({ userId, workspaceId });
    }

    clearTimeout(closeTimerRef.current);
    openTimerRef.current = window.setTimeout(() => {
      if (triggerRef.current && !open) {
        wasOpenedWithHoverRef.current = true;
        triggerRef.current.click();
      }
    }, openDelay);
  };

  const handleOnMouseLeave = () => {
    clearTimeout(openTimerRef.current);
    if (document.getSelection()?.toString() === '') {
      closeTimerRef.current = window.setTimeout(() => {
        if (wasOpenedWithHoverRef.current) {
          setIsOpen(false);
        }
      }, closeDelay);
    }
  };

  const handleOnClick: MouseEventHandler = (e) => {
    clearTimeout(openTimerRef.current);
    clearTimeout(closeTimerRef.current);

    if (wasOpenedWithHoverRef.current && open) {
      wasOpenedWithHoverRef.current = false;
      e.preventDefault();
    }
  };

  const handleKeyDown: KeyboardEventHandler = (e) => {
    if (e.key === 'Enter' || e.key === 'Space' || e.key === ' ') {
      if (wasOpenedWithHoverRef.current && open) {
        wasOpenedWithHoverRef.current = false;
        return;
      }

      if (triggerRef.current) {
        triggerRef.current.click();
        e.preventDefault();
      }
    }
  };

  const handleAutoFocus = (e: Event) => {
    if (wasOpenedWithHoverRef.current) {
      e.preventDefault();
    }
  };

  useEffect(() => {
    return () => {
      clearTimeout(openTimerRef.current);
      clearTimeout(closeTimerRef.current);
    };
  }, []);

  if (!userId) {
    return <>{children}</>;
  }

  return (
    <Popover.Root open={open} onOpenChange={(open) => setIsOpen(open)}>
      <UserCardContext.Provider value={contextValue}>
        <Popover.Trigger
          asChild
          onPointerEnter={handleOnMouseEnter}
          onPointerLeave={handleOnMouseLeave}
          onClick={handleOnClick}
          onKeyDown={handleKeyDown}
        >
          <div role="button" tabIndex={0} className={styles['trigger']} ref={triggerRef}>
            {children}
          </div>
        </Popover.Trigger>
        <Popover.Portal>
          <Popover.Content
            sideOffset={5}
            align="start"
            alignOffset={-20}
            style={{ zIndex: 1070 /* $zindex-popover from Bootstrap, so it's above modals */ }}
            onPointerEnter={handleOnMouseEnter}
            onPointerLeave={handleOnMouseLeave}
            onOpenAutoFocus={handleAutoFocus}
            onCloseAutoFocus={handleAutoFocus}
          >
            <UserCard userId={userId} workspaceId={workspaceId} actions={actions} />
          </Popover.Content>
        </Popover.Portal>
      </UserCardContext.Provider>
    </Popover.Root>
  );
};
