import { ProjectType, Summary } from '@polygence/common';
import { Heading, Icon, Text } from '@polygence/components';
import { Fragment } from 'react';
import {
  Input,
  array,
  coerce,
  date,
  number,
  object,
  parse,
  enum_,
  record,
  string,
  optional,
  unknown,
} from 'valibot';

import DateWrapper, { DateFormat } from 'src/components/aux/dateWrapper';
import { UserCardTrigger } from 'src/components/common/UserCard/UserCardTrigger';
import { SummaryPublicQuestions } from 'src/components/hermes/sessions/submitted-session-summary/SummaryPublicQuestions';
import { getSummaryContent } from 'src/components/hermes/utils';

const SummarySchema = object({
  summaries: array(
    object({
      mentor: object({ name: string(), userId: coerce(number(), Number) }),
      projectType: enum_(ProjectType),
      sessionTitle: string(),
      sessionDate: coerce(date(), (i) => new Date(i as string)),
      summary: object({ content: optional(string()), extras: record(unknown()) }),
    }),
  ),
});

type SummaryProps = Input<typeof SummarySchema>;

const StudentSnapshotSummary = ({ session }: { session: SummaryProps['summaries'][number] }) => {
  const {
    sessionTitle,
    mentor: { name: mentorName, userId: mentorId },
    projectType,
    sessionDate,
    summary,
  } = session;

  const summaryContent = parse(string(), getSummaryContent(summary as Summary) ?? '');

  return (
    <article>
      <Heading as="h2" size="h5" alignment="left" className="mb-2">
        {sessionTitle}
      </Heading>
      <div className="d-flex gap-3 align-items-center mb-3">
        <div className="d-flex gap-2">
          <Icon id="clock" size="sm" className="text-primary" />
          <Text size="small" fontWeight="light">
            {new DateWrapper(sessionDate).format(DateFormat.FORMAT_2)}
          </Text>
        </div>
        <UserCardTrigger userId={mentorId}>
          <div className="d-flex gap-2">
            <Icon id="user" size="sm" className="text-primary" />
            <Text size="small" fontWeight="light" className="underline">
              {mentorName}
            </Text>
          </div>
        </UserCardTrigger>
      </div>
      <Text size="small" fontWeight="bold" className="mb-2">
        {summaryContent}
      </Text>
      <hr className="mb-2" />
      <SummaryPublicQuestions summary={summary} projectType={projectType} />
    </article>
  );
};

export const StudentSnapshotSummaries = (props: SummaryProps) => {
  const { summaries } = parse(SummarySchema, props);

  if (!summaries.length) {
    return (
      <section className="d-flex flex-1 align-items-center justify-content-center">
        <Text size="medium" fontWeight="light" alignment="center">
          There are no session summaries submitted yet.
        </Text>
      </section>
    );
  }

  return (
    <section className="d-flex flex-column gap-4">
      {summaries.map((session, index) => {
        return (
          <Fragment key={session.sessionTitle}>
            <StudentSnapshotSummary session={session} />
            {index < summaries.length - 1 && <hr className="mt-4" />}
          </Fragment>
        );
      })}
    </section>
  );
};
