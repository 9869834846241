import type { Meeting, Project, Session } from '../types/hermes';
import { MeetingStatus, ProjectStatus } from '../types/hermes';
import { dayjs } from '../utils/dayjsCustom';

export const isMeetingJoinableByTime = (meeting: Meeting | undefined) => {
  if (!meeting) {
    return false;
  }

  const isJoinableByLocalTime = dayjs().isBetween(
    dayjs(meeting.scheduledAt).subtract(15, 'minutes'),
    dayjs(meeting.scheduledAt).add(2, 'hours'),
  );

  return (
    meeting.status !== MeetingStatus.LATE_CANCELLED &&
    (isJoinableByLocalTime || meeting.isJoinableByTime)
  );
};

export function isSurveyRequiredForFinalZoomMeeting(project: Project) {
  if (!project) {
    return false;
  }
  return project.finalStudentSurveyRequiredForZoomJoin;
}

export function isFinalStudentSurveyCompletedByLastSession(
  project: Project,
  session: Session | undefined | null,
) {
  if (
    !project ||
    !project.finalStudentSurveyRequiredForZoomJoin ||
    !project.finalStudentSurveyEnabled ||
    !project.sessions ||
    !session ||
    project.sessionsSchedulable > 0
  ) {
    return true;
  }
  if (
    session.sessionNumber !==
    project.sessions.reduce((highestSession, current) => {
      if (!highestSession || current.sessionNumber > highestSession.sessionNumber) {
        return current;
      }
      return highestSession;
    }).sessionNumber
  ) {
    return true;
  }
  return project.finalStudentSurveyCompleted;
}

const FeedbackRemindersByCompletedSessionCount = {
  1: 'surveysRequiredFor1StSessionForZoomJoin',
  2: 'surveysRequiredFor2NdSessionForZoomJoin',
} as const;

export function completedSessionsBeforeCurrent(
  project: Project | undefined,
  currentSessionNumber: number,
) {
  return project?.sessions?.filter((session) => {
    return (
      session.countAs !== 'no_show' &&
      session.countAs !== 'late_cancelled' &&
      session.countAs !== 'not_session' &&
      session.sessionNumber < currentSessionNumber
    );
  }).length;
}

export function getFeedbackReminder(project: Project | undefined, currentSessionNumber: number) {
  const completedSessionsBeforeCurrentSession = completedSessionsBeforeCurrent(
    project,
    currentSessionNumber,
  );
  return completedSessionsBeforeCurrentSession
    ? FeedbackRemindersByCompletedSessionCount[
        completedSessionsBeforeCurrentSession as keyof typeof FeedbackRemindersByCompletedSessionCount
      ]
    : null;
}

export function isFeedbackRelevantForSession(
  project: Project | undefined,
  currentSessionNumber: number,
) {
  const flag = getFeedbackReminder(project, currentSessionNumber);
  return flag && project && project[flag as keyof typeof project];
}

export function lastCompletedSession(project: Project | undefined, sessionNumber: number) {
  if (!project || !project.sessions) {
    return null;
  }
  const arrayForSort = [...project.sessions];
  const reversed = arrayForSort.reverse();
  return reversed?.find((session) => {
    return (
      session.countAs !== 'no_show' &&
      session.countAs !== 'late_cancelled' &&
      session.countAs !== 'not_session' &&
      session.sessionNumber < sessionNumber
    );
  });
}

function isFeedbackSubmitted(project: Project | undefined, sessionNumber: number) {
  const prevCompletedSession = lastCompletedSession(project, sessionNumber);
  return prevCompletedSession?.feedbacks.length === project?.studentsCount;
}

export function isPreviousFeedbackSubmitted(
  project: Project | undefined,
  currentSessionNumber: number,
) {
  const relevant = isFeedbackRelevantForSession(project, currentSessionNumber);
  if (!relevant) {
    return true;
  }

  if (!project) {
    return false;
  }

  const session = lastCompletedSession(project, currentSessionNumber);
  if (!session) {
    return false;
  }

  return isFeedbackSubmitted(project, currentSessionNumber);
}

export function isProjectMeetingJoinable(
  project: Project | undefined,
  session: Session | undefined | null,
  meeting: Meeting | undefined,
) {
  if (!project || !project.sessions || !session || !meeting) {
    return false;
  }
  if (session.proposing) {
    return false;
  }
  return (
    (session.sessionNumber === 1 ||
      project.status === ProjectStatus.ACTIVE ||
      project.sessions
        .filter(({ summary }) => {
          return summary;
        })
        .every(({ countAs }) => {
          return countAs === 'no_show';
        })) &&
    isFinalStudentSurveyCompletedByLastSession(project, session) &&
    isPreviousFeedbackSubmitted(project, session.sessionNumber)
  );
}
