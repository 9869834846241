import type {
  PodsCohortId,
  PodsCourseId,
  PortfolioItemId,
  Slug,
  UUID,
  UserId,
  WorkspaceId,
} from '../../types/common';
import type { ProjectStatus } from '../../types/hermes';
import type { Nullable, ToUnion } from '../../types/utils';
import type { PaymentIntentInvoice } from '../payment';
import type { StudentPod } from '../pods';

import type { FrequentlyAskedQuestionSerializer } from './landing';
import type { ProjectOutcomeTypeSerializer } from './marketplace';

export const StudentEndOfPodStatus = {
  SURVEY: 'survey',
  PROJECT_OUTCOME: 'project_outcome',
  VIEW_PROGRAMS: 'view_programs',
} as const;

export type StudentEndOfPodStatuses =
  (typeof StudentEndOfPodStatus)[keyof typeof StudentEndOfPodStatus];

export interface StudentPodSerializer extends PublicPodsCohortSerializer {
  workspace_id: WorkspaceId;
  project_status: Nullable<ToUnion<typeof ProjectStatus>>;
  project_end_date: string;
  outcome_type: ProjectOutcomeTypeSerializer;
  active: boolean;
}

export interface StudentRelatedPod {
  paymentIntent: Nullable<PaymentIntentInvoice>;
  pod: StudentPod;
  isEnrolled: boolean;
}

export interface RequiredStudentInformation {
  isParentEmailMissing: boolean;
  isReferralMissing: boolean;
  isPodsReferralMissing: boolean;
  isUpsellStudent: boolean;
  isSchoolMissing: boolean;
  isProfileIncomplete: boolean;
}

export interface EndOfPodStatusSerializer {
  status: StudentEndOfPodStatuses;
  uuid: UUID;
  portfolio_item_id: PortfolioItemId;
}

export interface PublicPodMentorListSerializer {
  id: UserId;
  first_name: string;
  slugified_first_name: Slug;
  profile_picture: string;
  highest_institution: string;
  highest_subject: Nullable<string>;
  credential_status_label: string;
  overridden_education: Nullable<string>;
}

export interface PublicPodsCourseSerializer {
  id: PodsCourseId;
  slug: string;
  active: boolean;
  title: string;
  short_title: string;
  description: string;
  overview: string;
  outcome: string;
  image: string;
  week_by_week_overview: string[];
  faqs: FrequentlyAskedQuestionSerializer[];
  outcome_type: string;
  tag: string;
  price_in_cents: number;
  minimum_group_size: number;
  maximum_group_size: number;
  meta_title: string;
  meta_description: string;
}

export interface PublicPodsCourseCardSerializer {
  id: PodsCourseId;
  slug: string;
  title: string;
  image: string;
  number_of_open_cohorts: number;
  next_cohort_start_date: Nullable<string>;
  badge_text: Nullable<string>;
}

export interface PublicPodsCohortSerializer {
  id: PodsCohortId;
  uuid: string;
  start_date: string;
  number_of_sessions: number;
  current_group_size: number;
  maximum_group_size: number;
  price: number;
  mentors: PublicPodMentorListSerializer[];
  is_pod_full: boolean;
  remaining_seats: number;
  sign_up_deadline: string;
  course: PublicPodsCourseSerializer;
}

export interface PublicPodsWaitlistPodTopicSerializer {
  id: PodsCourseId;
  title: string;
}

export interface PublicPodsWaitlistSubmissionSerializer {
  course_id: PodsCourseId;
  email: string;
  graduation_year: Date;
  referral_type: string;
}
