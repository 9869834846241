import { ProjectType, ProjectTypes, Summary } from '@polygence/common';
import { Text } from '@polygence/components';

import { publicQuestions as launchpadPublicQuestions } from 'src/components/hermes/surveys/mentor/launchpadQuestions';
import { publicQuestions as pathfindersPublicQuestions } from 'src/components/hermes/surveys/mentor/pathfindersQuestions';
import { publicQuestions as reflectionPublicQuestions } from 'src/components/hermes/surveys/mentor/reflectionQuestions';

const publicQuestionsPerProjectType = {
  [ProjectType.PATHFINDER_LAUNCHPAD]: launchpadPublicQuestions,
  [ProjectType.PATHFINDER_STANDALONE]: pathfindersPublicQuestions,
  [ProjectType.PATHFINDER_DIAGNOSTIC]: reflectionPublicQuestions,
};

export const SummaryPublicQuestions = ({
  summary,
  projectType,
}: {
  summary: Pick<Summary, 'extras'>;
  projectType: ProjectTypes;
}) => {
  const publicQuestions =
    publicQuestionsPerProjectType[projectType as keyof typeof publicQuestionsPerProjectType];

  if (!publicQuestions) {
    return null;
  }
  /* these are displayed in separate sections, do not duplicate them here */
  const alreadyDisplayedKeys = ['studentStar', 'sessionRecap', 'studentEncouragement'];

  const filteredExtras = Object.fromEntries(
    Object.entries(summary.extras ?? {})
      .filter(([key]) => !alreadyDisplayedKeys.some((ignore) => key.startsWith(ignore)))
      .filter(([key]) =>
        publicQuestions.some((element) =>
          'name' in element.props ? element.props.name === key : false,
        ),
      ),
  );

  const withLabels = Object.entries(filteredExtras).map(([key, value]) => {
    return {
      key,
      label: publicQuestions.find(
        (element: (typeof publicQuestions)[number]) =>
          // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
          'labelFor' in element.props ? element.props.labelFor === key : false,
        // @ts-expect-error doesn't exist on some values
        // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
      )?.props?.children as string | undefined,
      value,
    };
  });

  return (
    <>
      {withLabels.map(({ key, label, value }) => {
        return (
          <div className="mb-4" key={key}>
            {label && (
              <Text className="mb-2" fontWeight="bold" size="small">
                {label}
              </Text>
            )}
            <Text size="small" className="text-break">
              {value ? String(value) : null}
            </Text>
          </div>
        );
      })}
    </>
  );
};
