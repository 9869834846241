import NiceModal, { useModal, bootstrapDialog } from '@ebay/nice-modal-react';
import { Modal, ModalBody } from '@polygence/components';

import {
  StudentSnapshot,
  StudentSnapshotProps,
} from 'src/components/common/StudentSnapshot/StudentSnapshot';

export const StudentSnapshotModal = NiceModal.create((props: StudentSnapshotProps) => {
  const modal = useModal();

  return (
    <Modal {...bootstrapDialog(modal)} closeButton>
      <ModalBody>
        <StudentSnapshot {...props} />
      </ModalBody>
    </Modal>
  );
});
