import { ProjectType } from '@polygence/common';

import { MEET_WITH_POLYGENCE_MENTOR_TEAM_CALENDLY_LINK } from 'src/constants';

const displayOnLastSession = {
  $or: [
    {
      $and: [
        { $is: { field: '__sessionsRemaining', value: 1 } },
        { $is: { field: 'countAs', value: 'full_session' } },
      ],
    },
    { $is: { field: '__sessionsRemaining', value: 0.5 } },
  ],
};

export const preparationLengthQuestions = [
  {
    component: 'HoursAndMinutes',
    labelVariables: ['__sessionNumber'],
    props: {
      label: 'How long did you prepare for this session?',
      name: 'preparationLength',
      small_font: "This is for internal use only - we don't share it with anybody.",
      required: true,
    },
    display: {
      $not: { field: 'countAs', value: 'no_show' },
    },
  },
];

export const techIssueQuestions = [
  {
    component: 'RadioButton',
    props: {
      label: 'Did student have video on for majority of the session?',
      name: 'videoIsOnQuestion',
      options: [
        {
          label: 'Yes.',
          value: 'yes',
          default: true,
        },
        {
          label: 'No.',
          value: 'no',
        },
      ],
    },
    display: {
      $and: [
        { $not: { field: 'countAs', value: 'no_show' } },
        { $is: { field: '__projectType', value: ProjectType.FLAGSHIP } },
        { $not: { field: '__sessionsRemaining', value: 1 } },
        { $not: { field: '__sessionsRemaining', value: 0.5 } },
      ],
    },
  },
  {
    component: 'RadioButton',
    props: {
      label: 'Did student provide a reason they needed camera to be off?',
      name: 'videoIsNotOnReasonExists',
      options: [
        {
          label: 'Yes.',
          value: 'yes',
          default: true,
        },
        {
          label: 'No.',
          value: 'no',
        },
      ],
    },
    display: {
      $and: [
        { $is: { field: 'videoIsOnQuestion', value: 'no' } },
        { $not: { field: 'countAs', value: 'no_show' } },
      ],
    },
  },
  {
    component: 'Checkbox',
    props: {
      label: 'Report tech issue',
      name: 'techIssue',
      id: 'techIssue',
    },
    display: {
      $not: { field: 'countAs', value: 'no_show' },
    },
  },
  {
    component: 'FormInput',
    props: {
      name: 'techIssueComment',
      inputType: 'textarea',
      required: true,
      htmlRequired: true,
    },
    display: {
      $is: { field: 'techIssue', value: true },
    },
  },
];

export const projectIssueQuestions = [
  {
    component: 'Checkbox',
    props: {
      label: 'Flag issue with your student or their project',
      name: 'projectIssue',
      id: 'projectIssue',
    },
    display: {
      $not: { field: 'countAs', value: 'no_show' },
    },
  },
  {
    component: 'FormInput',
    props: {
      name: 'projectIssueComment',
      inputType: 'textarea',
      required: true,
      htmlRequired: true,
    },
    display: {
      $is: { field: 'projectIssue', value: true },
    },
  },
];

const displayAiSessionSummary = {
  $and: [
    { $not: { field: 'countAs', value: 'no_show' } },
    { $not: { field: '__projectType', value: ProjectType.PATHFINDER_LAUNCHPAD } },
    { $not: { field: '__projectType', value: ProjectType.PATHFINDER_STANDALONE } },
    { $is: { field: '__aiSessionSummaryEnabled', value: true } },
  ],
};

/* eslint-disable-next-line import/no-anonymous-default-export, import/no-default-export -- autodisabled, autodisabled */
export default [
  ...preparationLengthQuestions,
  {
    component: 'MarkdownEditor',
    labelVariables: ['__sessionNumber'],
    props: {
      label: 'Session {{__sessionNumber}} summary',
      name: 'content',
      inputType: 'textarea',
      small_font: `We will send out this summary to the student and parent after submission. Please provide details of what you covered in the session.`,
      required: true,
      htmlRequired: true,
      editorStyle: {
        minHeight: '180px',
        padding: '0 5px',
      },
      placeholder: `Since last time, you've made progress on... Today we reviewed... I gave you feedback on...`,
    },
    display: {
      $and: [
        { $not: { field: 'countAs', value: 'no_show' } },
        { $not: { field: '__projectType', value: ProjectType.PATHFINDER_LAUNCHPAD } },
        { $not: { field: '__projectType', value: ProjectType.PATHFINDER_STANDALONE } },
        { $is: { field: '__aiSessionSummaryEnabled', value: false } },
      ],
    },
  },
  {
    component: 'Heading',
    props: {
      text: 'Session summary',
      className: '',
    },
    display: displayAiSessionSummary,
  },
  {
    component: 'Alert',
    props: {
      variant: 'primary',
      children: `To save you time, we will generate the session summary based on your session transcript and send it to the student and parent after submission. If you'd like to review and make changes, please check the verification option below.`,
    },
    display: displayAiSessionSummary,
  },
  {
    component: 'Checkbox',
    props: {
      id: 'aiGeneratedSummaryManualReview',
      name: 'aiGeneratedSummaryManualReview',
      label: 'Want to confirm/verify the generated summary',
      defaultValue: false,
    },
    display: displayAiSessionSummary,
  },
  {
    component: 'MarkdownEditor',
    props: {
      label: 'Loose ends to tie',
      name: 'assignment',
      inputType: 'textarea',
      small_font:
        'We will send out this to both the student and parent after submission with the specified due date.',
    },
    display: {
      $and: [
        displayOnLastSession,
        { $not: { field: 'countAs', value: 'no_show' } },
        { $not: { field: '__projectType', value: ProjectType.PATHFINDER_LAUNCHPAD } },
        { $not: { field: '__projectType', value: ProjectType.PATHFINDER_STANDALONE } },
        { $not: { field: '__projectType', value: ProjectType.PATHFINDER_DIAGNOSTIC } },
      ],
    },
  },
  ...techIssueQuestions,
  ...projectIssueQuestions,
  {
    component: 'Paragraph',
    props: {
      text: 'If you’re at session 3 or later, we highly recommend that the student team reach out to the student. Would this be okay with you? May we let your student know that you brought this to our attention?',
    },
    display: {
      $and: [
        { $is: { field: 'projectIssue', value: true } },
        { $gt: { field: '__sessionNumber', value: 2 } },
      ],
    },
  },
  {
    component: 'Checkbox',
    props: {
      label: 'I am okay with the student team contacting my student',
      name: 'canContactStudent',
      id: 'canContactStudent',
      defaultValue: true,
    },
    display: {
      $and: [
        { $is: { field: 'projectIssue', value: true } },
        { $gte: { field: '__sessionNumber', value: 2 } },
      ],
    },
  },
  {
    component: 'Checkbox',
    props: {
      label: 'I would like to remain anonymous',
      name: 'remainAnonymous',
      id: 'remainAnonymous',
    },
    display: {
      $and: [
        { $is: { field: 'projectIssue', value: true } },
        { $gt: { field: '__sessionNumber', value: 2 } },
      ],
    },
  },
  {
    component: 'RadioButton',
    props: {
      label:
        'Does your student plan on conducting original research that involves human participants? For example, collecting data through a survey',
      name: 'irbQuestion',
      options: [
        {
          label: 'Yes.',
          value: 'yes',
        },
        {
          label: 'No.',
          value: 'no',
          default: true,
        },
      ],
    },
    display: {
      $is: { field: '__sessionNumber', value: 2 },
    },
  },
  {
    component: 'Paragraph',
    props: {
      text: `You are always welcome to book a meeting with us [here](${MEET_WITH_POLYGENCE_MENTOR_TEAM_CALENDLY_LINK}) to discuss anything: issues, strategies, etc`,
    },
    display: {
      $not: { field: 'countAs', value: 'no_show' },
    },
  },
];
