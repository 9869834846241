import {
  projectIssueQuestions,
  techIssueQuestions,
} from 'src/components/hermes/surveys/mentor/generalQuestions';
import {
  sessionCountAsQuestion,
  studentPositiveFeedbackQuestions,
} from 'src/components/hermes/surveys/mentor/metaQuestions';
import {
  makeQuestionsOnlyAvailableForPolygence,
  makeQuestionsSentToStudentSection,
} from 'src/components/hermes/surveys/mentor/utils';

export const publicQuestions = [
  {
    component: 'LabelWithTooltip',
    props: {
      children: 'Recap the session in 2 sentences:',
      tooltip:
        'This brief summary reminds your student of your conversation and informs their parent about what you discussed.',
      labelFor: 'sessionRecap',
    },
  },
  {
    component: 'FormInput',
    props: {
      name: 'sessionRecap',
      inputType: 'textarea',
      placeholder: 'It was great chatting with you about...',
      htmlRequired: true,
    },
  },
  {
    component: 'LabelWithTooltip',
    props: {
      children: 'What I heard from you in this session was that...',
      tooltip:
        'This is a chance to be the student’s mirror and reflect back to them what you heard in the session. Consider highlighting their strengths, the times you saw their eyes light up with excitement, etc.',
      labelFor: 'sessionStudentHighlight',
    },
  },
  {
    component: 'FormInput',
    props: {
      name: 'sessionStudentHighlight',
      inputType: 'textarea',
      htmlRequired: true,
      placeholder: 'You are...',
    },
  },
  {
    component: 'LabelWithTooltip',
    props: {
      children: 'Given your interests, I recommend the following resources:',
      tooltip:
        'You likely already discussed in your session additional resources your student can check out to further learn about their career path. Here is another place to share more resources you think they would most benefit from.',
      labelFor: 'recommendedResources',
    },
  },
  {
    component: 'FormInput',
    props: {
      name: 'recommendedResources',
      inputType: 'textarea',
      rows: 5,
      placeholder:
        'For example: \n1. Books/papers/articles to read \n2. Podcasts/TedTalks to explore \n3. Summer programs to consider \n4. Online courses to take or a competition to enter',
      htmlRequired: true,
    },
  },
  studentPositiveFeedbackQuestions,
  {
    component: 'LabelWithTooltip',
    props: {
      children: 'Send a quick note of encouragement:',
      tooltip: 'A short note of encouragement can go a long way for students!',
      labelFor: 'studentEncouragement',
    },
  },
  {
    component: 'FormInput',
    props: {
      name: 'studentEncouragement',
      inputType: 'textarea',
      htmlRequired: true,
    },
  },
] as const;

export const privateQuestions = [
  {
    component: 'LabelWithTooltip',
    props: {
      children:
        'If the student wanted an additional follow up session with you, would you be open to that?',
      tooltip:
        'This data helps us know if, in the off chance a student asks for another session to follow up, we should or should not reach out to you to match you for one more session.',
      labelFor: 'openToAdditionalSession',
    },
  },
  {
    component: 'RadioButton',
    props: {
      name: 'openToAdditionalSession',
      required: true,
      options: [
        { label: 'Yes', value: 'yes' },
        {
          label: 'No',
          value: 'no',
        },
      ],
    },
  },
  {
    component: 'LabelWithTooltip',
    props: {
      children:
        'Which other Polygence programs do you think this student would be a good fit for in the next 1-6 months? (select all that apply)',
      tooltip:
        'This data helps us learn more about what would be helpful for the student after this program.',
    },
  },
  {
    component: 'Checkbox',
    props: {
      label: 'Core Program - 10 session project to dive deep',
      name: 'studentProgramFitCore',
    },
  },
  {
    component: 'Checkbox',
    props: {
      label: 'Pods - 6 week structured sessions with peers',
      name: 'studentProgramFitPods',
    },
  },
  {
    component: 'Checkbox',
    props: {
      label: 'Launchpad - 3 session workshopping a Core program project idea',
      name: 'studentProgramFitLaunchpad',
    },
  },
  {
    component: 'Checkbox',
    props: {
      label: 'UCI - 3 credit program and access to college level coursework',
      name: 'studentProgramFitUCI',
    },
  },
  {
    component: 'Checkbox',
    props: {
      label: 'Premium Showcasing - 3 sessions to create showcasing plan with a specialist',
      name: 'studentProgramFitPSS',
    },
  },
  {
    component: 'Checkbox',
    props: {
      label: 'A program outside of Polygence',
      name: 'studentProgramFitOther',
    },
  },
  {
    component: 'FormInput',
    props: {
      name: 'studentProgramFitOtherDescription',
      inputType: 'text',
    },
    display: {
      $is: { field: 'studentProgramFitOther', value: true },
    },
  },
  {
    component: 'LabelWithTooltip',
    props: {
      children:
        'Please feel free to elaborate on any reasoning for checking a program above (Optional):',
    },
  },
  {
    component: 'FormInput',
    props: {
      name: 'studentProgramFitElaboration',
      inputType: 'textarea',
    },
  },
  {
    component: 'LabelWithTooltip',
    props: {
      children:
        'If this student chose to do a Core Program (10 session project), would you want to work with them?',
      tooltip:
        'This data helps us know if we should or should not reach out to you to propose a match for the Core program should this student get to that point.',
    },
  },
  {
    component: 'RadioButton',
    props: {
      name: 'openToMentorCore',
      required: true,
      showLabel: false,
      options: [
        { label: 'Yes', value: 'yes' },
        {
          label: 'No',
          value: 'no',
        },
        { value: 'maybe', label: 'Maybe' },
      ],
    },
  },
  {
    component: 'LabelWithTooltip',
    props: {
      children:
        'Would you like to share any additional thoughts on how we can improve Pathfinders? (Optional)',
      tooltip: 'We can always get better! Thanks for helping us improve.',
    },
  },
  {
    component: 'FormInput',
    props: {
      name: 'improvementIdea',
      inputType: 'textarea',
    },
  },
] as const;

export const pathfindersQuestions = [
  sessionCountAsQuestion,
  makeQuestionsSentToStudentSection(publicQuestions),
  makeQuestionsOnlyAvailableForPolygence(privateQuestions),
  ...techIssueQuestions,
  ...projectIssueQuestions,
] as const;
