import { getRestClient } from '../commonSettings';
import type {
  EMAIL_VALIDATION_RESULT,
  Invitation,
  InvitationData,
  InvitedUser,
  LoginData,
  LoginUser,
  MobileLoginData,
  MobileProvider,
  SimpleUser,
  UnfinishedRegistration,
} from '../types/backend';
import type { ProspectiveUserId, UUID, UserId } from '../types/common';
import type { EmptyResponse } from '../types/data/common';
import type { StringUserType } from '../types/user';
import { getBaseApiUrl, getBaseGeoApiUrl, getBaseUrl } from '../utils/baseUrls';

export function login(payload: LoginUser) {
  const internalFetch = getRestClient();
  return internalFetch.post<LoginData>('/user/login/', payload);
}

export function mobileThirdPartyLogin(token: string, provider: MobileProvider) {
  const internalFetch = getRestClient();
  return internalFetch.post<MobileLoginData>(`user/auth/social/${provider}/login/mobile/`, {
    token,
  });
}

export function deleteAccount(password: string) {
  const internalFetch = getRestClient();
  return internalFetch.delete('user/delete_current_user/', { data: { password } });
}

export function getBackendVersion() {
  const internalFetch = getRestClient();
  return internalFetch.get<{ mobileApiVersion: number }>('version/');
}

export const refreshToken = (refreshToken: string) => {
  const internalFetch = getRestClient();
  return internalFetch.post<{ access: string }>('/user/login/refresh/', {
    refresh: refreshToken,
  });
};

export const createUnverifiedUser = (data: {
  email: string;
  phoneNumber: string;
  userType: string;
  firstName: string;
  lastName: string;
  timeZone: string;
  invitationUuid?: string;
}) => {
  const internalFetch = getRestClient();
  return internalFetch.post<{ token: string; refresh: string }>(
    '/user/create_unverified_user/',
    data,
    { withCredentials: true },
  );
};

export const requestAccountVerification = () => {
  const internalFetch = getRestClient();
  return internalFetch.post<{ message: string }>('/user/send-verification-email/');
};

export const verifyAccount = (uuid: string, password: string) => {
  const internalFetch = getRestClient();
  return internalFetch.post<{ access?: string; refresh?: string }>('/user/verify-account/', {
    uuid,
    password,
  });
};

export const updateUserInfo = (payload: { firstName?: string; lastName?: string }) => {
  const internalFetch = getRestClient();
  return internalFetch.patch<{ firstName: string; lastName: string; id: UserId }>(
    '/user/user-info/',
    payload,
  );
};

export const getGoogleAuthorizationUrl = (redirectUri: string) => {
  const internalFetch = getRestClient();
  return internalFetch.get<{ authorizationUrl: string }>(
    `/user/auth/social/google/get-url?redirect_uri=${getBaseUrl(redirectUri)}`,
  );
};

export const getUserByReferralCode = (code: string) => {
  const internalFetch = getRestClient();
  return internalFetch.get<{ result: SimpleUser }>(`/user/user-by-referral-code/`, {
    params: { code },
  });
};

export const loginWithGoogle = (code: string, state: string, channel: string | undefined) => {
  const internalFetch = getRestClient();
  return internalFetch.post<UnfinishedRegistration | LoginData>(
    `${getBaseApiUrl()}/user/auth/social/google/login/`,
    { code, state, channel },
  );
};

export const loginWithApple = (payload: {
  token: string;
  channel?: string;
  firstName?: string;
  lastName?: string;
}) => {
  const internalFetch = getRestClient();
  return internalFetch.post<UnfinishedRegistration | LoginData>(
    `/user/auth/social/apple/login/`,
    payload,
  );
};

export const createAccountWithSocialProvider = (
  pUserUuid: UUID,
  payload: { userType: StringUserType; phoneNumber: string },
) => {
  const internalFetch = getRestClient();
  return internalFetch.post<{ access: string; refresh: string }>(
    `/user/register-social/${pUserUuid}/`,
    payload,
    { withCredentials: true },
  );
};

export const getEmailValidation = (emailAddress: string) => {
  const internalFetch = getRestClient();
  return internalFetch.get<{ result: EMAIL_VALIDATION_RESULT }>(
    `/user/email/validation/?email_address=${emailAddress}`,
  );
};

export const getImpersonatorTokens = (userId: UserId) => {
  const internalFetch = getRestClient();
  return internalFetch.post<{ access: string; refresh: string }>('/user/impersonator-login/', {
    userId,
  });
};

export const stopImpersonatorSession = () => {
  const internalFetch = getRestClient();
  return internalFetch.post<EmptyResponse>('/user/impersonator-logout/');
};

export const activate = (uid: UUID, token: string) => {
  const internalFetch = getRestClient();
  return internalFetch.post('/user/activate/', { uid, token });
};

export const changeEmailCheck = (uuid: UUID) => {
  const internalFetch = getRestClient();
  return internalFetch.post('/user/change-email-check/', { uuid });
};

export const changeEmailConfirm = (uuid: UUID, password: string) => {
  const internalFetch = getRestClient();
  return internalFetch.post('/user/change-email-confirm/', {
    uuid,
    password,
  });
};

export const updateProspectiveUserReferral = (
  prospectiveUserId: ProspectiveUserId,
  referralId: UserId,
) => {
  const internalFetch = getRestClient();
  return internalFetch.patch(`/user/prospective-user/${prospectiveUserId}/update-referral/`, {
    referralId,
  });
};

export const resendActivation = (email: string) => {
  const internalFetch = getRestClient();
  return internalFetch.post<{ email: string }>('/user/resend-activation/', {
    email,
  });
};

export const sendInvitation = ({
  inviteeEmail,
  inviteeFirstName,
  inviteePhoneNumber,
  inviterUserId,
  productType,
  podSlug,
  ccEmails,
}: InvitationData) => {
  const payload = {
    email: inviteeEmail,
    first_name: inviteeFirstName,
    phone_number: inviteePhoneNumber,
    inviter_user_id: inviterUserId,
    productType,
    podSlug,
    cc_emails: ccEmails,
  };

  const internalFetch = getRestClient();
  return internalFetch.post<Invitation>('/user/invitation/', payload);
};

export const getInvitations = () => {
  const internalFetch = getRestClient();
  return internalFetch.get<Invitation[]>('/user/invitation/');
};

export const getInvitationStatus = () => {
  const internalFetch = getRestClient();
  return internalFetch.get<InvitedUser[]>('/user/invitation/status/');
};

export const connectAccounts = (uuid: UUID) => {
  const internalFetch = getRestClient();
  return internalFetch.post('/user/connect-accounts/', { uuid });
};

export const changeEmailRequest = (payload: { email: string }) => {
  const internalFetch = getRestClient();
  return internalFetch.post<{ email: string }>('/user/change-email-request/', payload);
};

export const passwordReset = (payload: Record<string, unknown>) => {
  const internalFetch = getRestClient();
  return internalFetch.post('/user/password-reset/', payload);
};

export const deleteUserByAdmin = (userId: UserId, payload: { email: string }) => {
  const internalFetch = getRestClient();
  return internalFetch.post(`/user/${userId}/delete/`, payload);
};

export const getAnonymousUserFeatures = () => {
  const internalFetch = getRestClient();
  return internalFetch.get<Record<never, never>>('/user/anonymous-user/features/', {
    withCredentials: true,
  });
};

export const getAnonymousUserUserIntent = () => {
  const internalFetch = getRestClient();
  return internalFetch.get<{ userIntent: string }>('/user/anonymous-user/user-intent/', {
    withCredentials: true,
  });
};

export const connectGoogleAccount = (code: string, state: string) => {
  const internalFetch = getRestClient();
  return internalFetch.post('/user/auth/social/google/connect/', { code, state });
};

export const deleteGoogleConnection = () => {
  const internalFetch = getRestClient();
  return internalFetch.delete<EmptyResponse>('/user/auth/social/google/delete_google_connect/');
};

export const trackAnonymousUser = (referrer: string, params: Record<string, string>) => {
  const internalFetch = getRestClient();
  return internalFetch.post(
    `${getBaseGeoApiUrl()}/user/track-anonymous/`,
    { referer: referrer, params },
    { withCredentials: true },
  );
};

export const createProspectiveUser = (token: string, payload: Record<string, unknown>) => {
  const internalFetch = getRestClient();
  return internalFetch.post<{ refresh: string; access: string; token: string }>(
    `/user/prospective-user/${token}/`,
    payload,
  );
};

export const passwordResetConfirm = (
  payload: {
    uid: UUID;
    token: string;
  } & Record<string, unknown>,
) => {
  const internalFetch = getRestClient();
  return internalFetch.post(`${getBaseApiUrl()}/user/password-reset-confirm/`, payload);
};

export const getAttributeSuggestions = () => {
  const internalFetch = getRestClient();
  return internalFetch.get<Record<string, string[]>>('/user/attribute-suggestions/');
};

export const changeEmailSendTextMessage = (uuid: string) => {
  const internalFetch = getRestClient();
  return internalFetch.post<{ newEmail: string }>(
    `/user/change-email-send-text-message/${uuid}/`,
    undefined,
  );
};

export const changeEmailSMSConfirm = (uuid: UUID, confirmationCode: string) => {
  const internalFetch = getRestClient();
  return internalFetch.post<EmptyResponse | { message: string }>(
    '/user/change-email-sms-confirm/',
    {
      uuid,
      confirmationCode,
    },
  );
};

export const discourseSso = (sso: string, sig: string) => {
  const internalFetch = getRestClient();
  return internalFetch.get<{ url: string }>(`/user/discourse-sso?sso=${sso}&sig=${sig}`);
};

export const getServerTime = () => {
  const internalFetch = getRestClient();
  return internalFetch.get<string>('/server-time/');
};
