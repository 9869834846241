import { UserType, commonHooks } from '@polygence/common';
import type { StudentRelatedPod } from '@polygence/common/types/data/pods';
import { pluralize } from '@polygence/common/utils/pluralize';
import { Button, Collapsible, Heading, Text } from '@polygence/components';
import classNames from 'classnames';
import { Container } from 'react-bootstrap';
import { Redirect } from 'react-router-dom';

import { ActivePodInfoCard } from 'src/students/Pods/ActivePodInfoCard';
import { PendingPodInfoCard } from 'src/students/Pods/PendingPodInfoCard';
import { PodFullPage } from 'src/students/Pods/PodFullPage';
import styles from 'src/students/Pods/PodHubPage.module.scss';
import { urls } from 'src/urls';

export const PodHubPage = ({
  pods,
  tokenCount,
}: {
  pods: StudentRelatedPod[];
  tokenCount: number;
}) => {
  const { firstName, userType } = commonHooks.useCurrentUser();
  const activePods = pods.filter((pod) => pod.isEnrolled);
  const pendingPods = pods.filter((pod) => !pod.isEnrolled);

  if (userType !== UserType.STUDENT) {
    return (
      <div className="tw-container tw-mx-auto tw-flex tw-flex-col tw-items-center">
        <Heading as="h1" size="h3" alignment="center" className="tw-mb-6 tw-mt-[30vh]">
          Currently, Pods is only available to students.
        </Heading>
        <Text size="large" className="tw-mb-6">
          Please log out of the account you're signed in with or have your student register with an
          account to enroll.
        </Text>
      </div>
    );
  }

  if (pods.length === 0) {
    return (
      <div className="tw-container tw-mx-auto tw-flex tw-flex-col tw-items-center">
        <Heading size="h2" alignment="center" className="tw-mb-6 tw-mt-[30vh]">
          {`Welcome to Pods, ${firstName}!`}
        </Heading>
        <Text size="medium" className="tw-mb-6">
          Click the button below to see our selection of Pods.
        </Text>
        <Button variant="primary" href={urls.getLandingPodsCohortPage()} className="tw-mx-auto">
          Choose a Pod
        </Button>
      </div>
    );
  }

  if (pods.length === 1 && pendingPods[0]) {
    const pod = pendingPods[0].pod;
    if (pod.isPodFull) {
      return <PodFullPage startDate={pod.startDate} title={pod.course.title} />;
    }
    const paymentIntent = pendingPods[0].paymentIntent;
    if (paymentIntent) {
      return <Redirect to={`/payment/${paymentIntent.uuid}`} />;
    }
  }

  return (
    <>
      {activePods.length > 0 && (
        <section className={classNames('pt-5 pb-10', styles['activePods'])}>
          <Container>
            <Heading size="h5" alignment="left" className="mb-6">
              {`Welcome to Pods, ${firstName}!`}
            </Heading>
            {activePods.map((entry) => (
              <ActivePodInfoCard key={entry.pod.course.title} pod={entry.pod} />
            ))}
          </Container>
        </section>
      )}
      {pendingPods.length > 0 && (
        <section>
          <Container className="py-5">
            <Collapsible
              summary="Interested in these pods?"
              defaultOpen
              contentClassNames="tw-overflow-visible"
            >
              <hr className={styles['divider']} />
              {tokenCount > 0 && (
                <Text size="medium" fontWeight="bold" className="mt-2">
                  You can enroll in {tokenCount} {pluralize('pod', tokenCount)} without additional
                  fees
                </Text>
              )}
              {pendingPods.map((entry) => (
                <PendingPodInfoCard
                  key={entry.pod.course.title}
                  pod={entry}
                  tokenCount={tokenCount}
                />
              ))}
            </Collapsible>
          </Container>
        </section>
      )}
    </>
  );
};
