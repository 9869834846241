import type { Paginated } from '@polygence/common';
import { Alert, Button, Text, Icon } from '@polygence/components';
import type {
  TypedUseQueryHookResult,
  UseQuery,
} from '@reduxjs/toolkit/dist/query/react/buildHooks';
import type { BaseQueryFn, QueryDefinition } from '@reduxjs/toolkit/query';
import { Dispatch, ReactNode, SetStateAction, useEffect, useRef, useState } from 'react';

import { Loader } from 'src/components/Loader';

const PAGE_COUNT = 7;
const DEFAULT_PAGE_SIZE = 10;

const getFirstPageNumber = (page: number, lastPage: number) => {
  return Math.min(
    Math.max(page - Math.floor(PAGE_COUNT / 2), 1),
    Math.max(lastPage - PAGE_COUNT + 1, 1),
  );
};

const getPagesArray = (firstPageNumber: number, lastPageNumber: number) => {
  return Array.from(
    { length: Math.min(PAGE_COUNT, lastPageNumber) },
    (_, i) => i + firstPageNumber,
  );
};

const PaginatorComponent = ({
  currentPage,
  setCurrentPage,
  pages,
  lastPageNumber,
  loading,
  totalCount,
  pageSize,
}: {
  currentPage: number;
  setCurrentPage: Dispatch<SetStateAction<number>>;
  pages: number[];
  lastPageNumber: number;
  loading: boolean;
  totalCount: number;
  pageSize: number;
}) => {
  const currentWindowStart = (currentPage - 1) * pageSize + 1;
  const currentWindowEnd = Math.min(currentWindowStart + pageSize - 1, totalCount);

  return (
    <div className="d-flex justify-content-between align-items-center px-3 my-4">
      <div className="d-flex gap-2">
        <Button
          size="sm"
          variant="link"
          disabled={currentPage === 1 || loading}
          className="btn btn-default"
          onClick={() => setCurrentPage(1)}
        >
          First
        </Button>
        <Button
          size="sm"
          variant="link"
          disabled={currentPage === 1 || loading}
          className="btn btn-default"
          onClick={() => setCurrentPage((prevValue) => prevValue - 1)}
        >
          &lt;
        </Button>
        {pages.map((page) => (
          <Button
            size="sm"
            variant={currentPage === page ? 'primary' : 'link'}
            className={currentPage !== page ? 'd-none d-sm-block' : undefined}
            key={`page-button-${page}`}
            onClick={() => setCurrentPage(page)}
            disabled={loading}
          >
            {page}
          </Button>
        ))}
        <Button
          size="sm"
          variant="link"
          disabled={currentPage === lastPageNumber || loading}
          className="btn btn-default"
          onClick={() => setCurrentPage((prevValue) => prevValue + 1)}
        >
          &gt;
        </Button>
        <Button
          size="sm"
          variant="link"
          disabled={currentPage === lastPageNumber || loading}
          className="btn btn-default"
          onClick={() => setCurrentPage(lastPageNumber)}
        >
          Last
        </Button>
      </div>
      {currentWindowStart && totalCount > 0 && (
        <Text size="small" as="span">
          {currentWindowStart} - {currentWindowEnd} of{' '}
          <Text size="small" as="span" fontWeight="bold">
            {totalCount}
          </Text>
        </Text>
      )}
    </div>
  );
};

const DefaultLoading = () => {
  return <Loader className="mx-auto my-5" />;
};

const DefaultEmpty = () => {
  return (
    <div className="d-flex justify-content-center">
      <Alert variant="warning" className="d-flex align-items-center gap-3">
        <Icon id="filter" />
        <Text size="medium" alignment="center" fontWeight="bold">
          No results found
        </Text>
      </Alert>
    </div>
  );
};

const DefaultError = () => {
  return (
    <Alert variant="danger" className="text-center my-5">
      Something went wrong.
    </Alert>
  );
};

export const RtkPaginator = <
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  TQueryArg extends Record<string, any>,
  TBaseQuery extends BaseQueryFn,
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  TResult extends Paginated<any>,
  TTagType extends string,
  TApiPath extends string,
>({
  query,
  queryArgs,
  paginationArgs = {},
  children,
  loading = <DefaultLoading />,
  error = <DefaultError />,
  empty = <DefaultEmpty />,
}: {
  query: UseQuery<QueryDefinition<TQueryArg, TBaseQuery, TTagType, TResult, TApiPath>>;
  queryArgs?: Record<string, unknown>;
  paginationArgs?: Record<string, unknown>;
  children: (arg: TypedUseQueryHookResult<TResult, TQueryArg, TBaseQuery>) => ReactNode;
  loading?: ReactNode;
  error?: ReactNode;
  empty?: ReactNode;
}) => {
  const [currentPage, setCurrentPage] = useState(1);
  const previousCount = useRef(0);
  const { pageSize = DEFAULT_PAGE_SIZE, ...args } = paginationArgs as { pageSize?: number };
  const params = {
    ...args,
    page_size: pageSize,
    page: currentPage,
  };
  // eslint-disable-next-line @typescript-eslint/no-unsafe-argument, @typescript-eslint/no-explicit-any
  const queryResult = query(params as any, {
    refetchOnMountOrArgChange: true,
    ...queryArgs,
  });
  const { currentData, isFetching, isSuccess, isError } = queryResult;
  const count = currentData?.count ?? 0;

  const deferredCount = isFetching ? previousCount.current : count;
  previousCount.current = deferredCount;

  const lastPageNumber = deferredCount ? Math.ceil(deferredCount / pageSize) : 1;
  const firstPageNumber = deferredCount ? getFirstPageNumber(currentPage, lastPageNumber) : 1;
  const pages = getPagesArray(firstPageNumber, lastPageNumber);

  const prevPaginationArgsRef = useRef(paginationArgs);

  useEffect(() => {
    const prevPaginationArgs = prevPaginationArgsRef.current;

    if (
      Object.keys(prevPaginationArgs).length === Object.keys(paginationArgs).length &&
      Object.keys(paginationArgs).every((key) => paginationArgs[key] === prevPaginationArgs[key])
    ) {
      return;
    }

    prevPaginationArgsRef.current = paginationArgs;
    setCurrentPage(1);
  }, [paginationArgs]);

  if (lastPageNumber < 1) {
    return null;
  }

  return (
    <>
      <PaginatorComponent
        currentPage={currentPage}
        setCurrentPage={setCurrentPage}
        pages={pages}
        lastPageNumber={lastPageNumber}
        loading={isFetching}
        totalCount={deferredCount}
        pageSize={pageSize}
      />
      {isSuccess && deferredCount === 0 && empty}
      {isSuccess && currentData?.results && (
        <>
          {children(queryResult)}
          {currentData?.results.length > 0 && (
            <PaginatorComponent
              currentPage={currentPage}
              setCurrentPage={setCurrentPage}
              pages={pages}
              lastPageNumber={lastPageNumber}
              loading={isFetching}
              totalCount={deferredCount}
              pageSize={pageSize}
            />
          )}
        </>
      )}
      {isFetching && loading}
      {isError && error}
    </>
  );
};
