import { Card, Heading } from '@polygence/components';
import { useParams } from 'react-router';

import { useStudentLifecycleEventsQuery } from 'src/reducers/partnerDashboardReducers';

export const StudentEvents = () => {
  const { id } = useParams<{ id: string }>();
  const { data } = useStudentLifecycleEventsQuery({ partnerCompanyId: Number(id) });

  return (
    <>
      <Heading size="h5" as="h1" alignment="left">
        Student Updates
      </Heading>
      <Card body>
        <pre>{JSON.stringify(data, null, 2)}</pre>
      </Card>
    </>
  );
};
