import type { EmptyResponse, MeetingId } from '@polygence/common';
import { axiosBaseQuery } from '@polygence/common/api/fetch';
import type { AIMeetingSummaryReview } from '@polygence/common/types/hermes';
import { createApi } from '@reduxjs/toolkit/query/react';

export const hermesMeetingAISummaryApi = createApi({
  baseQuery: axiosBaseQuery(),
  reducerPath: 'hermesMeetingAISummary',
  tagTypes: ['MeetingToReview'],
  endpoints: (build) => {
    return {
      meetingToReview: build.query<AIMeetingSummaryReview, number>({
        providesTags: (result, _, __) => {
          return result ? [{ type: 'MeetingToReview', id: result.id }] : [];
        },
        query: (meetingId) => {
          return {
            url: `/hermes/meetings/${meetingId}/ai-summary-review/`,
            method: 'GET',
          };
        },
      }),
      approveMeetingAISummary: build.mutation<
        EmptyResponse,
        {
          meetingId: MeetingId;
        }
      >({
        query: ({ meetingId }) => {
          return {
            url: `/hermes/meetings/${meetingId}/ai-summary-review/approve/`,
            method: 'POST',
          };
        },
        invalidatesTags: (_, __, { meetingId }) => [{ type: 'MeetingToReview', id: meetingId }],
      }),
      reviewMeetingAISummary: build.mutation<
        EmptyResponse,
        {
          meetingId: MeetingId;
          summaryContent: string;
        }
      >({
        query: ({ meetingId, summaryContent }) => {
          return {
            url: `/hermes/meetings/${meetingId}/ai-summary-review/review/`,
            method: 'POST',
            data: { summaryContent },
          };
        },
        invalidatesTags: (_, __, { meetingId }) => [{ type: 'MeetingToReview', id: meetingId }],
      }),
    };
  },
});

export const {
  useMeetingToReviewQuery,
  useApproveMeetingAISummaryMutation,
  useReviewMeetingAISummaryMutation,
} = hermesMeetingAISummaryApi;
