import type {
  AttachmentId,
  CaseStudyStatus,
  ChannelMedal,
  CohortId,
  CounselorProfileId,
  CountryId,
  Date,
  DateTime,
  FinancialAidDocumentId,
  Gender,
  InstitutionId,
  MarkdownString,
  MentorProfileId,
  MentorRequestId,
  NoteId,
  ParentProfileId,
  PortfolioItemId,
  ProjectId,
  ProjectLinkId,
  ProjectOutcomeTypeId,
  ScholarGroupId,
  SchoolId,
  Slug,
  StudentApplicationId,
  StudentProfileId,
  StudentScholarPageId,
  SymposiumPresenterStatisticsId,
  TagId,
  TimeZone,
  UserId,
  UUID,
  WorkspaceId,
} from '../common';
import type { ProjectStatus, ProjectTypes } from '../hermes';
import type {
  FileTypes,
  MentorPackagePaidOrScholarShip,
  MentorRequestProposalType,
  PortfolioItemStatus,
  ShowcasingSpecialty,
  StudentScholarPage,
} from '../marketplace';
import type { ExpectedOutput, GeneralInterest } from '../studentApplication';
import type { StringUserType } from '../user';
import type { Nullable } from '../utils';

import type { ProjectSessionSerializer } from './analytics';
import type { SimpleUserSerializer } from './backend';
import type { UpcomingSessionSerializer } from './hermes';
import { PublicCaseStudyListSerializer } from './landing';
import { PaymentIntentStudentOverviewSerializer } from './payment';
import type { AcceptedStudentApplicationSerializer } from './studentApplication';
import type { KeywordSerializer } from './zeus';

export interface TempFileSerializer {
  filename: string;
  type: 'temp';
  url: string;
  uuid: UUID;
}

export interface PermanentFileSerializer {
  type: 'permanent';
  name: string;
  file: string;
  url: string;
  uuid: UUID;
}

export interface InstitutionPublicSerializer {
  id: InstitutionId;
  label: string;
  value: string;
}

export const studentCollegeChoiceStatusOptions = [
  { label: 'Will Apply', value: 'will_apply' },
  { label: 'Applied', value: 'applied' },
  { label: 'Accepted', value: 'accepted' },
  { label: 'Not Accepted', value: 'not_accepted' },
  { label: 'Waitlisted', value: 'waitlisted' },
  { label: 'Going', value: 'going' },
] as const;

export type StudentCollegeChoiceStatus =
  (typeof studentCollegeChoiceStatusOptions)[number]['value'];

export type StudentCollegeChoiceStatusLabel =
  (typeof studentCollegeChoiceStatusOptions)[number]['label'];

export const studentCollegeChoiceSchoolLevelsOptions = [
  { label: 'MA/MS (Masters) program', value: 'ma_ms_program' },
  { label: 'PhD/Doctoral program', value: 'phd_doctoral_program' },
  { label: 'MD program', value: 'md_program' },
  { label: 'JD program', value: 'jd_program' },
  { label: 'MBA program', value: 'mba_program' },
  { label: 'MFA program', value: 'mfa_program' },
  { label: 'Other', value: 'other' },
] as const;

export type StudentCollegeChoiceSchoolLevels =
  (typeof studentCollegeChoiceSchoolLevelsOptions)[number]['value'];

export interface StudentCollegeChoiceSerializer {
  college: InstitutionPublicSerializer;
  status: StudentCollegeChoiceStatus;
  status_label: StudentCollegeChoiceStatusLabel;
  rank: number;
  early_action: boolean;
  early_decision: boolean;
  got_scholarship: boolean;
  full_scholarship: boolean;
  scholarship_amount: number;
  stage: string;
  major: string;
  level_of_schooling: StudentCollegeChoiceSchoolLevels;
}

export interface CollegeStatusOption {
  label: string;
  value: StudentCollegeChoiceStatus;
}

export interface MentorProfileMinimalSerializer {
  user_id: UserId;
  profile_id: MentorProfileId;
  email: string;
  last_name: string;
  first_name: string;
  profile_picture: string;
}

export type MentorRequestUid = UUID;

export type TagCategories = 'subject_tag' | 'personality_trait';

export interface StudentProposalData {
  start_date: Date;
  end_date: Date;
  yes_url: string;
  no_url: string;
  student_name: string;
  grade: string;
  school_name: string;
  city: string;
  state: string;
  country: string;
  interests: string;
  relevant_experience: string;
  interview_url: string;
  rematch_note: string;
  mentor_rate: string;
}

export interface MentorSelfProposableSerializer {
  uuid: MentorRequestUid;
  proposal_data: StudentProposalData;
}

type ExpertiseCategories =
  | 'ai_ml_primary'
  | 'ai_ml_secondary'
  | 'data_science'
  | 'computational_skills';

type MentorEligibilityStatuses =
  | ''
  | 'yes'
  | 'yes_us_based'
  | 'foreign_us_student'
  | 'i_dont_know'
  | 'no'
  | 'volunteer'
  | 'pro_bono'
  | 'foreign'
  | 'uk_based';

type FUND_DONATION_OPTIONS = 'no_response' | 'donate_none' | 'donate_some' | 'donate_all';

type VOLUNTEER_SPECS = 'no_response' | 'do_mix' | 'volunteer_exclusively';

type MENTOR_SCHOLARSHIP =
  | 'no_response'
  | 'no'
  | 'yes_with_honorarium'
  | 'yes_with_donation'
  | 'yes';

export interface OwnMentorProfileSerializer {
  applied_cs_mentor: ExpertiseCategories;
  auto_accepting_session_proposals_at: Nullable<DateTime>;
  bio: Nullable<string>;
  coding_skills: Nullable<string>;
  college_award_year: Nullable<number>;
  college_degree_other: Nullable<string>;
  college_degree: unknown;
  college_field: Nullable<string>;
  college_obtained: boolean;
  college_primary_field_other: Nullable<string>;
  college_primary_field: unknown;
  college: unknown;
  contractor_acknowledgement: boolean;
  created_at: DateTime;
  credential_status: string;
  credential_status_label: string;
  current_employer: Nullable<string>;
  CV_name: Nullable<string>;
  CV: unknown;
  donation_rate: Nullable<string>;
  eligibility: MentorEligibilityStatuses;
  email: string;
  experience_1_end: Nullable<string>;
  experience_1_start: Nullable<string>;
  experience_1: Nullable<string>;
  experience_2_end: Nullable<string>;
  experience_2_start: Nullable<string>;
  experience_2: Nullable<string>;
  experience_3_end: Nullable<string>;
  experience_3_start: Nullable<string>;
  experience_3: Nullable<string>;
  experience_4_end: Nullable<string>;
  experience_4_start: Nullable<string>;
  experience_4: Nullable<string>;
  experience_institution_1: Nullable<string>;
  experience_institution_2: Nullable<string>;
  experience_institution_3: Nullable<string>;
  experience_institution_4: Nullable<string>;
  expertise_other: string;
  expertise: string;
  first_name: string;
  fourth_experience: boolean;
  fund_donation: FUND_DONATION_OPTIONS;
  gender_other: string;
  gender: Gender;
  id: UserId;
  language_skills: Nullable<string>;
  last_name: string;
  linkedin_url: string;
  masters_2: unknown;
  masters_award_year_2: Nullable<number>;
  masters_award_year: Nullable<number>;
  masters_degree_2: unknown;
  masters_degree_other_2: Nullable<string>;
  masters_degree_other: Nullable<string>;
  masters_degree: unknown;
  masters_field_2: Nullable<string>;
  masters_field: Nullable<string>;
  masters_obtained_2: boolean;
  masters_obtained: boolean;
  masters_primary_field_2: unknown;
  masters_primary_field_other_2: Nullable<string>;
  masters_primary_field_other: Nullable<string>;
  masters_primary_field: unknown;
  masters: unknown;
  other_skills: string;
  personal_page: Nullable<string>;
  phd_award_year: Nullable<number>;
  phd_degree_other: Nullable<string>;
  phd_degree: unknown;
  phd_field: Nullable<string>;
  phd_obtained: boolean;
  phd_primary_field_other: Nullable<string>;
  phd_primary_field: unknown;
  phd: unknown;
  phone_number: Nullable<string>;
  profile_approved: boolean;
  profile_complete: boolean;
  profile_completion_stage: number;
  profile_id: MentorProfileId;
  profile_picture: Nullable<string>;
  projects_v2: unknown;
  projects: Nullable<string>;
  public: boolean;
  referral_types: unknown;
  referral: Nullable<string>;
  research_skills: string;
  RnD_opt_out: boolean;
  scholarship_clarification: Nullable<string>;
  screen_app_complete: boolean;
  screened: boolean;
  second_experience: boolean;
  second_masters: boolean;
  subject: Nullable<string>;
  tags: TagSerializer[];
  teaching_details: Nullable<string>;
  teaching_experience: boolean;
  third_experience: boolean;
  time_zone: TimeZone;
  top_credential: Nullable<string>;
  venmo_handle: Nullable<string>;
  volunteer_specification: VOLUNTEER_SPECS;
  volunteering: MENTOR_SCHOLARSHIP;
  zoom_email: Nullable<string>;
  ai_session_summary_opt_out: boolean;
  subject_literature: boolean;
  subject_creative_writing: boolean;
  subject_history: boolean;
  subject_fine_arts: boolean;
  subject_law: boolean;
  subject_politics: boolean;
  subject_economics: boolean;
  subject_finance: boolean;
  subject_business: boolean;
  subject_sports_analytics: boolean;
  subject_journalism: boolean;
  subject_fashion: boolean;
  subject_film: boolean;
  subject_music: boolean;
  subject_other: boolean;
  subject_architecture: boolean;
  subject_environmental_science: boolean;
  subject_robotics: boolean;
  subject_neuroscience: boolean;
  subject_psychology: boolean;
  subject_physics: boolean;
  subject_biology: boolean;
  subject_chemistry: boolean;
  subject_math: boolean;
  subject_engineering: boolean;
  subject_computer_science: boolean;
  subject_humanities: boolean;
  subject_social_sciences: boolean;
  showcasing_specialties: ShowcasingSpecialty[];
}

export interface AdmissionAdvisorModalSerializer {
  first_name: string;
  role: string;
  image_url: string;
  calendly_url: string;
  calendly_url_partner_pays: string;
  text_template: string;
  text_template_partner_pays: string;
  action: string;
  fullName: string;
}

export type TimeCommitment =
  | ''
  | '1_to_2'
  | '3_to_5'
  | '6_to_10'
  | '10_to_15'
  | '16_to_20'
  | '20_to_40';

export type MentorRequestStatus =
  | 'matching'
  | 'rematching'
  | 'matched'
  | 'declined_pending'
  | 'declined'
  | 'pending_payment';

export enum StudentStatus {
  NOT_CONTACTED = 'not_contacted',
  UNDER_REVIEW = 'under_review',
  ACTIVE = 'active',
  DECLINED = 'declined',
  DECLINE_PENDING = 'decline_pending',
  ARCHIVED = 'archived',
}

export interface ExternalStudentProfileSerializer {
  id: StudentProfileId;
  user_id: UserId;
  profile_picture: Nullable<string>;
  first_name: string;
  last_name: string;
  timeline_start_at: Nullable<Date>;
  timeline_end_at: Nullable<Date>;
  interests: Nullable<string>;
  expectations: Nullable<string>;
  relevant_experience: Nullable<string>;
  user_status: StudentStatus;
  user_status_last_modified: Nullable<DateTime>;
  profile_complete: boolean;
  projects: [
    {
      uuid: UUID;
      title: string;
      mentor: {
        name: Nullable<string>;
        pk: Nullable<UserId>;
      };
      total_no_of_sessions: number;
      sessions_remaining: number;
      next_session_scheduled: Nullable<DateTime>;
      project: {
        title: string;
        description: string;
      };
      status: string;
      type: string;
      created_at: DateTime;
    },
  ];
  registration_date: DateTime;
  mentor_request_status: Nullable<MentorRequestStatus>;
  profile_completion_date: Nullable<DateTime>;
  initial_interview_scheduling_sent_date: Nullable<DateTime>;
  interview_date: Nullable<DateTime>;
  intend_to_do_cs: boolean;
  intend_to_do_sci_fair: boolean;
  no_cs_or_sci_fair: boolean;
  time_commitment: Nullable<TimeCommitment>;
  group_projects_interest: boolean;
  group_friends: Nullable<string>;
  scholarship_applicant: boolean;
  financial_aid_elaboration: string;
  initial_payment_at: Nullable<DateTime>;
  is_active: Nullable<boolean>;
  has_project_plan: boolean;
}

export interface ProjectOutcomeTypeSerializer {
  id: ProjectOutcomeTypeId;
  name: string;
  button_text: string;
  tile_image: string;
}

export interface SchoolSerializer {
  id: SchoolId;
  name: string;
  level: string;
  state: string;
  city: string;
}

export interface SymposiumPresenterStatisticsSerializer {
  id: SymposiumPresenterStatisticsId;
  scholar_page_views: number;
  scholar_page_registrants: number;
  scholar_page: number;
}

export interface StudentParentInfoSerializer {
  parent_first?: string;
  parent_last?: string;
  parent_email?: string;
}

export interface StudentApplicationV2Serializer {
  id: StudentApplicationId;
  admission_advisor_calendly_url: string;
  banner_text: string;
  completed_application_stages: string[];
  computer_science_completed_programs_ai_detail: string;
  computer_science_completed_programs_ai: boolean;
  computer_science_completed_programs_ap_comp_sci_a: boolean;
  computer_science_completed_programs_ap_comp_sci_principles: boolean;
  computer_science_completed_programs_usaco_detail: string;
  computer_science_completed_programs_usaco: boolean;
  computer_science_language_proficiencies: string;

  connected_counselor_email: Nullable<string>;
  connected_counselor_first_name: Nullable<string>;
  connected_counselor_last_name: Nullable<string>;
  connected_counselor_organization: Nullable<string>;
  counselor_email: string;
  counselor_first: Nullable<string>;
  counselor_last: Nullable<string>;
  counselor_organization: Nullable<string>;
  counselor_type: Nullable<string>;
  expected_output: ExpectedOutput;
  financial_aid_documents: FinancialAidDocumentSerializer;
  financial_aid_elaboration: string;
  first_profile_completion_at: Nullable<DateTime>;
  general_interest: GeneralInterest;
  google_referral: Nullable<string>;
  high_school_other: Nullable<string>;
  high_school: Nullable<string>;
  high_school_label: Nullable<string>;
  influencer_name: Nullable<string>;
  intend_to_do_cs: boolean;
  intend_to_do_sci_fair: boolean;
  interests: Nullable<string>;
  middle_school_other: Nullable<string>;
  middle_school: Nullable<string>;
  middle_school_label: Nullable<string>;
  other_referral: Nullable<string>;
  output_app_game: boolean;
  output_art_work_or_portfolio: boolean;
  output_computer_simulation: boolean;
  output_creative_writing: boolean;
  output_github_code_base: boolean;
  output_infographic: boolean;
  output_opinion_editorial: boolean;
  output_organization: boolean;
  output_podcast: boolean;
  output_research_paper: boolean;
  output_science_fair_project: boolean;
  output_unsure: boolean;
  output_video_or_docu_series: boolean;
  output_webinar: boolean;
  parent_first: Nullable<string>;
  parent_last: Nullable<string>;
  parent_email: Nullable<string>;
  parent_phone: Nullable<string>;
  parent_referral: Nullable<string>;
  partner_pays: boolean;
  podcast_name: Nullable<string>;
  profile_complete: boolean;
  profile_completion_stage: number;
  pronoun_other: string;
  pronoun: string;
  referral: Nullable<string>;

  relevant_experience_example: string;
  relevant_experience: string;
  scholarship_applicant: boolean;
  school_grade_other: Nullable<string>;
  school_graduation_year: Nullable<DateTime>;
  school: Nullable<string>;
  science_experiment_can_conduct_from_home: boolean;
  science_experiment_considers_other_option: boolean;
  science_experiment_description: string;
  science_experiment_location: string;
  science_experiment_materials: string;
  subject_biology: boolean;
  subject_economics: boolean;
  subject_engineering: boolean;
  subject_fine_arts: boolean;
  subject_law: boolean;
  subject_other: boolean;
  tiktok_channel: string;

  time_commitment: TimeCommitment;
  timeline_end_at: Nullable<Date>;
  timeline_start_at: Nullable<Date>;
  user_status: string;
  uuid: UUID;
  virtual_interview_required: boolean;
  virtual_interview_submitted_at: Nullable<DateTime>;
  webinar_referral: Nullable<string>;
  word_of_mouth_referral_existing_scholar: Nullable<string>;
  word_of_mouth_referral_type: Nullable<string>;
  word_of_mouth_referral: Nullable<string>;
  youtube_video_referral: Nullable<string>;
}

export interface CountrySerializer {
  id: CountryId;
  name: string;
}

export interface FinancialAidDocumentSerializer {
  id: FinancialAidDocumentId;
  url: string;
}

export interface ProjectLinkSerializer {
  id: ProjectLinkId;
  outcome_type: ProjectOutcomeTypeId;
  outcome_type_details: ProjectOutcomeTypeSerializer;
  perm_outcome_file: Nullable<PermanentFileSerializer[]>;
  platform: Nullable<string>;
  url: Nullable<string>;
  portfolio_item: PortfolioItemId;
}

interface ExternalMentorSerializer {
  id: MentorProfileId;
  user_id: UserId;
  first_name: string;
  last_name: string;
  time_zone: TimeZone;
  profile_picture: string;
  phone_number: string;
  email: string;
  chatUrl: string;
  adminUrl: string;
}

export interface SimpleTagSerializer {
  id: TagId;
  name: string;
  parent: SimpleTagSerializer;
}

export interface TagSerializer {
  id: TagId;
  parent: TagSerializer;
  slug: string;
  uuid: Nullable<UUID>;
  name: string;
  show_for_case_studies: boolean;
  show_for_interest_form: boolean;
  category: Nullable<TagCategories>;
  key_terms: string;
  created_date: DateTime;
  modified_date: DateTime;
  public: boolean;
}

export interface PortfolioItemSerializer {
  abstract: MarkdownString;
  case_study_status: Nullable<CaseStudyStatus>;
  completed_at: Nullable<DateTime>;
  data: Nullable<Record<string, string>>;
  id: PortfolioItemId;
  image: Nullable<string>;
  mentors: ExternalMentorSerializer[];
  outcome: string;
  page: StudentScholarPageId;
  project: ProjectId;
  project_links: ProjectLinkSerializer[];
  project_status: ProjectStatus;
  started_at: DateTime;
  title: string;
  status: PortfolioItemStatus;
  tags: TagSerializer[];
  mentorReview: string;
  studentReview: string;
}

export interface StudentScholarPageSerializer {
  id: StudentScholarPageId;
  full_name: string;
  first_name: string;
  user_id: UserId;
  profile_picture: string;
  high_school: string;
  grad_year: DateTime;
  polygence_graduation_year: number;
  created_at: DateTime;
  about: string;
  portfolio_items: PortfolioItemSerializer[];
  participant_count: number;
  student_location: string;
  url: Slug;
  student: StudentProfileId;
  cover_image: string;
  data: Record<'about', string>;
  symposium_presenter: boolean;
  symposium_name: string;
  symposium_title: string;
  presentation_url: string;
  presentation_group_name: string;
  presentation_start_at: Nullable<DateTime>;
  presentation_end_at: Nullable<DateTime>;
  published: boolean;
  preview_key: UUID;
}

export interface StudentScholarPageSelectSerializer {
  id: StudentScholarPageId;
  full_name: string;
  email: string;
  available_school: string;
}

type DegreeExpectation = '' | 'only_advanced' | 'open_to_other_experts';

export interface AttachmentSerializer {
  pk: AttachmentId;
  content: unknown;
  name: string;
}

export interface StudentOwnProfileSerializer {
  id: StudentProfileId;
  first_name: string;
  last_name: string;
  user_status: StringUserType;
  email: string;
  phone_number: Nullable<string>;
  gender: 'female' | 'male' | 'other';
  gender_other: string;
  pronoun: string;
  pronoun_other: string;
  time_zone: TimeZone;
  RnD_opt_out: boolean;
  student_status: string;
  profile_picture: Nullable<string>;
  birthday: Nullable<Date>;
  month_of_birth: Nullable<string>;
  day_of_birth: Nullable<number>;
  partner_pays: boolean;
  parent_first: Nullable<string>;
  parent_last: Nullable<string>;
  parent_email: Nullable<string>;
  parent_phone: Nullable<string>;
  send_update_to_parent: boolean;
  has_no_counselor: boolean;
  counselor_first: Nullable<string>;
  counselor_last: Nullable<string>;
  counselor_emails: string[];
  counselor_email: string;
  counselor_organization: Nullable<string>;
  google_referral: Nullable<string>;
  friend_referral: Nullable<string>;
  other_referral: Nullable<string>;
  school_grade: Nullable<string>;
  school_grade_other: Nullable<string>;
  school_graduation_year: Nullable<DateTime>;
  scholarship_full_only: boolean;
  state: Nullable<string>;
  city: Nullable<string>;
  address: Nullable<string>;
  address2: Nullable<string>;
  zip: Nullable<string>;
  school: Nullable<string>;
  middle_school: Nullable<SchoolId>;
  middle_school_label: Nullable<string>;
  high_school: Nullable<SchoolId>;
  high_school_label: Nullable<string>;
  middle_school_other: Nullable<string>;
  high_school_other: Nullable<string>;
  past_project: string;
  intend_to_do_cs: boolean;
  intend_to_do_sci_fair: boolean;
  interests: Nullable<string>;
  expectations: Nullable<string>;
  profile_completion_stage: number;
  profile_complete: boolean;
  profile_approved: boolean;
  profile_approval_date: Nullable<DateTime>;
  first_profile_completion_at: Nullable<DateTime>;
  referral: Nullable<string>;
  discount_code: Nullable<string>;
  time_commitment: TimeCommitment;
  degree_match_expectations: Nullable<DegreeExpectation>;
  relevant_experience: Nullable<string>;
  group_projects_interest: boolean;
  group_friends: Nullable<string>;
  mentors_of_interest: Nullable<string>;
  mentors_ids: number[];
  timeline_start_at: Nullable<DateTime>;
  timeline_end_at: Nullable<DateTime>;
  about: string;
  admission_advisor_email: Nullable<string>;
  admission_advisor_modal_seen: boolean;
  attachments: AttachmentSerializer[];
  banner_text: Nullable<string>;
  connected_parents: unknown;
  counselor_type: unknown;
  counselor: unknown;
  country: Nullable<CountryId>;
  financial_aid_elaboration: string;
  featured_us_as: string[];
  latest_student_application_id: StudentApplicationId;
  profile_completion_date: Nullable<DateTime>;
  selected_product: ProjectTypes;
  scholar_page_slug: string;
  scholarship_applicant: boolean;
  stripe_id_present: boolean;
  subject_literature: boolean;
  subject_creative_writing: boolean;
  subject_history: boolean;
  subject_fine_arts: boolean;
  subject_law: boolean;
  subject_politics: boolean;
  subject_economics: boolean;
  subject_finance: boolean;
  subject_business: boolean;
  subject_sports_analytics: boolean;
  subject_journalism: boolean;
  subject_fashion: boolean;
  subject_film: boolean;
  subject_music: boolean;
  subject_other: boolean;
  subject_architecture: boolean;
  subject_environmental_science: boolean;
  subject_robotics: boolean;
  subject_neuroscience: boolean;
  subject_psychology: boolean;
  subject_physics: boolean;
  subject_biology: boolean;
  subject_chemistry: boolean;
  subject_math: boolean;
  subject_engineering: boolean;
  subject_computer_science: boolean;
  subject_humanities: boolean;
  subject_social_sciences: boolean;
  subject_interest_other: boolean;
  pods_referral: string;
  pods_referral_extra: string;
}

export interface ReferralTypeSerializer {
  label: string;
  value: string;
  frontend_label: string;
  clarifying_question: string;
}

export interface ParentOwnProfileSerializer {
  id: ParentProfileId;
  first_name: string;
  last_name: string;
  channel: string;
  email: string;
  phone_number: string;
  time_zone: TimeZone;
  spouse_first_name: string;
  spouse_last_name: string;
  spouse_email: string;
  supporting_has_mentor: Nullable<number>;
  supporting_completes_a_project: Nullable<number>;
  supporting_showcase: Nullable<number>;
  supporting_independent_learner: Nullable<number>;
  involvement: string;
  involvement_other: string;
  child_relationship: string;
  child_relationship_other: string;
  premeet_mentor: boolean;
  referral: string;
  student_name: string;
  student_email: string;
  child_interest: string;
  other_comments: string;
  cc_student_emails: boolean;
  profile_complete: boolean;
  profile_approved: boolean;
  profile_picture: string;
}

export interface CounselorOwnProfileSerializer {
  id: CounselorProfileId;
  first_name: string;
  last_name: string;
  channel: string;
  email: string;
  phone_number: Nullable<string>;
  time_zone: TimeZone;
  company_name: string;
  company_size: string;
  referral: string;
  referral_details: string;
  cc_student_emails: boolean;
  why_interested: string;
  profile_complete: boolean;
  profile_approved: boolean;
  is_partner_pays_workflow: boolean;
  welcome_video_seen_at: Nullable<DateTime>;
  subscribe_to_marketing_emails: boolean;
  subscribe_to_partner_benefit_opportunities: boolean;
  profile_picture?: Nullable<string>;
  practice_country?: Nullable<CountryId>;
  practice_city?: Nullable<string>;
  client_country?: Nullable<CountryId>;
}

export interface MentorPublicSerializer {
  id: number;
  bio: string;
  credential_status_label: string;
  highest_institution: string;
  first_name: string;
  last_name_initial: string;
  slugified_first_name: string;
  profile_picture: string;
  subject: string;
  mentor_subjects: string;
  coding_skills: Nullable<string>;
  teaching_details: string;
  language_skills: string;
  projects_v2: Array<{
    pk: number;
    title: string;
    description: string;
    prerequisites: string;
    other_comments: string;
    dont_feature_project: false;
    cover_image: Nullable<string>;
  }>;
  completed_projects: PublicCaseStudyListSerializer[];
  mentor_review: Array<{
    review: string;
    author: string;
    profile_picture: string;
  }>;
  completedProjects: Array<{
    url: string;
  }>;
  college: string;
  college_degree: string;
  college_field: string;
  college_award_year: string;
  college_obtained: boolean;
  masters: string;
  masters_degree: string;
  masters_field: string;
  masters_award_year: string;
  masters_obtained: boolean;
  second_masters: string;
  masters_2: string;
  masters_degree_2: string;
  masters_field_2: string;
  masters_award_year_2: string;
  masters_obtained_2: boolean;
  phd: string;
  phd_degree: string;
  phd_field: string;
  phd_award_year: string;
  phd_obtained: boolean;
  experience_1: string;
  experience_institution_1: string;
  second_experience: string;
  experience_2: string;
  experience_institution_2: string;
  third_experience: string;
  experience_3: string;
  experience_institution_3: string;
  fourth_experience: string;
  experience_4: string;
  experience_institution_4: string;
  experience_1_start: string;
  experience_1_end: string;
  experience_2_start: string;
  experience_2_end: string;
  experience_3_start: string;
  experience_3_end: string;
  experience_4_start: string;
  experience_4_end: string;
}

export interface MentorPublicListSerializer {
  id: UserId;
  first_name: string;
  last_name_initial: string;
  slugified_first_name: Slug;
  profile_picture: string;
  subject: Nullable<string>;
  mentor_subjects: string;
  credential_status: string;
  credential_status_label: string;
  highest_institution: Nullable<string>;
  coding_skills: Nullable<string>;
  public: boolean;
  publicly_available: boolean;
}

export interface MentorPublicLinkListSerializer {
  id: UserId;
  first_name: string;
  slugified_first_name: Slug;
}

export interface PublicProjectSerializer {
  status: string;
  completed_at: Nullable<string>;
  created_at: string;
  mentors: MentorPublicListSerializer[];
}

export interface PublicPortfolioItemSerializer {
  project: PublicProjectSerializer;
  title: string;
  abstract: string;
  image: string;
  case_study_url: Nullable<string>;
}

export interface PublicStudentProfileSerializer {
  profile_picture: Nullable<string>;
  enrolled_year: string;
  school: Nullable<string>;
}

export interface PublicScholarPageSerializer {
  id: StudentScholarPageId;
  full_name: string;
  portfolio_items: PublicPortfolioItemSerializer[];
  student_profile: PublicStudentProfileSerializer;
  symposium_presenter: boolean;
  url: string;
  polygence_graduation_year: number;
}

export interface PublicScholarPageLinkSerializer {
  id: StudentScholarPageId;
  full_name: string;
  url: string;
}

export interface PublicScholarStudentProfileSerializer {
  enrolled_year: number;
  country: string;
  profile_picture: string;
  school: string;
  about: string;
  first_name: string;
  last_name: string;
  full_name: string;
  school_graduation_year: string;
  city: string;
  state: string;
}

export interface PublicDetailedScholarPageSerializer {
  portfolio_items: PublicPortfolioItemSerializer[];
  student_profile: PublicScholarStudentProfileSerializer;
  polygence_graduation_year: number;
  symposium_presenter: boolean;
  cover_image: string;
  presentation_url: string;
  symposium_name: string;
  url: string;
  published: boolean;
}

export interface DegreeSerializer {
  value: string;
  label: string;
}

export interface FieldOfStudySerializer {
  value: string;
  label: string;
}

export interface ScholarGroupSerializer {
  id: ScholarGroupId;
  url: string;
  peers: StudentScholarPage[];
}

export interface PortfolioItemUpdateSerializer extends Omit<PortfolioItemSerializer, 'tags'> {
  tags: PortfolioItemId[];
}

export interface ExternalStudentProfileOverviewSerializer {
  profile: ExternalProfileSerializer;
  current_project: Nullable<ProjectId>;
  projects: StudentProject[];
  mentors: ExternalMentorSerializer[];
  sessions: Nullable<ProjectSessionSerializer>;
  application: ExternalStudentProfileSerializer;
  application_status: ApplicationStatus;
  scholar_page: Nullable<StudentProfileOverviewScholarPageSerializer>;
  overdue_payment_intent: Nullable<PaymentIntentStudentOverviewSerializer>;
}

export interface ExternalProfileSerializer {
  id: StudentProfileId;
  user_id: UserId;
  profile_picture: Nullable<string>;
  profile_completion_date: Nullable<DateTime>;
  interview_date: Nullable<DateTime>;
  initial_interview_scheduling_sent_date: Nullable<DateTime>;
  first_name: string;
  last_name: string;
  time_zone: TimeZone;
  phone_number: Nullable<string>;
  email: string;
  start_date: Nullable<DateTime>;
}

export interface StudentProject {
  id: ProjectId;
  mentor_ids: MentorProfileId[];
  title: string;
  description: string;
  status: ProjectStatus;
  type: ProjectTypes;
  started_at: DateTime;
  completed_at: DateTime;
  details: Record<string, string>;
  workspace_id: WorkspaceId;
  sessions_paid_for: number;
  room_url: string;
  milestones_enabled: boolean;
}

interface ApplicationStatus {
  enrolled_at: DateTime;
  introduced_to_mentor_at: DateTime;
}

export interface StudentProfileOverviewScholarPageSerializer {
  symposium_name: string;
  presentation_url: string;
  presentation_group_name: string;
  presentation_start_at: Nullable<DateTime>;
  symposium_title: string;
}

export interface StudentFromMentorRequestSerializer {
  cart_uuid: UUID;
  admission_team_contact: boolean;
  created_at: DateTime;
  date_matched: Nullable<DateTime>;
  decline_details: Record<string, unknown>;
  disable_auto_close: boolean;
  due_date: Nullable<DateTime>;
  days_until_auto_close: Nullable<number>;
  enrolled_at: Nullable<DateTime>;
  exclude_from_waitlist: boolean;
  legacy_keywords: string;
  keywords: KeywordSerializer[];
  match_url: string;
  mentor_offer_date: Nullable<DateTime>;
  mentor_request: string;
  mentor: Nullable<{
    name: string;
    first_name: string;
    last_name: string;
    email: string;
    phone_number: string;
    id: UserId;
    time_zone: TimeZone;
    profile_picture: string;
  }>;
  must_have_tags: TagSerializer[];
  original_due_date: Nullable<DateTime>;
  payment_due_date: Nullable<DateTime>;
  payment_intent_id: Nullable<UUID>;
  pk: MentorRequestId;
  possible_rejection: boolean;

  rematch_note: string;
  status: MentorRequestStatus;
  student_application: Nullable<AcceptedStudentApplicationSerializer>;
  student: StudentAnalyticsSerializer;
  tags: TagSerializer[];
  uuid: UUID;
  expected_end_date: Nullable<Date>;
  automated_matching_enabled: boolean;
  purchased_products: string[];
}

export interface StudentAnalyticsSerializer extends StudentOwnProfileSerializer {
  admission_advisor: Nullable<SimpleUserSerializer>;
  attachments: AttachmentSerializer[];
  blurb_interests: string;
  blurb_relevant_experience: string;
  channel_medal: ChannelMedal;
  channel: string;
  cohort_application_pk: Nullable<CohortId>;
  country_name: string;
  created_at: DateTime;
  decline_details: Record<string, unknown>;
  financial_aid_elaboration: string;
  hs_deal_for_admission_advisor_workflow: boolean;
  latest_cohort_application: Nullable<{ name: string; deadline: DateTime }>;
  mentor_package_price: number;
  owner_id: UserId;
  parents: {
    name: string;
    email: string;
    phone: string;
  }[];
  preferred: string[];
  profile_completion_date: Nullable<DateTime>;
  scholarship_applicant: boolean;
  screening_interview_note: Nullable<NoteSerializer>;
  tags: TagSerializer[];
  usherer: {
    name: string;
    id?: UserId;
  };
  videoask_url: Nullable<string>;
  virtual_interview_received_at: Nullable<DateTime>;
  virtual_interview_required: boolean;
  partnerTags: UserCardPartnerTag[];
}

export interface NoteSerializer {
  created_at: DateTime;
  created_by_name: string;
  created_by: UserId;
  id: NoteId;
  note: string;
  source: string;
  type: string;
  updated_at: DateTime;
}

export interface StudentDetailsSerializer extends StudentFromMentorRequestSerializer {
  default_interview_recording_id: Nullable<UUID>;
  expected_end_date: Nullable<Date>;
  expiry_date: Nullable<DateTime>;
  has_partnership_arrangement: boolean;
  matched_by_field_of_study: boolean;
  matched_by_tags: boolean;
  mentor_package_paid_or_scholarship: MentorPackagePaidOrScholarShip;
  mentor_package_price: Nullable<number>;
  proposed_mentors: ProposedMentorSerializer[];
  sessions_remaining: Nullable<string>;
}

export interface ProposedMentorSerializer {
  // proposed_mentor_serializer
  pk: MentorProfileId;
  name: string;
  email: string;
  user_id: UserId;
  order: number;
  score: number;
  rate: number;
  institution_categories: Record<string, string[]>;
  points: {
    points_for_no_project: number;
    points_for_project_status: number;
    points_for_recent_login: number;
    points_for_matching_tags: number;
    points_for_matching_keywords: number;
    points_for_mentor_requests_proposed_for: number;
    penalty_for_no_advanced_degree: number;
    points_for_mentor_review_rating: number;
  };
  type: MentorRequestProposalType;
  common_keywords: string;
  common_tags: string;
  applied_cs_mentor: string;
  waiting_for_student_since: Nullable<DateTime>;
  proposals: {
    date: DateTime;
    response?: {
      value: 'accept' | 'reject';
      reason?: {
        no_good_fit?: boolean;
        no_bandwidth?: boolean;
        timezone_issue?: boolean;
        unavailable_until?: string;
        other?: string;
      };
      match_rating?: number;
      recorded_at: DateTime;
    };
  }[];
}

export interface TagSelectorSerializer {
  id: TagId;
  name: string;
  category: TagCategories;
}

export interface UserCardBadge {
  label: string;
  icon: 'completedProject' | 'activeProject' | 'enrolledProject';
  variant: 'primary' | 'dark' | 'light';
}

export interface UserCardPartnerTag {
  label: string;
  definition: string;
  severity: 'medium' | 'high';
}

export type UserCardAction =
  | {
      label: string;
      url: string;
      action: undefined;
    }
  | {
      label: string;
      url: undefined;
      action: UserCardCustomAction;
    };

export interface UserCardCustomAction {
  name: string;
  parameters: Record<string, unknown>;
}

export interface UserCardSerializer {
  id: UserId;
  first_name: string;
  last_name: string;
  profile_picture: string;
  description: string[];
  timezone: string;
  actions: UserCardAction[];
  badges: UserCardBadge[];
  partner_tags: UserCardPartnerTag[];
  pronouns?: string;
  envision_interest: boolean;
}

export interface StudentSelectedProductSerializer {
  selected_product: string;
}

export interface StudentJourneyMinimalProjectSerializer {
  id: ProjectId;
  workspace_id: WorkspaceId;
  mentor: MentorProfileMinimalSerializer | null;
  upcoming_session: UpcomingSessionSerializer | null;
}

export interface StudentJourneyProjectSerializer extends StudentJourneyMinimalProjectSerializer {
  all_sessions: number;
  sessions_completed: number;
  started_at: string;
  completed_at: string | null;
  expected_end_at: string;
}

export interface FileUploadSerializer {
  type: FileTypes;
  url: string;
  uuid: UUID;
}

export interface PublicStudentProfileForCollegeAdmissionSurveySerializer {
  first_name: string;
  featured_us_as: string[];
}

export interface HubSpotEmailNotificationSerializer {
  public_label: string;
  public_description: string;
  email_id: string;
  subscribed: boolean;
}
