import * as Sentry from '@sentry/react';
import { useEffect, useRef } from 'react';

export const useValidateValue = <T>(
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  value: any,
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  validatorTypeGuard: (value: any) => value is T,
  errorMessage: string,
): T | null => {
  const isValid = validatorTypeGuard(value);
  const errorSent = useRef(false);

  useEffect(() => {
    if (!isValid && !errorSent.current) {
      Sentry.captureMessage(errorMessage, {
        tags: {
          value: String(value),
        },
      });

      errorSent.current = true;
    }
  }, [errorMessage, isValid, value]);

  return isValid ? value : null;
};
