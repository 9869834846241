import { Text } from '@polygence/components';
import type { Components } from 'react-markdown';
import ReactMarkdown from 'react-markdown';
import { Input, object, parse, string } from 'valibot';

import { StudentSnapshotEmptyContent } from 'src/components/common/StudentSnapshot/StudentSnapshotContent';
import { UserCardHoverTrigger } from 'src/components/common/UserCard/UserCardHoverTrigger';

const MarkdownSchema = object({
  content: string(),
});

type MarkdownProps = Input<typeof MarkdownSchema>;

export const StudentSnapshotMarkdown = (props: MarkdownProps) => {
  const { content } = parse(MarkdownSchema, props);

  if (!content) {
    return <StudentSnapshotEmptyContent />;
  }

  return (
    <section>
      <ReactMarkdown components={{ a: LinkWithUserCard, p: TextNode, strong: StrongNode }}>
        {content}
      </ReactMarkdown>
    </section>
  );
};

const StrongNode: Components['strong'] = ({ node, ...props }) => {
  return (
    <Text size="small" fontWeight="bold" as="strong">
      {props.children}
    </Text>
  );
};

const TextNode: Components['p'] = ({ node, ...props }) => {
  return (
    <Text size="small" alignment="left" className="mb-3">
      {props.children}
    </Text>
  );
};

const LinkWithUserCard: Components['a'] = ({ node, ...props }) => {
  const href = String(node?.properties?.['href'] ?? '');
  if (href.startsWith('#user:')) {
    const userId = Number(href.split(':')[1]);
    return (
      <UserCardHoverTrigger userId={userId} prefetchOnHover>
        {props.children}
      </UserCardHoverTrigger>
    );
  }

  return (
    <a href={href} target="_blank" rel="noopener noreferrer">
      {props.children}
    </a>
  );
};
